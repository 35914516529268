import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./FeaturedCardCarousel.css";
import parse from "html-react-parser";
import Loader from "../Loader/loader";
import { useNavigate } from "react-router";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperCarousel } from "../../utils/carouselFunction";
import useCustomTranslation from "../../utils/useCustomTranslation";
import FeaturedCard from "../Card/FeaturedCard";
import * as posterImage from "../../utils/usePosterImage";

export const FeaturedCardCarousel = ({
    contents: contentToRender = [],
    heading,
    colors: colorObj,
}) => {
    const navigate = useNavigate();

    const content = useSelector((state) => state.layout.content);

    const [isLoading, setIsLoading] = useState(false);

    const [featuredContentsRefData, setFeaturedContentsRefData] = useState([]);

    const [cardCarouselContentsRefData, setCardCarouselContentsRefData] = useState([]);

    useEffect(() => {
        const fetchDefaultData = async () => {
            setIsLoading(true);

            // Helper function to process content arrays
            const processContentArray = async (contentArray) => {
                const processedContent = JSON.parse(JSON.stringify(contentArray));
                for (const element of processedContent) {
                    if (element?.link) {
                        element.posterImage = await posterImage.usePosterImage(
                            element?.link,
                            element?.image,
                        );
                    }
                }
                return processedContent;
            };

            // Fetch and process featured content and card content
            const featuredContent = await processContentArray(
                content[contentToRender[0].contentKey].map((k) => content[k]).slice(0, 1),
            );
            if (featuredContent.length) setFeaturedContentsRefData(featuredContent);

            // Check if there is more than one contentToRender before fetching and processing card content
            if (contentToRender.length > 1) {
                const cardContent = await processContentArray(
                    content[contentToRender[1].contentKey]?.map((k) => content[k]) || [],
                );
                if (cardContent.length) setCardCarouselContentsRefData(cardContent);
            }

            setIsLoading(false);
        };

        fetchDefaultData();
    }, []);

    const handleClick = (nid) => {
        navigate(`${nid}`);
    };

    const { dir } = useCustomTranslation();

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="container">
                <div
                    className="featuredCardWrapper py-50"
                    style={{ backgroundColor: colorObj?.background }}
                >
                    <div className="row">
                        <div
                            className="col-12 mb-4"
                            style={{ "--primary_color": colorObj?.primary_color }}
                        >
                            <div className="titleMain">
                                <h3>{heading}</h3>
                            </div>
                        </div>

                        <div className="col-12 mb-2">
                            {featuredContentsRefData.map((value) => (
                                <div className="newsCardBig" key={value.title}>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pe-lg-0 pe-md-0">
                                            <div className="NewsImg">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={value.image.src}
                                                    onClick={() => handleClick(value.nid)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 ps-lg-0 ps-md-0">
                                            <div className="card-body">
                                                {value.microsite && (
                                                    <div
                                                        className="microsite"
                                                        style={{
                                                            "--primary_color":
                                                                colorObj?.primary_color,
                                                        }}
                                                    >
                                                        {value.microsite}
                                                    </div>
                                                )}
                                                <h3
                                                    className="underline"
                                                    style={{
                                                        "--accent_color1": colorObj?.accent_color1,
                                                    }}
                                                    onClick={() => handleClick(value.nid)}
                                                >
                                                    {value.title}
                                                </h3>
                                                <div
                                                    className="textMain"
                                                    style={{
                                                        "--accent_color1": colorObj?.accent_color1,
                                                    }}
                                                >
                                                    {parse(value.summary)}
                                                </div>
                                                <div className="d-flex gap-2 justify-center">
                                                    <div
                                                        className="hashtag"
                                                        style={{
                                                            "--primary_color":
                                                                colorObj?.primary_color,
                                                            "--accent_color2":
                                                                colorObj?.accent_color2,
                                                        }}
                                                    >
                                                        {value.tags?.split(",")[0]}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-12">
                            <Swiper
                                dir={dir}
                                key={dir}
                                {...swiperCarousel}
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                }}
                            >
                                {cardCarouselContentsRefData.map((card) => (
                                    <SwiperSlide key={card.title}>
                                        <FeaturedCard content={card} colorsObj={colorObj} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
