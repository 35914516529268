import React from "react";

const PrintSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M16.0001 8.61523V5.61523H8.00015V8.61523H7.00015V4.61523H17.0001V8.61523H16.0001ZM17.6151 12.1152C17.8985 12.1152 18.1361 12.0196 18.3281 11.8282C18.5201 11.6369 18.6158 11.3992 18.6151 11.1152C18.6151 10.8319 18.5195 10.5946 18.3281 10.4032C18.1368 10.2119 17.8991 10.1159 17.6151 10.1152C17.3318 10.1152 17.0945 10.2112 16.9031 10.4032C16.7118 10.5952 16.6158 10.8326 16.6151 11.1152C16.6151 11.3986 16.7111 11.6362 16.9031 11.8282C17.0951 12.0202 17.3325 12.1159 17.6151 12.1152ZM16.0001 19.0002V14.4622H8.00015V19.0002H16.0001ZM17.0001 20.0002H7.00015V16.0002H3.57715V10.6152C3.57715 10.0486 3.76948 9.57357 4.15415 9.19023C4.53882 8.8069 5.01315 8.61523 5.57715 8.61523H18.4231C18.9898 8.61523 19.4648 8.8069 19.8481 9.19023C20.2315 9.57357 20.4231 10.0486 20.4231 10.6152V16.0002H17.0001V20.0002ZM19.4231 15.0002V10.6152C19.4231 10.3319 19.3275 10.0946 19.1361 9.90323C18.9448 9.7119 18.7071 9.6159 18.4231 9.61523H5.57715C5.29382 9.61523 5.05615 9.71123 4.86415 9.90323C4.67215 10.0952 4.57648 10.3326 4.57715 10.6152V15.0002H7.00015V13.4622H17.0001V15.0002H19.4231Z"
                fill="#808080"
            />
        </svg>
    );
};

export default PrintSvg;
