import React from "react";

const MenuIconSvg = ({ fill, text, className, onClick }) => {
    return text ? (
        <svg
            width="99"
            height="17"
            viewBox="0 0 99 17"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            className="cursor-pointer"
        >
            <path
                d="M50.42 1.76V16H48.14V7.16L44.36 16H42.66L38.86 7.26V16H36.58V1.76H38.62L43.52 12.9L48.38 1.76H50.42ZM53.5322 1.76H63.0522V3.8H56.0522V7.74H62.6322V9.78H56.0522V13.96H63.0522V16H53.5322V1.76ZM77.4267 1.76V16H75.5067L67.8267 6.02V16H65.4267V1.76H67.3467L75.0067 11.72V1.76H77.4267ZM86.4044 16.18C84.4444 16.18 82.9577 15.68 81.9444 14.68C80.9444 13.6667 80.4444 12.1867 80.4444 10.24V1.76H82.9844V10.38C82.9844 11.6067 83.271 12.54 83.8444 13.18C84.4177 13.8067 85.271 14.12 86.4044 14.12C87.511 14.12 88.351 13.8 88.9244 13.16C89.511 12.52 89.8044 11.5933 89.8044 10.38V1.76H92.3444V10.24C92.3444 12.1733 91.8377 13.6467 90.8244 14.66C89.811 15.6733 88.3377 16.18 86.4044 16.18Z"
                fill={fill}
            />
            <rect y="7" width="28" height="3" fill={fill} />
            <rect y="13" width="28" height="3" fill={fill} />
            <rect y="1" width="28" height="3" fill={fill} />
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`w-8 h-[auto] ${className}`}
            onClick={onClick}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
        </svg>
    );
};

export default MenuIconSvg;
