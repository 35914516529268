import React from "react";

const HamburgerMenuIconSvg = ({ color }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="16"
            viewBox="0 0 30 16"
            fill="none"
        >
            <rect y="6.40039" width="29.9996" height="3.19992" fill={color} />
            <rect y="12.7988" width="29.9996" height="3.19992" fill={color} />
            <rect width="29.9996" height="3.19992" fill={color} />
        </svg>
    );
};

export default HamburgerMenuIconSvg;
