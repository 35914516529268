import axios from "axios";

export const getPosterImage = async (videoURL, defaultImage) => {
    let posterImage = "";

    // Check if the URL is from Vimeo or YouTube
    const isVimeo = videoURL?.includes("vimeo.com");
    const isYouTube = videoURL?.includes("youtube.com") || videoURL.includes("youtu.be");

    if (isVimeo) {
        // Extract the Vimeo video ID
        const videoId = videoURL.split("/").pop();
        try {
            const response = await axios.get(`https://vimeo.com/api/v2/video/${videoId}.json`);
            if (response.status === 200 && response.data && response.data.length > 0) {
                posterImage = response.data[0].thumbnail_large;
            } else {
                console.error("Failed to fetch Vimeo poster image");
            }
        } catch (error) {
            console.error("Error fetching Vimeo poster image:", error);
        }
    } else if (isYouTube) {
        const videoIdMatch = videoURL.match(/(?:v=|\/v\/|embed\/|youtu\.be\/)([^"&?/\s]{11})/);
        const videoId = videoIdMatch ? videoIdMatch[1] : null;

        if (videoId) {
            posterImage = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
        } else {
            console.error("Invalid YouTube video URL");
        }
    } else {
        posterImage = defaultImage?.src;
    }

    return posterImage;
};
