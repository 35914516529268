import React, { useCallback, useEffect, useState } from "react";
import "./FeatureSection2Left.css";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { APP_COLORS } from "../../constants/styles";
import CustomLink from "../../utils/customLink";
import { ARTICLE_DETAILS, BLOCK_DETAILS } from "../../constants/strings";

function FeatureSection2Left({
    contents: contentToRender,
    heading,
    body,
    section_image,
    colors: colorObj,
    block_source,
    block_buttons,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    const contentFromStore = useSelector((state) => state.layout.content);

    const [featureSection2LeftDetails, setFeatureSection2LeftDetails] = useState({
        heading: "",
        body: "",
        path: "",
        image: "",
        nid: "",
    });

    useEffect(() => {
        if (block_source === BLOCK_DETAILS) {
            setFeatureSection2LeftDetails({
                ...featureSection2LeftDetails,
                heading: heading,
                body: body,
                image: section_image,
            });
        }

        if (block_source === ARTICLE_DETAILS) {
            setFeatureSection2LeftDetails({
                ...featureSection2LeftDetails,
                heading: contentReferenceData[0]?.title,
                body: contentReferenceData[0]?.summary,
                image: contentReferenceData[0]?.image,
                path: contentReferenceData[0]?.path,
                nid: contentReferenceData[0]?.nid,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                const resp = respKeys.map((k) => contentFromStore[k]);
                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    return (
        <section className="feature-LR py-50" style={APP_COLORS(colorObj)}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-7 col-sm-12 col-12 p-0">
                        <div className="feature-img-Hldr">
                            <img src={featureSection2LeftDetails?.image?.src} alt=" " />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 col-sm-12 col-12 d-flex align-items-end">
                        <div className="feature-txt-main">
                            <h2>{featureSection2LeftDetails.heading}</h2>
                            <div className="feature-bodyTxt">
                                {parse(
                                    featureSection2LeftDetails.body
                                        ? featureSection2LeftDetails.body
                                        : "",
                                )}
                            </div>
                            <>
                                {block_source === BLOCK_DETAILS ? (
                                    block_buttons.map((v) => (
                                        <CustomLink to={getLink(v.action)} key={v.label}>
                                            <span className="cursor-pointer" key={v.label}>
                                                {v.label}
                                                <i className="bi bi-arrow-right mt-1"></i>
                                            </span>
                                        </CustomLink>
                                    ))
                                ) : (
                                    <CustomLink to={getLink(featureSection2LeftDetails?.path)}>
                                        <span className="cursor-pointer">
                                            {t("btnText.learnMore")}
                                            <i className="bi bi-arrow-right mt-1"></i>
                                        </span>
                                    </CustomLink>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeatureSection2Left;
