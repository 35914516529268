import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./layoutSlice";
import authReducer from "./authSlice";
import directoryReducer from "./directorySlice";
import messagesReducer from "./messagesSlice";
import mailingListReducer from "./mailingListSlice";
import galleryReducer from "./gallerySlice";
import funeralsSamarsReducer from "./funeralsSamarsSlice";
import speechReducer from "./speechGallerySlice";
import searchReducer from "./searchSlice";
import instantSearchReducer from "./instantSearchSlice";
import jobSpotReducer from "./jobSpotSlice";
import applicationImageTileLinksReducer from "./ImageApplicationsTileLinksSlice";
import contactUsReducer from "./contactUsSlice";
import newsLetterFormsReducer from "./newsLetterFormSlice";

export default configureStore({
    reducer: {
        layout: layoutReducer,
        auth: authReducer,
        directory: directoryReducer,
        messages: messagesReducer,
        mailing: mailingListReducer,
        gallery: galleryReducer,
        funeralsSamars: funeralsSamarsReducer,
        speech: speechReducer,
        search: searchReducer,
        instantSearch: instantSearchReducer,
        jobSpot: jobSpotReducer,
        applicationImageTileLinks: applicationImageTileLinksReducer,
        contact_us: contactUsReducer,
        newsLetterForms: newsLetterFormsReducer,
    },
});
