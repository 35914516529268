import React from "react";
import "./EmbeddedIframe.css";

function EmbeddedIframe({ contents }) {
    return (
        <>
            <div>
                <iframe src={contents[0].contentKey} className="iframe"></iframe>
            </div>
        </>
    );
}

export default EmbeddedIframe;
