import React from "react";
import "./PhotoGallery.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import useCustomTranslation from "../../utils/useCustomTranslation";

function PhotoGallery({ image_series, article_type = "" }) {
    const { t } = useCustomTranslation();

    let centerClass = "justify-content-center";
    let colClass = "col-lg-10";
    if (article_type == "article2") {
        centerClass = "";
        colClass = "";
    }

    const overlayRender = (props) => {
        const { index } = props;
        const description = image_series[index]?.title || "";

        return (
            <div className="customOverlay">
                <div className="description">{description}</div>
            </div>
        );
    };

    async function toDataURL(url) {
        const response = await fetch(url);
        const blob = await response.blob();
        return URL.createObjectURL(blob);
    }

    const getFileName = (url) => {
        try {
            const urlObj = new URL(url);
            const imageUrl = urlObj.searchParams.get("url");

            if (imageUrl) {
                const urlParts = decodeURIComponent(imageUrl).split("/");
                const fileNameWithParams = urlParts[urlParts.length - 1];
                return fileNameWithParams.split("?")[0] || "image";
            }

            return "image";
        } catch (error) {
            console.error("Error extracting filename:", error);
            return "image";
        }
    };

    const toolbarRender = ({ onScale, scale, images, index }) => {
        const handleDownload = async () => {
            const a = document.createElement("a");
            a.href = await toDataURL(images[index].src);
            a.download = getFileName(images[index].src);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };

        return (
            <div className="toolbar">
                <i className="bi bi-zoom-in me-3" onClick={() => onScale(scale + 0.1)}></i>
                <i className="bi bi-zoom-out me-3" onClick={() => onScale(scale - 0.1)}></i>
                <i className="bi bi-download" onClick={handleDownload} title="Download"></i>
            </div>
        );
    };

    return (
        <div className="container">
            <div className={`row d-flex ${centerClass} photoGalleryWrapper`}>
                <div className={`${colClass} col-md-12 col-sm-12 col-12`}>
                    <div className="title">
                        <h2>{t("constantString.photoGalleryHeader")}</h2>
                        <div className="line"></div>
                    </div>

                    <PhotoProvider
                        overlayRender={(props) => overlayRender({ ...props })}
                        speed={() => 800}
                        toolbarRender={toolbarRender}
                        easing={(type) =>
                            type === 2
                                ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
                                : "cubic-bezier(0.34, 1.56, 0.64, 1)"
                        }
                    >
                        <div className="photoGalleryHldr">
                            {image_series?.map((item, index) => (
                                <PhotoView key={index} src={item.src}>
                                    <div className="imgMain">
                                        <img src={item.src} alt={item.alt || ""} />
                                    </div>
                                </PhotoView>
                            ))}
                        </div>
                    </PhotoProvider>
                </div>
            </div>
        </div>
    );
}

export default PhotoGallery;
