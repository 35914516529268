import React from "react";

const HomeSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M14.1672 6.25074C14.1672 6.14024 14.1233 6.03426 14.0452 5.95612C13.967 5.87798 13.861 5.83408 13.7505 5.83408C13.64 5.83408 13.534 5.87798 13.4559 5.95612C13.3778 6.03426 13.3339 6.14024 13.3339 6.25074H14.1672ZM2.50053 6.25074C2.50053 6.14024 2.45663 6.03426 2.37849 5.95612C2.30035 5.87798 2.19437 5.83408 2.08386 5.83408C1.97335 5.83408 1.86737 5.87798 1.78923 5.95612C1.71109 6.03426 1.66719 6.14024 1.66719 6.25074H2.50053ZM15.1222 8.21241C15.2004 8.29065 15.3065 8.3346 15.4172 8.3346C15.5278 8.3346 15.634 8.29065 15.7122 8.21241C15.7904 8.13417 15.8344 8.02806 15.8344 7.91741C15.8344 7.80676 15.7904 7.70065 15.7122 7.62241L15.1222 8.21241ZM7.91719 0.417412L8.21219 0.122411C8.17349 0.0836087 8.12751 0.0528231 8.07689 0.0318178C8.02627 0.0108124 7.972 0 7.91719 0C7.86239 0 7.80812 0.0108124 7.7575 0.0318178C7.70688 0.0528231 7.6609 0.0836087 7.62219 0.122411L7.91719 0.417412ZM0.122193 7.62241C0.043954 7.70065 0 7.80676 0 7.91741C0 8.02806 0.043954 8.13417 0.122193 8.21241C0.200432 8.29065 0.306547 8.3346 0.417193 8.3346C0.527839 8.3346 0.633954 8.29065 0.712193 8.21241L0.122193 7.62241ZM3.75053 15.8341H12.0839V15.0007H3.75053V15.8341ZM14.1672 13.7507V6.25074H13.3339V13.7507H14.1672ZM2.50053 13.7507V6.25074H1.66719V13.7507H2.50053ZM15.7122 7.62241L8.21219 0.122411L7.62219 0.712412L15.1222 8.21241L15.7122 7.62241ZM7.62219 0.122411L0.122193 7.62241L0.712193 8.21241L8.21219 0.712412L7.62219 0.122411ZM12.0839 15.8341C12.6364 15.8341 13.1663 15.6146 13.557 15.2239C13.9477 14.8332 14.1672 14.3033 14.1672 13.7507H13.3339C13.3339 14.0823 13.2022 14.4002 12.9677 14.6346C12.7333 14.869 12.4154 15.0007 12.0839 15.0007V15.8341ZM3.75053 15.0007C3.41901 15.0007 3.10106 14.869 2.86664 14.6346C2.63222 14.4002 2.50053 14.0823 2.50053 13.7507H1.66719C1.66719 14.3033 1.88669 14.8332 2.27739 15.2239C2.66809 15.6146 3.19799 15.8341 3.75053 15.8341V15.0007Z"
                fill="#808080"
            />
        </svg>
    );
};

export default HomeSvg;
