import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    selectCountriesLanguageArray,
    selectCountryLanguageCodes,
    getCountryLanguageDataFromLocalStorageOrIp,
    setCountryInfo,
    setLanguageInfo,
    getCountriesData,
    setRegionInfo,
} from "../redux/authSlice";
import {
    DEFAULT_COUNTRY_CODE,
    getCountryLanguageInfoLocalStorage,
    getValidCountryLanguageCode,
} from "./countryLanguageHelpers";
import { useLocation } from "react-router-dom";

export const useCountryLanguageLinkExtractor = () => {
    const { pathname } = useLocation();
    const linkFromProps = pathname.slice(1);

    const dispatch = useDispatch();

    const [link, setLink] = useState(null);

    const countriesLanguagesArray = useSelector(
        selectCountriesLanguageArray,
        (prev, next) => prev.length === next.length,
    );
    const { countryCode: countryCodeFromState, languageCode: languageCodeFromState } = useSelector(
        selectCountryLanguageCodes,
        shallowEqual,
    );
    const { regionId: regionIdFromLS } = getCountryLanguageInfoLocalStorage();

    useEffect(() => {
        if (!countriesLanguagesArray.length) dispatch(getCountriesData());
    }, [countriesLanguagesArray]);

    useEffect(() => {
        const linkWithoutLeadingSlash = linkFromProps?.startsWith("/")
            ? linkFromProps.slice(1)
            : linkFromProps;
        const [countryCodeFromLink, languageCodeFromLink, ...idFromLinkArray] =
            linkWithoutLeadingSlash.split("/");
        const idFromLink = idFromLinkArray.join("/");

        const { languageCode: defaultLanguageCode } = getValidCountryLanguageCode(
            countriesLanguagesArray,
            countryCodeFromLink,
        );

        // 1. check to see if link is:
        //  COUNTRY_CODE/LANGUAGE_CODE/ID (and same as state) OR
        //  COUNTRY_CODE/ID (and langauge code in state is default for the COUNTRY_CODE) AND
        //  COUNTRY_CODE !== global (if it does, global is part of the link and not the country code)
        if (
            countryCodeFromLink?.toLowerCase() === countryCodeFromState?.toLowerCase() &&
            (languageCodeFromLink?.toLowerCase() === languageCodeFromState?.toLowerCase() ||
                defaultLanguageCode?.toLowerCase() === languageCodeFromState?.toLowerCase()) &&
            countryCodeFromLink?.toLowerCase() !== DEFAULT_COUNTRY_CODE.toLowerCase()
        ) {
            // link contains valid country and language, and it's the same as we have in state
            //   or link contains only country and it matches default language of that country in state
            setLink(
                languageCodeFromLink &&
                    languageCodeFromLink?.toLowerCase() !== languageCodeFromState?.toLowerCase()
                    ? `${languageCodeFromLink?.toLowerCase()}/${idFromLink}`
                    : idFromLink,
            );
        } else if (countriesLanguagesArray.length) {
            // else, check if linkFromProps /country/language is valid
            const {
                countryCode: validCountryCode,
                languageCode: validLanguageCode,
                languageDirection: validDirectionCode,
                regionId: validRegionId,
            } = getValidCountryLanguageCode(
                countriesLanguagesArray,
                countryCodeFromLink,
                languageCodeFromLink,
                regionIdFromLS,
            );
            if (
                countryCodeFromLink?.toLowerCase() === validCountryCode?.toLowerCase() &&
                countryCodeFromLink?.toLowerCase() !== DEFAULT_COUNTRY_CODE.toLowerCase()
            ) {
                // if it's valid, set it to state (just for this session)
                dispatch(setCountryInfo({ code: countryCodeFromLink, sessionOnly: true }));
                dispatch(
                    setLanguageInfo({
                        code: validLanguageCode,
                        direction: validDirectionCode,
                        sessionOnly: true,
                    }),
                );
                dispatch(setRegionInfo({ id: validRegionId, sessionOnly: true }));
                if (
                    !languageCodeFromLink ||
                    languageCodeFromLink?.toLowerCase() === validLanguageCode?.toLowerCase()
                ) {
                    setLink(idFromLink);
                } else {
                    setLink(`${languageCodeFromLink}${idFromLink ? "/" : ""}${idFromLink}`);
                }
                // if it's not valid, treat the whole linkFromProps as a the link
            } else {
                //         // check if codes from state are valid, if so, set the link
                if (!!countryCodeFromState && !!languageCodeFromState) {
                    setLink(linkWithoutLeadingSlash);
                }
                //         // finally, attempt to get country language codes from localstorage or ip address
                else dispatch(getCountryLanguageDataFromLocalStorageOrIp());
            }
        }
    }, [linkFromProps, countriesLanguagesArray, countryCodeFromState, languageCodeFromState]);

    return link;
};
