import React, { useContext, useEffect, useState } from "react";
import "./SpeechListing.css";
import { MultiSelect } from "primereact/multiselect";
import { Paginator } from "primereact/paginator";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useDispatch, useSelector } from "react-redux";
import { fetchSpeechGalleryData } from "../../redux/speechGallerySlice";
import Loader from "../Loader/loader";
import { useLocation, useNavigate } from "react-router-dom";
import { ScrollTop } from "../../utils/scrollTopFunction";
import { WindowContext } from "../WindowResize/resize";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import CustomLink from "../../utils/customLink";
import { getDateToDisplay } from "../../utils/dateFunctions";
import useGetArticleMeta from "../../utils/useGetArticleMeta";
import { ZERO_WIDTH_CHAR } from "../../constants/displayConstants";

function SpeechListing({ contents: contentsArray, colors: colorObj }) {
    const { pathname, search } = useLocation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    const { isMobile } = useContext(WindowContext);

    const [paramsReq, setParamsReq] = useState({
        page: 0,
        page_size: 12,
    });

    const { contents, isLoading } = useSelector((state) => state.speech);

    const dynamicFilters = useSelector((state) => state.speech.dynamicFilters);
    const showFilters = Object.keys(dynamicFilters).reduce(
        (acc, cur) => acc + dynamicFilters[cur].Items.length,
        0,
    );

    const [speechGalleryDetails, setSpeechGalleryDetails] = useState([]);

    const [searchKeyword, setSearchKeyword] = useState("");

    const [totalPageCount, setTotalPageCount] = useState(0);

    const [selectedFilters, setSelectedFilters] = useState({});

    const [prevParams, setPrevParams] = useState({});

    const { getSection, getTheme } = useGetArticleMeta();

    useEffect(() => {
        const queryParams = new URLSearchParams(search);

        const req = { ...paramsReq, page: 0 };
        const selectedFilters = {};

        queryParams.forEach((value, key) => {
            if (key.startsWith("search_") && !key.startsWith("search_text")) {
                const filterKey = key.slice(7);
                const filterValues = value ? value.split(",") : [];

                if (filterValues.length > 0) {
                    let saveItems = [];
                    filterValues.forEach((item) => {
                        if (item !== "") {
                            saveItems.push({ itemName: decodeURIComponent(item) });
                        }
                    });

                    selectedFilters[filterKey] = saveItems;
                    req[`search_${filterKey}`] = filterValues;
                }
            } else if (key === "search_text") {
                setSearchKeyword(value);
                req.search_text = value;
            } else {
                req[key] = value;
            }
        });

        setSelectedFilters(selectedFilters);
        setParamsReq(req);
    }, [search]);

    useEffect(() => {
        if (
            Object.keys(paramsReq).length > 0 &&
            JSON.stringify(paramsReq) !== JSON.stringify(prevParams)
        ) {
            setPrevParams(paramsReq);
            let reqObj = { ...paramsReq };
            reqObj.page = reqObj.page + 1;
            const apiLink = contentsArray[0].contentKey.slice(0, -1);
            dispatch(fetchSpeechGalleryData({ reqObj, apiLink }));
        }
    }, [paramsReq, dispatch, prevParams]);

    useEffect(() => {
        const queryParams = [];

        for (const key in paramsReq) {
            if (
                paramsReq[key] &&
                key.startsWith("search_") &&
                !key.startsWith("search_text") &&
                paramsReq[key].length > 0
            ) {
                queryParams.push(`${key}=${paramsReq[key].map(encodeURIComponent).join(",")}`);
            }
        }

        if (searchKeyword !== "") {
            queryParams.push(`search_text=${encodeURIComponent(searchKeyword)}`);
        }

        navigate(`/${pathname.slice(1)}?${queryParams.join("&")}`);
    }, [paramsReq, navigate, searchKeyword, pathname]);

    const fetchSpeechListingData = () => {
        setSpeechGalleryDetails([]);
        setTotalPageCount(0);

        if (contents) {
            let articleArr = [];
            articleArr = articleArr.concat(contents.articles);
            setSpeechGalleryDetails(articleArr);
            setTotalPageCount(contents.total);
        }
    };

    useEffect(() => {
        fetchSpeechListingData();
    }, [contents, isLoading]);

    const onChangeSelectFilter = (value, filterName) => {
        let arr = [];
        let arrWithItemName = [];

        for (const iterator of value) {
            let str = encodeURIComponent(iterator.itemName);
            arr.push(str);
            arrWithItemName.push({ itemName: iterator.itemName });
        }

        const key = `search_${filterName.toLowerCase()}`;

        setParamsReq((prevState) => ({
            ...prevState,
            [key]: arr,
            page: 0,
        }));

        setSelectedFilters((prev) => ({
            ...prev,
            [filterName]: arrWithItemName,
        }));
    };

    const clearSearch = () => {
        setParamsReq({
            ...paramsReq,
            search_text: "",
            page: 0,
        });
        setSearchKeyword("");
    };

    const onPageChange = (event) => {
        setParamsReq({ ...paramsReq, page_size: event.rows, page: event.page });
        ScrollTop();
    };

    const clearAllFilter = () => {
        setParamsReq({
            page: 0,
            page_size: 12,
        });
        setSelectedFilters({});
        navigate("/", { replace: true });

        setTimeout(() => {
            const cleanUrl = `/${pathname.slice(1)}`;
            navigate(cleanUrl, { replace: true });
        }, 0);
    };

    return (
        <>
            <Loader isLoading={isLoading} />

            <section
                className="speechListingSection"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="marBottom">
                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div
                                            className="search-hldr"
                                            style={{ "--primary_color": colorObj?.primary_color }}
                                        >
                                            <i className="bi bi-search"></i>
                                            {searchKeyword !== "" && (
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={clearSearch}
                                                >
                                                    <i className="search1 fa fa-close"></i>
                                                </span>
                                            )}
                                            <input
                                                type="text"
                                                className="form-control ml-2"
                                                placeholder={t("placeHolder.search")}
                                                value={searchKeyword}
                                                onChange={(e) => setSearchKeyword(e.target.value)}
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {Object.keys(dynamicFilters).map(
                                        (filterKey) =>
                                            dynamicFilters[filterKey].Items &&
                                            dynamicFilters[filterKey].Items.length > 0 && (
                                                <div
                                                    className="col-md-3 filters-main"
                                                    key={filterKey}
                                                >
                                                    <div className="card flex justify-content-center">
                                                        <MultiSelect
                                                            placeholder={
                                                                dynamicFilters[filterKey].Label
                                                            }
                                                            value={selectedFilters[filterKey]}
                                                            onChange={(e) =>
                                                                onChangeSelectFilter(
                                                                    e.value,
                                                                    filterKey,
                                                                )
                                                            }
                                                            options={
                                                                dynamicFilters[filterKey].Items ||
                                                                []
                                                            }
                                                            optionLabel="itemName"
                                                            maxSelectedLabels={
                                                                dynamicFilters[filterKey].Items
                                                                    ? dynamicFilters[filterKey]
                                                                          .Items.length
                                                                    : 3
                                                            }
                                                            className="w-full md:w-20rem"
                                                            filter
                                                            style={{
                                                                "--accent_color1":
                                                                    colorObj?.accent_color1,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            ),
                                    )} */}

                                    {Object.keys(showFilters).length ? (
                                        <div className="filtersHldr">
                                            <div className="col-12">
                                                <div
                                                    className="filters-main"
                                                    style={{
                                                        borderTop: `1px solid ${colorObj?.accent_color2}`,
                                                        borderBottom: `1px solid ${colorObj?.accent_color2}`,
                                                    }}
                                                >
                                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                                        <div
                                                            className="filter-label"
                                                            style={{
                                                                color: colorObj?.accent_color3,
                                                            }}
                                                        >
                                                            {t("btnText.filters:")}
                                                        </div>

                                                        {Object.keys(dynamicFilters).map(
                                                            (filterKey) =>
                                                                dynamicFilters[filterKey].Items &&
                                                                dynamicFilters[filterKey].Items
                                                                    .length > 0 && (
                                                                    <div
                                                                        className="tag-dropdown-main"
                                                                        key={filterKey}
                                                                    >
                                                                        <MultiSelect
                                                                            placeholder={
                                                                                dynamicFilters[
                                                                                    filterKey
                                                                                ].Label
                                                                            }
                                                                            value={
                                                                                selectedFilters[
                                                                                    filterKey
                                                                                ]
                                                                            }
                                                                            onChange={(e) =>
                                                                                onChangeSelectFilter(
                                                                                    e.value,
                                                                                    filterKey,
                                                                                )
                                                                            }
                                                                            options={
                                                                                dynamicFilters[
                                                                                    filterKey
                                                                                ].Items || []
                                                                            }
                                                                            optionLabel="itemName"
                                                                            selectedItemsLabel={`${selectedFilters[
                                                                                filterKey
                                                                            ]
                                                                                ?.slice(0, 2)
                                                                                ?.map(
                                                                                    (i) =>
                                                                                        i.itemName,
                                                                                )
                                                                                ?.join(", ")}${
                                                                                selectedFilters[
                                                                                    filterKey
                                                                                ]?.length > 2
                                                                                    ? " ..."
                                                                                    : ""
                                                                            }`}
                                                                            maxSelectedLabels={0}
                                                                            filter
                                                                            style={{
                                                                                "--accent_color1":
                                                                                    colorObj?.accent_color1,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ),
                                                        )}

                                                        {Object.keys(selectedFilters).length >
                                                            0 && (
                                                            <div
                                                                className="clear-all-txt"
                                                                style={{
                                                                    color: colorObj?.primary_color,
                                                                }}
                                                                onClick={() => clearAllFilter()}
                                                            >
                                                                {t("btnText.clearAll")}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                                        <div className="search-hldr">
                                                            <i className="fa fa-search"></i>
                                                            {searchKeyword !== "" && (
                                                                <span
                                                                    className="cursor-pointer"
                                                                    onClick={clearSearch}
                                                                >
                                                                    <i className="search1 fa fa-close"></i>
                                                                </span>
                                                            )}
                                                            <input
                                                                type="text"
                                                                className="form-control ml-2"
                                                                placeholder={t(
                                                                    "placeHolder.search",
                                                                )}
                                                                value={searchKeyword}
                                                                onChange={(e) => {
                                                                    setSearchKeyword(
                                                                        e.target.value,
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb-3">
                            <div className="row">
                                {speechGalleryDetails.length > 0 && (
                                    <>
                                        {speechGalleryDetails?.map((value, i) => {
                                            const theme = getTheme(value?.breadcrumb);
                                            const section = getSection(value?.breadcrumb);

                                            return (
                                                <div className="col-md-4 mb-4" key={i}>
                                                    <div className="newsCard card">
                                                        <div className="cardImg">
                                                            {!!value?.posterImage && (
                                                                <div
                                                                    className="playIconHldr"
                                                                    style={{
                                                                        "--accent_color4":
                                                                            colorObj.accent_color4,
                                                                    }}
                                                                >
                                                                    <i className="bi bi-play-circle"></i>
                                                                </div>
                                                            )}
                                                            <CustomLink to={getLink(value?.path)}>
                                                                <ImageWithObjectFit
                                                                    className="cursor-pointer"
                                                                    src={
                                                                        value?.image?.src ||
                                                                        value?.posterImage
                                                                    }
                                                                />
                                                            </CustomLink>
                                                        </div>

                                                        <div className="card-body d-flex flex-column text-start">
                                                            <div
                                                                className="tag-heading"
                                                                style={{
                                                                    color: colorObj.primary_color,
                                                                }}
                                                            >
                                                                {theme || ZERO_WIDTH_CHAR}
                                                            </div>

                                                            <CustomLink to={getLink(value?.path)}>
                                                                <h3
                                                                    className="hover:underline cursor-pointer"
                                                                    style={{
                                                                        color: colorObj?.accent_color1,
                                                                    }}
                                                                >
                                                                    {value && value.title
                                                                        ? value.title
                                                                        : ""}
                                                                </h3>
                                                            </CustomLink>
                                                            <div className="authorDateMain mt-auto">
                                                                <span
                                                                    className="author me-1"
                                                                    style={{
                                                                        "--accent_color1":
                                                                            colorObj.accent_color1,
                                                                    }}
                                                                >
                                                                    {section}{" "}
                                                                </span>
                                                                <span
                                                                    className="date"
                                                                    style={{
                                                                        "--accent_color3":
                                                                            colorObj.accent_color3,
                                                                    }}
                                                                >
                                                                    {section && value?.date
                                                                        ? " • "
                                                                        : ""}
                                                                    {getDateToDisplay(value?.date)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                        </div>

                        {speechGalleryDetails.length > 0 && (
                            <div
                                style={{
                                    "--accent_color4": colorObj?.accent_color4,
                                    "--primary_color": colorObj?.primary_color,
                                }}
                            >
                                <Paginator
                                    first={paramsReq.page * paramsReq.page_size}
                                    rows={paramsReq.page_size}
                                    totalRecords={totalPageCount}
                                    onPageChange={onPageChange}
                                    pageLinkSize={isMobile ? 1 : 5}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default SpeechListing;
