import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../utils/networkRequests";

const SLICE_NAME = "messages";

export const fetchMessages = createAsyncThunk(
    `${SLICE_NAME}/fetchMessages`,
    async (_, { getState }) => {
        const INITIAL_REQ_OBJ = {
            type: "get_messages",
        };
        try {
            const state = getState();

            const response = await axiosInstance.get(
                `${state.auth?.configs?.MESSAGES_API_LINK}?type=${INITIAL_REQ_OBJ.type}`,
            );
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const fetchMessageById = createAsyncThunk(
    `${SLICE_NAME}/fetchMessageById`,
    async ({ id }, { getState }) => {
        const INITIAL_REQ_OBJ = {
            type: "view_message",
            id,
        };
        try {
            const state = getState();
            const apiLink = state.auth.configs?.MESSAGES_API_LINK;

            const response = await axiosInstance.get(
                `${apiLink}?type=${INITIAL_REQ_OBJ.type}&message_id=${INITIAL_REQ_OBJ.id}`,
            );
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const deleteMessageByIds = createAsyncThunk(
    `${SLICE_NAME}/deleteMessageByIds`,
    async ({ messages_id }, { getState }) => {
        const type = "update_message_status";

        const req = {
            messages_id,
            status: "deleteMessage",
        };
        try {
            const state = getState();
            const apiLink = state.auth.configs?.MESSAGES_API_LINK;

            const response = await axiosInstance.post(`${apiLink}?type=${type}`, req);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const readMessageByIds = createAsyncThunk(
    `${SLICE_NAME}/readMessageByIds`,
    async ({ messages_id }, { getState }) => {
        const type = "update_message_status";

        const req = {
            messages_id,
            status: "readMessage",
        };
        try {
            const state = getState();
            const apiLink = state.auth.configs?.MESSAGES_API_LINK;

            const response = await axiosInstance.post(`${apiLink}?type=${type}`, req);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const unReadMessageByIds = createAsyncThunk(
    `${SLICE_NAME}/unReadMessageByIds`,
    async ({ messages_id }, { getState }) => {
        const type = "update_message_status";

        const req = {
            messages_id,
            status: "unreadMessage",
        };
        try {
            const state = getState();
            const apiLink = state.auth.configs?.MESSAGES_API_LINK;

            const response = await axiosInstance.post(`${apiLink}?type=${type}`, req);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

const initialState = {
    messages: [],
    messageDetails: {},
    readMessageStatus: {},
    unreadMessageStatus: {},
    deleteMessageStatus: {},
};

export const messagesSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        // Example reducer:
        // fetchContentAndLayout: (state) => {
        //   const contentKeysMappedToLowercase = {}
        //   Object.keys(contentFromII.content).forEach(
        //     (k) => (contentKeysMappedToLowercase[k.toLowerCase()] = contentFromII.content[k]),
        //   )
        //   state.content = contentKeysMappedToLowercase
        //   state.layout = layoutFromII.layout
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMessages.fulfilled, (state, action) => {
                const data = action.payload?.data;
                state.messages = data;
            })
            .addCase(fetchMessageById.fulfilled, (state, action) => {
                const data = action.payload.data;
                state.messageDetails = data;
            })
            .addCase(readMessageByIds.fulfilled, (state, action) => {
                const data = action.payload;
                state.readMessageStatus = data;
            })
            .addCase(unReadMessageByIds.fulfilled, (state, action) => {
                const data = action.payload;
                state.unreadMessageStatus = data;
            })
            .addCase(deleteMessageByIds.fulfilled, (state, action) => {
                const data = action.payload;
                state.deleteMessageStatus = data;
            });
    },
});

export default messagesSlice.reducer;
