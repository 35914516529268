import React from "react";

const InstagramSvg = () => {
    return (
        <svg
            width="41"
            height="32"
            viewBox="0 0 41 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M34.9605 8.86191C34.9436 7.59777 34.707 6.34619 34.2611 5.16317C33.8745 4.1653 33.2839 3.25905 32.5272 2.50233C31.7705 1.74561 30.8642 1.15506 29.8664 0.768412C28.6985 0.330033 27.4648 0.0929931 26.2177 0.0673869C24.612 -0.00438467 24.1029 -0.0244141 20.027 -0.0244141C15.951 -0.0244141 15.4286 -0.024414 13.8346 0.0673869C12.588 0.0931813 11.3549 0.330218 10.1876 0.768412C9.18956 1.15479 8.28317 1.74525 7.52642 2.502C6.76966 3.25875 6.17921 4.16514 5.79283 5.16317C5.35357 6.3301 5.11704 7.56353 5.09347 8.81017C5.0217 10.4175 5 10.9266 5 15.0026C5 19.0785 5 19.5993 5.09347 21.1949C5.11851 22.4434 5.35385 23.6752 5.79283 24.8453C6.17985 25.843 6.77075 26.749 7.52776 27.5055C8.28476 28.2619 9.19123 28.8521 10.1893 29.2384C11.3533 29.6944 12.5867 29.9484 13.8363 29.9895C15.4436 30.0612 15.9527 30.0829 20.0286 30.0829C24.1046 30.0829 24.627 30.0829 26.221 29.9895C27.4681 29.9649 28.7019 29.7284 29.8697 29.2901C30.8673 28.903 31.7733 28.3123 32.53 27.5556C33.2866 26.799 33.8774 25.893 34.2645 24.8953C34.7034 23.727 34.9388 22.4952 34.9638 21.245C35.0356 19.6393 35.0573 19.1303 35.0573 15.0526C35.0539 10.9767 35.0539 10.4592 34.9605 8.86191ZM20.017 22.7105C15.7541 22.7105 12.3007 19.2571 12.3007 14.9942C12.3007 10.7313 15.7541 7.27793 20.017 7.27793C22.0634 7.27793 24.0261 8.09089 25.4732 9.53798C26.9203 10.9851 27.7332 12.9477 27.7332 14.9942C27.7332 17.0407 26.9203 19.0034 25.4732 20.4504C24.0261 21.8975 22.0634 22.7105 20.017 22.7105ZM28.0404 8.79181C27.0439 8.79181 26.2411 7.9873 26.2411 6.99251C26.2411 6.75633 26.2876 6.52247 26.378 6.30427C26.4683 6.08607 26.6008 5.88781 26.7678 5.72081C26.9348 5.5538 27.1331 5.42133 27.3513 5.33095C27.5695 5.24057 27.8033 5.19405 28.0395 5.19405C28.2757 5.19405 28.5096 5.24057 28.7278 5.33095C28.946 5.42133 29.1442 5.5538 29.3112 5.72081C29.4782 5.88781 29.6107 6.08607 29.7011 6.30427C29.7915 6.52247 29.838 6.75633 29.838 6.99251C29.838 7.9873 29.0335 8.79181 28.0404 8.79181Z"
                fill="#4F4F4F"
            />
            <path
                d="M20.0201 20.0071C22.7884 20.0071 25.0325 17.763 25.0325 14.9948C25.0325 12.2265 22.7884 9.98242 20.0201 9.98242C17.2519 9.98242 15.0078 12.2265 15.0078 14.9948C15.0078 17.763 17.2519 20.0071 20.0201 20.0071Z"
                fill="#4F4F4F"
            />
        </svg>
    );
};

export default InstagramSvg;
