import React, { useEffect, useState } from "react";
import "./Application.css";
import { useSelector } from "react-redux";
import { useGetLink } from "../../utils/useOpenLink";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Link } from "react-router-dom";

function ApplicationPage({ contents: contentsArray = [] }) {
    const content = useSelector((state) => state.layout.content);

    const [contentsData, setContentsData] = useState([]);

    const { getLink } = useGetLink();

    useEffect(() => {
        const fetchDefaultData = () => {
            const mainContent = content[contentsArray[0].contentKey].map((k) => content[k]);

            if (mainContent.length) setContentsData(mainContent);
        };
        fetchDefaultData();
    }, []);

    return (
        <>
            <section className="updateSecation liteGreenBg">
                <div className="container">
                    <div className="row row-flex" style={{ justifyContent: "center" }}>
                        {contentsData.map((value, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                                <div className="post-card">
                                    <div className="iconMain">
                                        <ImageWithObjectFit
                                            className="cursor-pointer"
                                            src={value.image.src}
                                        />
                                    </div>
                                    <div className="cardTitle">{value.title}</div>
                                    <h3>{value.summary}</h3>
                                    <p>{value.body}</p>
                                    <Link to={getLink(value.content_reference)}>
                                        <span className="btn btn-main mt-4">View More</span>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ApplicationPage;
