import React from "react";
import "./InfoPanel3.css";
import ImageWithObjectFit from "../ImageWrapperComponent";
import parse from "html-react-parser";

function InfoPanel3({ section_image, heading, body }) {
    return (
        <>
            <div className="home-emanage py-50">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="img-container">
                                <ImageWithObjectFit src={section_image.src} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="desc-container">
                                <h3>{heading}</h3>

                                <div className="download-container">
                                    <p>{parse(body)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InfoPanel3;
