import React from "react";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useGetLink } from "../../utils/useOpenLink";
import CustomLink from "../../utils/customLink";

function ImageCard({ content }) {
    const { image, title, posterImage, content_source, type, path, contentLink } = content;

    const { getLink } = useGetLink();

    return (
        <div className="card">
            <div className="cardImg">
                <ImageWithObjectFit className="cursor-pointer" src={image?.src || posterImage} />
            </div>
            <CustomLink
                to={getLink(content_source === "Content" ? contentLink : path, type)}
                type={type}
            >
                <div className="card-body">
                    <h3 className="cursor-pointer underline">{title}</h3>
                </div>
            </CustomLink>
        </div>
    );
}

export default ImageCard;
