import React from "react";
import CustomLink from "../../utils/customLink";

function LabelOnlyMenuResponsive({ value, openLink, colors }) {
    return (
        <>
            <div className="mega-menu">
                <div className="accordion navMainWrapper" id="navigationMain">
                    <div className="accordion-item">
                        <CustomLink to={openLink(value.content_reference)}>
                            <h2 className="accordion-header">
                                <span
                                    className="accordion-button"
                                    onClick={() => openLink(value.content_reference)}
                                    style={{ color: colors?.accent_color1 }}
                                >
                                    {value.heading}
                                </span>
                            </h2>
                        </CustomLink>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LabelOnlyMenuResponsive;
