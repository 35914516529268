import moment from "moment";

export const convertServerToLocalDateObj = (dateTimeString = "") => {
    if (dateTimeString === "") return new Date();

    const dateObj = new Date(dateTimeString.endsWith("Z") ? dateTimeString : `${dateTimeString}Z`);

    return dateObj;
};

export const getDateToDisplay = (date) => {
    if (!date) return date;

    const targetDate = moment(date);
    const now = moment();

    const daysDifference = now.diff(targetDate, "days");

    if (daysDifference > 45) {
        const d = new Date(date);
        const fd = d.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
        return fd;
    }
    return moment(date).fromNow();
};
