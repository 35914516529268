import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getConfigs } from "../redux/authSlice";

const useGetConfigs = () => {
    const dispatch = useDispatch();
    const configs = useSelector((state) => state.auth.configs, shallowEqual);

    const isLoaded = Object.keys(configs).length;

    useEffect(() => {
        if (!isLoaded) dispatch(getConfigs());
    }, []);

    return isLoaded;
};

export default useGetConfigs;
