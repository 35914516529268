import React from "react";
import "./HeroStatic.css";

function HeroStatic({ heading, section_image, colors: colorObj }) {
    return (
        <>
            <div className="HeroStaticMain pb-15">
                <div className="sliderBox">
                    <img src={section_image?.src} />
                    <div className="container">
                        <div className="textMain">
                            <h3 style={{ color: colorObj?.accent_color4 }}>{heading}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeroStatic;
