import ApplicationPage from "../../components/Applications";
import { CardCarousel } from "../../components/Carousel/CardCarousel";
import EventCarousel from "../../components/Carousel/EventCarousel";
import { FeaturedCardCarousel } from "../../components/Carousel/FeaturedCardCarousel";
import FeaturedCardCarousel2 from "../../components/Carousel/FeaturedCardCarousel2";
import FullWidthCarousel from "../../components/Carousel/FullWidthCarousel";
import { HeroCarousel } from "../../components/Carousel/HeroCarousel";
import ImageCardCarousel from "../../components/Carousel/ImageCardCarousel";
import ImageOnlyCardCarousel from "../../components/Carousel/ImageOnlyCardCarousel";
import MultiFocusCarousel from "../../components/Carousel/MultiFocusCarousel";
import TagCarousel from "../../components/Carousel/TagCarousel";
import TagCarousel2 from "../../components/Carousel/TagCarousel2";
import TitleCarousel from "../../components/Carousel/TitleCarousel";
import { TrendingNowCarousel } from "../../components/Carousel/TrendingNowCarousel";
import TwoColumnHeroPage from "../../components/Carousel/TwoColumnHeroCarousel";
import EmbeddedIframe from "../../components/EmbeddedIframe";
import FeaturedCallOut from "../../components/FeaturedCallOut";
import GallerySection from "../../components/GallerySection";
import HeaderComponent from "../../components/Header";
import HeroFocus from "../../components/HeroFocus";
import HeroFocus2 from "../../components/HeroFocus2";
import IconLinks from "../../components/IconLinks/IconLink";
import { InfoPanel } from "../../components/InfoPanel";
import InfoPanel2 from "../../components/InfoPanel2";
import MessagesList from "../../components/MessagesList";
import KeyPoints from "../../components/KeyPoints";
import MailingList from "../../components/MailingList";
import QuotePanel from "../../components/QuotePanel";
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner";
import SimpleBanner2 from "../../components/SimpleBanner2";
import { TempComp } from "../../components/TempComp";
import { TextLinks } from "../../components/TextLinks";
import TextLinks2 from "../../components/TextLinks2";
import TileLinks from "../../components/TileLinks";
import Articles2 from "../Articles2";
import DirectoryPage from "../Directory";
import Funeralslisting from "../../components/FuneralsListing";
import ParagraphBlock from "../../components/ParagraphBlock";
import ParagraphMedia from "../../components/ParagraphMedia";
import ParagraphHighlight from "../../components/ParagraphHighlight";
import SpeechDetails from "../../components/SpeechDetails/SpeechDetails";
import SpeechListing from "../../components/SpeechDetails/SpeechListing";
import SearchResult from "../../components/SearchResult";
import PageNotFound from "../../components/PageNotFound";
import MediaHeader from "../../components/MediaHeader";
import MediaFooter from "../../components/MediaFooter";
import MediaFeaturedCardCarousel from "../../components/Carousel/MediaFeaturedCardCarousel";
import MediaCardCarousel from "../../components/Carousel/MediaCardCarousel";
import MediaArticles from "../MediaArticles";
import IconTextLinks from "../../components/IconTextLinks";
import FeaturedCard from "../../components/FeaturedCard";
import FeatureSection2Left from "../../components/FeatureSection2Left";
import FeatureSection2Right from "../../components/FeatureSection2Right";
import FooterComponent2 from "../../components/Footer2";
import FooterComponent3 from "../../components/Footer3";
import FooterComponent1 from "../../components/Footer1";
import InfoPanel3 from "../../components/InfoPanel3";
import InstantSearchCustomApp from "../../components/InstantSearch/InstantSearchCustom";
import ProgrammeInfoPanel from "../../components/ProgrammeInfoPanel";
import ProgrammeStdBody from "../../components/ProgrammeStdBody";
import ProgrammeStdMenu from "../../components/ProgrammeStdMenu";
import ProgrammeStdContent from "../../components/ProgrammeStdContent";
import UpcomingEvents from "../../components/UpcomingEvents";
import HeroSectionRight from "../../components/HeroSectionRight";
import HeroSectionLeft from "../../components/HeroSectionLeft";
import Accordion2 from "../../components/Accordion2";
import AccordionWithTab from "../../components/Accordion";
import GallerySection2 from "../../components/GallerySection2";
import FeatureTabs from "../../components/FeatureTabs";
import Articles from "../Articles";
import RelatedCardCarousel2 from "../../components/Carousel/RelatedCardCarousel2";
import ArticleHeading from "../../components/ArticleHeading";
import HeroStatic from "../../components/HeroStatic";
import RelatedCardCarousel from "../../components/Carousel/RelatedCardCarousel";
import EventListing from "../../components/EventListing";
import EventDetails2 from "../../components/EventDetails2";
import PageNotFound2 from "../../components/PageNotFound2";
import FeaturedEvents1 from "../../components/FeaturedEvents1";
import FeaturedEvents2 from "../../components/FeaturedEvents2/FeaturedEvents2";
import Articles3 from "../Articles3";
import ScrollingBlocks from "../../components/ScrollingBlocks";
import ScrollingArticle from "../../components/ScrollingArticle";
import IconLinks2 from "../../components/IconLinks2";
import EventHero from "../../components/EventHero";
import Article4 from "../Articles4";
import JobSpotDetails from "../../components/JobSpotDetails";
import JobSpotListing from "../../components/JobSpotListing";
import FeatureCarousel from "../../components/FeatureCarousel";
import FeatureCarousel3 from "../../components/FeatureCarousel3";
import HeroStatic2 from "../../components/HeroStatic2";
import HeroStatic3 from "../../components/HeroStatic3";
import InfoPanelTopAlign from "../../components/InfoPanelTopAlign";
import CountDownWithImage from "../../components/CountDownWithImage";
import HeroImageMenu from "../../components/HeroImageMenu";
import TextList from "../../components/TextList/TextList";
import ImageTileLinks from "../../components/ImageTileLinks";
import ApplicationTileLinks from "../../components/ApplicationTileLinks";
import HeaderComponent2 from "../../components/Header/Header2";
import ImageTiles from "../../components/ImageTiles";
import PaddingBlock from "../../components/PaddingBlock";
import ProgrammeTemplate from "../../components/ProgrammeTemplate";
import ProgramHeader from "../../components/ProgramHeader";
import ContactUs from "../../components/ContactUs";
import SubscribeNewsLetter from "../../components/SubscribeNewsLetter";

const layoutTypes = {
    TWO_COLUMN_HERO: TwoColumnHeroPage,
    HERO_CAROUSEL: HeroCarousel,
    CARD_CAROUSEL: CardCarousel,
    TAG_CLOUD: TempComp,
    INFO_PANEL: InfoPanel,
    TRENDING_NOW: TrendingNowCarousel,
    IMAGE_HERO_CAROUSEL: FullWidthCarousel,
    TEXT_LINKS: TextLinks,
    FEATURED_CARD_CAROUSEL: FeaturedCardCarousel,
    EVENT_CAROUSEL: EventCarousel,
    HEADER: HeaderComponent,
    HEADER2: HeaderComponent2,
    EVENTS_LIST: EventListing,
    IMAGE_CARD_CAROUSEL: ImageCardCarousel,
    IMAGE_ONLY_CARD_CAROUSEL: ImageOnlyCardCarousel,
    INFO_PANEL2: InfoPanel2,
    HERO_FOCUS: HeroFocus,
    STANDARD_FOOTER: FooterComponent1,
    STANDARD_FOOTER2: FooterComponent2,
    STANDARD_FOOTER3: FooterComponent3,
    APPLICATIONS_STANDARD: ApplicationPage,
    ARTICLE: Articles,
    DIRECTORY: DirectoryPage,
    MESSAGES: MessagesList,
    IFRANE: EmbeddedIframe,
    GALLERY: GallerySection,
    TAG_CAROUSEL: TagCarousel,
    FEATURED_CAROUSEL: FeaturedCardCarousel2,
    TAG_CAROUSEL_2: TagCarousel2,
    QUOTE_PANEL: QuotePanel,
    TEXT_LINKS_2: TextLinks2,
    MULTI_FOCUS: MultiFocusCarousel,
    SIMPLE_BANNER: SimpleBanner,
    TITLE_CAROUSEL: TitleCarousel,
    SIMPLE_BANNER_2: SimpleBanner2,
    ICON_LINK: IconLinks,
    ICON_LINK2: IconLinks2,
    HERO_FOCUS_2: HeroFocus2,
    FEATURD_CALLOUT: FeaturedCallOut,
    KEY_POINTS: KeyPoints,
    TILE_LINKS: TileLinks,
    MAILINGLIST: MailingList,
    FUNERALSSAMARS: Funeralslisting,
    PARAGRAPH: ParagraphBlock,
    PARAGRAPH_HIGH_LIGHT: ParagraphHighlight,
    PARAGRAPH_MEDIA: ParagraphMedia,
    SEARCH: SearchResult,
    ERROR: PageNotFound,
    SPEECH_DETAILS: SpeechDetails,
    SPEECHES: SpeechListing,
    MEDIA_HEADER: MediaHeader,
    MEDIA_FOOTER: MediaFooter,
    MEDIA_FEATURED_CARD_CAROUSEL: MediaFeaturedCardCarousel,
    MEDIA_CARD_CAROUSEL: MediaCardCarousel,
    ARTICLE_MEDIA: MediaArticles,
    ICON_TEXT_LINKS: IconTextLinks,
    FEATURED_CARD: FeaturedCard,
    INFO_PANEL3: InfoPanel3,
    SEARCH_2: InstantSearchCustomApp,
    FEATURE_SECTION2_LEFT: FeatureSection2Left,
    FEATURE_SECTION2_RIGHT: FeatureSection2Right,
    ACCORDION_2: Accordion2,
    ACCORDION: AccordionWithTab,
    PROGRAMME_INFO_PANEL: ProgrammeInfoPanel,
    PROGRAMME_STD_BODY: ProgrammeStdBody,
    PROGRAMME_STD_CONTACT: ProgrammeStdContent,
    PROGRAMME_STD_MENU: ProgrammeStdMenu,
    EVENT_LIST_V2: UpcomingEvents,
    HERO_SECTION1_RIGHT: HeroSectionRight,
    HERO_SECTION1_LEFT: HeroSectionLeft,
    GALLERY2: GallerySection2,
    FEATURE_TABS: FeatureTabs,
    ARTICLE_2: Articles2,
    RELATED_CARDS_2: RelatedCardCarousel2,
    ARTICLE_TITLE: ArticleHeading,
    HERO_STATIC: HeroStatic,
    RELATED_CARD: RelatedCardCarousel,
    EVENT_DETAILS: EventDetails2,
    ERROR2: PageNotFound2,
    FEATURD_EVENTS1: FeaturedEvents1,
    FEATURD_EVENTS2: FeaturedEvents2,
    ARTICLE_3: Articles3,
    SCROLLING_TITLE_HERO: ScrollingBlocks,
    SCROLLING_ARTICLE: ScrollingArticle,
    EVENT_HERO: EventHero,
    ARTICLE_4: Article4,
    JOB_SPOT: JobSpotListing,
    JOB_SPOT_DETAILS: JobSpotDetails,
    FEATURED_CAROUSEL_1: FeatureCarousel,
    FEATURED_CAROUSEL_3: FeatureCarousel3,
    HERO_STATIC_2: HeroStatic2,
    HERO_STATIC_3: HeroStatic3,
    INFO_PANEL_TOP_ALIGN: InfoPanelTopAlign,
    COUNTDOWN: CountDownWithImage,
    HERO_IMAGE_WITH_MENU: HeroImageMenu,
    TEXT_LIST: TextList,
    IMAGE_TILE_LINKS: ImageTileLinks,
    APPLICATION_TILE_LINKS: ApplicationTileLinks,
    IMAGE_TILES: ImageTiles,
    PADDING_BLOCK: PaddingBlock,
    PROGRAMME_TEMPLATE1: ProgrammeTemplate,
    PROGRAM_HEADER: ProgramHeader,
    CONTACT_US: ContactUs,
    SUBSCRIBE_NEWSLETTER: SubscribeNewsLetter,
};

const layoutTypeLookup = {
    two_column_hero: layoutTypes.TWO_COLUMN_HERO,
    hero_carousel: layoutTypes.HERO_CAROUSEL,
    card_carousel: layoutTypes.CARD_CAROUSEL,
    2: layoutTypes.TAG_CLOUD,
    info_panel: layoutTypes.INFO_PANEL,
    trending_now: layoutTypes.TRENDING_NOW,
    image_hero_carousel: layoutTypes.IMAGE_HERO_CAROUSEL,
    text_links: layoutTypes.TEXT_LINKS,
    featured_card_carousel: layoutTypes.FEATURED_CARD_CAROUSEL,
    events_carousel: layoutTypes.EVENT_CAROUSEL,
    header: layoutTypes.HEADER,
    header2: layoutTypes.HEADER2,
    events_list: layoutTypes.EVENTS_LIST,
    image_card_carousel: layoutTypes.IMAGE_CARD_CAROUSEL,
    image_only_card_carousel: layoutTypes.IMAGE_ONLY_CARD_CAROUSEL,
    info_panel_2: layoutTypes.INFO_PANEL2,
    hero_focus: layoutTypes.HERO_FOCUS,
    standard_footer: layoutTypes.STANDARD_FOOTER,
    standard_footer2: layoutTypes.STANDARD_FOOTER2,
    standard_footer3: layoutTypes.STANDARD_FOOTER3,
    applications_standard: layoutTypes.APPLICATIONS_STANDARD,
    article: layoutTypes.ARTICLE,
    directory: layoutTypes.DIRECTORY,
    messages: layoutTypes.MESSAGES,
    iframe: layoutTypes.IFRANE,
    gallery: layoutTypes.GALLERY,
    featured_carousel_2: layoutTypes.FEATURED_CAROUSEL,
    tag_carousel: layoutTypes.TAG_CAROUSEL,
    tag_carousel_2: layoutTypes.TAG_CAROUSEL_2,
    quote_panel: layoutTypes.QUOTE_PANEL,
    text_links_2: layoutTypes.TEXT_LINKS_2,
    multi_focus: layoutTypes.MULTI_FOCUS,
    simple_banner: layoutTypes.SIMPLE_BANNER,
    title_carousel: layoutTypes.TITLE_CAROUSEL,
    simple_banner_2: layoutTypes.SIMPLE_BANNER_2,
    icon_link: layoutTypes.ICON_LINK,
    icon_link2: layoutTypes.ICON_LINK2,
    hero_focus_2: layoutTypes.HERO_FOCUS_2,
    featured_callout: layoutTypes.FEATURD_CALLOUT,
    key_points: layoutTypes.KEY_POINTS,
    tile_links: layoutTypes.TILE_LINKS,
    mailinglist: layoutTypes.MAILINGLIST,
    funerals_samars: layoutTypes.FUNERALSSAMARS,
    paragraph: layoutTypes.PARAGRAPH,
    paragraph_highlight: layoutTypes.PARAGRAPH_HIGH_LIGHT,
    paragraph_media: layoutTypes.PARAGRAPH_MEDIA,
    search: layoutTypes.SEARCH,
    error: layoutTypes.ERROR,
    speech_details: layoutTypes.SPEECH_DETAILS,
    speeches: layoutTypes.SPEECHES,
    media_header: layoutTypes.MEDIA_HEADER,
    media_footer: layoutTypes.MEDIA_FOOTER,
    media_featured_card_carou: layoutTypes.MEDIA_FEATURED_CARD_CAROUSEL,
    media_card_carousel: layoutTypes.MEDIA_CARD_CAROUSEL,
    article_media: layoutTypes.ARTICLE_MEDIA,
    icon_text_links: layoutTypes.ICON_TEXT_LINKS,
    featured_card: layoutTypes.FEATURED_CARD,
    info_panel_3: layoutTypes.INFO_PANEL3,
    search2: layoutTypes.SEARCH_2,
    feature_section_2_left: layoutTypes.FEATURE_SECTION2_LEFT,
    feature_section_2_right: layoutTypes.FEATURE_SECTION2_RIGHT,
    accordion: layoutTypes.ACCORDION,
    accordion_2: layoutTypes.ACCORDION_2,
    program_info_panel: layoutTypes.PROGRAMME_INFO_PANEL,
    programme_std_body: layoutTypes.PROGRAMME_STD_BODY,
    programme_std_contact: layoutTypes.PROGRAMME_STD_CONTACT,
    programme_std_menu: layoutTypes.PROGRAMME_STD_MENU,
    events_list_v2: layoutTypes.EVENT_LIST_V2,
    hero_section1_left: layoutTypes.HERO_SECTION1_LEFT,
    hero_section1_right: layoutTypes.HERO_SECTION1_RIGHT,
    gallery2: layoutTypes.GALLERY2,
    feature_tabs: layoutTypes.FEATURE_TABS,
    article_2: layoutTypes.ARTICLE_2,
    related_cards_2: layoutTypes.RELATED_CARDS_2,
    article_title: layoutTypes.ARTICLE_TITLE,
    hero_static: layoutTypes.HERO_STATIC,
    related_cards: layoutTypes.RELATED_CARD,
    event_details: layoutTypes.EVENT_DETAILS,
    error2: layoutTypes.ERROR2,
    featured_events1: layoutTypes.FEATURD_EVENTS1,
    featured_events2: layoutTypes.FEATURD_EVENTS2,
    article_3: layoutTypes.ARTICLE_3,
    scrolling_title_hero: layoutTypes.SCROLLING_TITLE_HERO,
    scrolling_article: layoutTypes.SCROLLING_ARTICLE,
    event_hero: layoutTypes.EVENT_HERO,
    article_4: layoutTypes.ARTICLE_4,
    job_spot: layoutTypes.JOB_SPOT,
    job_spot_details: layoutTypes.JOB_SPOT_DETAILS,
    featured_carousel_1: layoutTypes.FEATURED_CAROUSEL_1,
    featured_carousel_3: layoutTypes.FEATURED_CAROUSEL_3,
    hero_static_2: layoutTypes.HERO_STATIC_2,
    hero_static_3: layoutTypes.HERO_STATIC_3,
    info_panel_top_align: layoutTypes.INFO_PANEL_TOP_ALIGN,
    countdown: layoutTypes.COUNTDOWN,
    hero_image_with_menu: layoutTypes.HERO_IMAGE_WITH_MENU,
    text_list: layoutTypes.TEXT_LIST,
    image_tile_links: layoutTypes.IMAGE_TILE_LINKS,
    application_tile_links: layoutTypes.APPLICATION_TILE_LINKS,
    image_tiles: layoutTypes.IMAGE_TILES,
    padding_block: layoutTypes.PADDING_BLOCK,
    program_template1: layoutTypes.PROGRAMME_TEMPLATE1,
    program_header: layoutTypes.PROGRAM_HEADER,
    contact_us: layoutTypes.CONTACT_US,
    subscribe_newsletter: layoutTypes.SUBSCRIBE_NEWSLETTER,
};

export default layoutTypeLookup;
