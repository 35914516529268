export const APP_COLORS = (colorObj) => {
    return {
        "--background_color": colorObj?.accent_color4,
        "--primary_color": colorObj?.primary_color,
        "--accent_color1": colorObj?.accent_color1,
        "--accent_color2": colorObj?.accent_color2,
        "--accent_color3": colorObj?.accent_color3,
        "--accent_color4": colorObj?.accent_color4,
    };
};
