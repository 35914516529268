import React from "react";

const YoutubeSvg = () => {
    return (
        <svg
            width="31"
            height="32"
            viewBox="0 0 31 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M15.7484 2.37769H15.9225C17.5304 2.38401 25.6774 2.44726 27.8741 3.08398C28.5382 3.2783 29.1433 3.65708 29.629 4.18245C30.1147 4.70782 30.464 5.36138 30.6419 6.07783C30.8395 6.879 30.9784 7.9395 31.0723 9.03373L31.0918 9.253L31.1349 9.80117L31.1505 10.0204C31.2777 11.9475 31.2933 13.7522 31.2953 14.1465V14.3046C31.2933 14.7136 31.2757 16.6406 31.1349 18.6478L31.1192 18.8692L31.1016 19.0884C31.0038 20.2944 30.8591 21.4919 30.6419 22.3732C30.4645 23.09 30.1154 23.7438 29.6296 24.2693C29.1438 24.7948 28.5384 25.1733 27.8741 25.3671C25.605 26.0249 16.9807 26.0713 15.7855 26.0734H15.5078C14.9033 26.0734 12.4035 26.0607 9.78234 25.9637L9.4498 25.9511L9.27962 25.9427L8.94514 25.9279L8.61065 25.9131C6.4394 25.8098 4.37183 25.6433 3.41922 25.365C2.75511 25.1714 2.14986 24.7932 1.66408 24.2681C1.17831 23.743 0.829077 23.0896 0.651373 22.3732C0.434249 21.4941 0.289499 20.2944 0.191695 19.0884L0.176047 18.8671L0.160398 18.6478C0.0638612 17.2192 0.0103669 15.7876 0 14.3552L0 14.0959C0.00391215 13.6426 0.0195608 12.0761 0.125189 10.3472L0.138881 10.1301L0.14475 10.0204L0.160398 9.80117L0.203432 9.253L0.222993 9.03373C0.316884 7.9395 0.455766 6.87689 0.653329 6.07783C0.830733 5.36109 1.17983 4.70722 1.66562 4.18176C2.15142 3.6563 2.75683 3.27772 3.42118 3.08398C4.37379 2.8099 6.44136 2.64123 8.6126 2.53581L8.94514 2.52105L9.28158 2.5084L9.4498 2.50208L9.78429 2.48732C11.6459 2.42275 13.5083 2.38691 15.3708 2.37979H15.7484V2.37769ZM12.5189 9.14547V19.3035L20.6503 14.2266L12.5189 9.14547Z"
                    fill="#4F4F4F"
                />
            </g>
        </svg>
    );
};

export default YoutubeSvg;
