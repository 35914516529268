import React from "react";

const SearchSvg = ({ colorObj }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
        >
            <path
                d="M40 39.5L30.5903 30.0903M30.5903 30.0903C32.1998 28.4807 33.4766 26.5699 34.3477 24.4669C35.2188 22.3638 35.6672 20.1099 35.6672 17.8336C35.6672 15.5573 35.2188 13.3033 34.3477 11.2003C33.4766 9.0973 32.1998 7.18646 30.5903 5.57689C28.9807 3.96732 27.0699 2.69053 24.9669 1.81944C22.8638 0.948346 20.6099 0.5 18.3336 0.5C16.0573 0.5 13.8033 0.948346 11.7003 1.81944C9.5973 2.69053 7.68646 3.96732 6.07689 5.57689C2.82621 8.82757 1 13.2364 1 17.8336C1 22.4307 2.82621 26.8396 6.07689 30.0903C9.32757 33.341 13.7364 35.1672 18.3336 35.1672C22.9307 35.1672 27.3396 33.341 30.5903 30.0903Z"
                stroke={colorObj?.primary_color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SearchSvg;
