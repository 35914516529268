import React from "react";

const FacebookSvg = () => {
    return (
        <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.6369 15.8204C30.6369 7.35819 23.7807 0.501953 15.3184 0.501953C6.85624 0.501953 0 7.35819 0 15.8204C0 23.4796 5.55911 29.8417 12.9095 30.9535V20.2677H9.01812V15.8204H12.9095V12.4849C12.9095 8.65532 15.1949 6.49344 18.6539 6.49344C20.3834 6.49344 22.1129 6.80228 22.1129 6.80228V10.5701H20.1981C18.2833 10.5701 17.6656 11.7437 17.6656 12.9791V15.8204H21.9276L21.2482 20.2677H17.6656V30.9535C25.016 29.8417 30.6369 23.4796 30.6369 15.8204Z"
                fill="#4F4F4F"
            />
        </svg>
    );
};

export default FacebookSvg;
