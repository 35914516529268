import React, { useEffect, useState } from "react";
import "./ImageOnlyCardCarousel.css";
import ImageOnlyCard from "../Card/ImageOnlyCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation, Pagination } from "swiper/modules";

function ImageOnlyCardCarousel({
    heading,
    background,
    contents: contentsArray,
    number_of_rows,
    colors: colorObj,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    useEffect(() => {
        setContentReferenceData(contentsArray);
    }, [contentsArray, number_of_rows]);

    const swiperCarousel = {
        slidesPerView: 3,
        navigation: true,
        grid: {
            rows: number_of_rows,
            fill: "row",
        },
        spaceBetween: 20,
        pagination: {
            clickable: true,
        },
        modules: [Grid, Pagination, Navigation],
        breakpoints: {
            345: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                slidesPerView: 2,
                spaceBetween: 20,
            },
            576: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                slidesPerView: 2,
            },
            1024: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                spaceBetween: 20,
                slidesPerView: 3,
            },
            1280: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                spaceBetween: 20,
                slidesPerView: 3,
            },
        },
    };

    return (
        <>
            <section className="ismailiCentresSecation py-50" style={{ background: background }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="titleMain"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                <h3>{heading}</h3>
                            </div>
                        </div>

                        <div className="col-12">
                            <Swiper
                                {...swiperCarousel}
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                {contentReferenceData.map((card) => (
                                    <SwiperSlide key={card.title}>
                                        <ImageOnlyCard content={card} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ImageOnlyCardCarousel;
