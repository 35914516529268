import { useRef } from "react";

const useToast = () => {
    const toastRef = useRef(null);

    const showToast = (severity, summary, detail, life = 3000) => {
        if (toastRef.current) {
            toastRef.current.show({ severity, summary, detail, life });
        }
    };

    return {
        toastRef,
        showToast,
    };
};

export default useToast;
