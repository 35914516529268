import React, { useCallback, useEffect, useState } from "react";
import "./ImageCardCarousel.css";
import ImageCard from "../Card/ImageCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperCarousel } from "../../utils/carouselFunction";
import { useSelector } from "react-redux";
import * as posterImage from "../../utils/usePosterImage";
import { useFilteredContent } from "../../utils/filterContent";
import { ARTICLE_DETAILS } from "../../constants/strings";

function ImageCardCarousel({
    heading,
    background,
    contents: contentsArray,
    colors: colorObj,
    block_source,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const filteredContent = useFilteredContent(contentsArray, content);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = content[contentKey];
            let resp = respKeys.map((k) => content[k]);
            resp = JSON.parse(JSON.stringify(resp));
            for await (const element of resp) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        if (block_source === ARTICLE_DETAILS) {
            setCurrentContent(contentsArray[0].contentKey);
        } else {
            const arr = contentsArray
                .map((c) => {
                    if (c.content_source === "Content") {
                        return {
                            image: c.content_image,
                            microsite: "",
                            title: c.heading,
                            author: "",
                            date: "",
                            summary: c.body,
                            contentLink: c.contentKey,
                            nid: "",
                            type: c.type,
                            content_source: c.content_source,
                        };
                    }
                    return null;
                })
                .filter(Boolean);
            setContentReferenceData([...filteredContent, ...arr]);
        }
    }, [filteredContent, contentsArray, block_source]);

    return (
        <>
            <section className="CulturalSecation py-50" style={{ background: background }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="titleMain"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                <h3>{heading}</h3>
                            </div>
                        </div>

                        <div className="col-12">
                            <Swiper
                                {...swiperCarousel}
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                }}
                            >
                                {contentReferenceData.map((card, index) => (
                                    <SwiperSlide key={index}>
                                        <ImageCard content={card} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ImageCardCarousel;
