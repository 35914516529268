import React, { useEffect, useState } from "react";
import "./FeaturedEvents1.css";
import { Swiper, SwiperSlide } from "swiper/react";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { useSelector } from "react-redux";
import FeaturedEventCard from "../Card/FeaturedEventCard";
import { Navigation, Pagination } from "swiper/modules";

function FeaturedEvents1({ contents: contentToRender = [], heading, colors: colorObj }) {
    const { direction } = useCustomTranslation();

    const [keysArr, setKeysArr] = useState([]);

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    useEffect(() => {
        const fetchDefaultData = () => {
            const resp = content[contentToRender[0].contentKey].map((k) => content[k]);

            if (resp.length) {
                setKeysArr(Object.keys(resp));
                setContentReferenceData(resp);
            }
        };
        fetchDefaultData();
    }, []);

    const swiperCarousel = {
        spaceBetween: 10,
        slidesPerView: 3,
        modules: [Pagination, Navigation],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        navigation: true,
        breakpoints: {
            345: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 1,
            },
            1024: {
                spaceBetween: 20,
                slidesPerView: 3,
            },
            1280: {
                spaceBetween: 20,
                slidesPerView: 3,
            },
        },
    };

    return (
        <div className="featuredEvents1Section py-50">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-heading" style={{ color: colorObj?.primary_color }}>
                            {heading}
                        </div>
                        <div className="content-heading" style={{ color: colorObj?.accent_color1 }}>
                            {contentToRender[0]?.heading}
                        </div>
                    </div>
                    <div className="col-12">
                        <Swiper
                            dir={direction}
                            key={direction}
                            {...swiperCarousel}
                            style={{ "--primary_color": colorObj?.primary_color }}
                        >
                            {keysArr.map((value) => (
                                <SwiperSlide key={value}>
                                    <FeaturedEventCard
                                        content={contentReferenceData[value]}
                                        key={value}
                                        colors={colorObj}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeaturedEvents1;
