import React from "react";
import "./Accordion2.css";
import parse from "html-react-parser";
import { Accordion, AccordionTab } from "primereact/accordion";

function Accordion2({ contents: contentToRender = [], colors: colorObj }) {
    return (
        <>
            <section className="accordion2Wrapper py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Accordion multiple>
                                {contentToRender &&
                                    contentToRender.map((items, index) => (
                                        <AccordionTab
                                            header={items.heading}
                                            key={index}
                                            style={{
                                                "--primary_color": colorObj?.primary_color,
                                                "--accent_color4": colorObj?.accent_color4,
                                                "--accent_color2": colorObj?.accent_color2,
                                                "--accent_color1": colorObj?.accent_color1,
                                            }}
                                        >
                                            {items.body && parse(items.body)}
                                        </AccordionTab>
                                    ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Accordion2;
