import React, { useEffect, useState } from "react";
import "./MultiFocus.css";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import useCustomTranslation from "../../utils/useCustomTranslation";

function MultiFocusCarousel({ contents: contentToRender = [], heading, body }) {
    const navigate = useNavigate();

    const content = useSelector((state) => state.layout.content);

    const [multiFocusContentsRefData, setMultiFocusContentsRefData] = useState([]);

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        const fetchDefaultData = () => {
            const cardContent = content[contentToRender[0].contentKey].map((k) => content[k]);
            setMultiFocusContentsRefData(cardContent);
        };
        fetchDefaultData();
    }, [content]);

    const clickHeading = (id) => {
        const index = contentToRender.findIndex((e) => e.id === id);
        const cardContent = content[contentToRender[index].contentKey].map((k) => content[k]);
        setMultiFocusContentsRefData(cardContent);
    };

    const handleClick = (id) => navigate(`/articles/${id}`);

    const { dir } = useCustomTranslation();

    const swiperCarousel = {
        spaceBetween: 10,
        slidesPerView: 1,
        modules: [Pagination, Navigation],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        dir: dir,
        key: dir,
        navigation: true,
        breakpoints: {
            345: {
                slidesPerView: 1.1,
                spaceBetween: 10,
            },
            576: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 1,
            },
            1024: {
                spaceBetween: 10,
                slidesPerView: 1,
            },
            1280: {
                spaceBetween: 10,
                slidesPerView: 1,
            },
        },
    };

    return (
        <>
            <div className="weekHistoryWrapper">
                <div className="container">
                    <div className="row">
                        <h3 className="title">{heading}</h3>
                        <span className="header-body-text">{body}</span>
                        <div className="date list-group list-group-horizontal overflow-auto">
                            {contentToRender.map((value, index) => (
                                <span
                                    className={`cursor-pointer ${
                                        selectedIndex === index ? "active" : ""
                                    }`}
                                    key={value.heading}
                                    onClick={() => {
                                        clickHeading(value.id);
                                        setSelectedIndex(index);
                                    }}
                                >
                                    {value.heading}
                                </span>
                            ))}
                        </div>
                        <div className="col-12">
                            <Swiper {...swiperCarousel}>
                                {multiFocusContentsRefData.map((card) => (
                                    <SwiperSlide key={card.title}>
                                        <div className="card-image">
                                            <ImageWithObjectFit
                                                className="cursor-pointer"
                                                src={card.image.src}
                                                onClick={() => handleClick(card.nid)}
                                            />
                                            <div className="card-body">
                                                <h3
                                                    className="card-title underline"
                                                    onClick={() => handleClick(card.nid)}
                                                >
                                                    {card.title}
                                                </h3>
                                                <p className="card-description">
                                                    {parse(card.summary)}
                                                </p>
                                                <div className="date">{card.date}</div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MultiFocusCarousel;
