import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HeroCard } from "../Card/HeroCard";
import "./HeroCarousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import useCustomTranslation from "../../utils/useCustomTranslation";
import * as posterImage from "../../utils/usePosterImage";

export const HeroCarousel = ({ contents: contentToRender = [] }) => {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = content[contentKey];
            let resp = respKeys.map((k) => content[k]);
            resp = JSON.parse(JSON.stringify(resp));
            for await (const element of resp) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        setCurrentContent(contentToRender[0].contentKey);
    }, []);

    const { dir } = useCustomTranslation();

    const swiperCarousel = {
        spaceBetween: 10,
        slidesPerView: 3,
        modules: [Pagination],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        dir: { dir },
        key: { dir },
        breakpoints: {
            345: {
                slidesPerView: 1.1,
                spaceBetween: 10,
            },
            576: {
                slidesPerView: 1.3,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2,
            },
            1024: {
                spaceBetween: 10,
                slidesPerView: 3,
            },
            1280: {
                spaceBetween: 10,
                slidesPerView: 3,
            },
        },
    };

    return (
        <div className="w-full flex hero-card pb-15">
            <Swiper {...swiperCarousel}>
                {contentReferenceData.map((card) => (
                    <SwiperSlide key={card.title}>
                        <HeroCard content={card} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
