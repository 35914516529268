import React from "react";

function Eyesvg() {
    return (
        <>
            <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.9999 16.83C9.60992 16.83 7.66992 14.89 7.66992 12.5C7.66992 10.11 9.60992 8.16998 11.9999 8.16998C14.3899 8.16998 16.3299 10.11 16.3299 12.5C16.3299 14.89 14.3899 16.83 11.9999 16.83ZM11.9999 9.66998C10.4399 9.66998 9.16992 10.94 9.16992 12.5C9.16992 14.06 10.4399 15.33 11.9999 15.33C13.5599 15.33 14.8299 14.06 14.8299 12.5C14.8299 10.94 13.5599 9.66998 11.9999 9.66998Z"
                    fill="#282828"
                />
                <path
                    d="M12.0001 21.52C8.24008 21.52 4.69008 19.32 2.25008 15.5C1.19008 13.85 1.19008 11.16 2.25008 9.49998C4.70008 5.67998 8.25008 3.47998 12.0001 3.47998C15.7501 3.47998 19.3001 5.67998 21.7401 9.49998C22.8001 11.15 22.8001 13.84 21.7401 15.5C19.3001 19.32 15.7501 21.52 12.0001 21.52ZM12.0001 4.97998C8.77008 4.97998 5.68008 6.91998 3.52008 10.31C2.77008 11.48 2.77008 13.52 3.52008 14.69C5.68008 18.08 8.77008 20.02 12.0001 20.02C15.2301 20.02 18.3201 18.08 20.4801 14.69C21.2301 13.52 21.2301 11.48 20.4801 10.31C18.3201 6.91998 15.2301 4.97998 12.0001 4.97998Z"
                    fill="#282828"
                />
            </svg>
        </>
    );
}

export default Eyesvg;
