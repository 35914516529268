import React, { useState } from "react";
import "./JobSpotDetails.css";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { savejobApplication } from "../../redux/jobSpotSlice";
import { Toast } from "primereact/toast";
import { ALPHABET_SPACE_PATTERN, EMAIL_PATTERN, MOBILE_NO_PATTERN } from "../../utils/pattern";
import BreadCrumbs from "../BreadCrumbs";
import useToast from "../../utils/useToast";
import { removeSpaceOnFirstCharacter } from "../../utils/spaceNotAllowedOnFirstCharacter";
import useCustomTranslation from "../../utils/useCustomTranslation";
import MoneySvg from "../../assets/MoneySvg";
import Loader from "../Loader/loader";
import { allowedExtensionsString } from "../../utils/string";

function JobSpotDetails({ contents: contentsArray, colors: colorObj, heading }) {
    const l = useLocation();

    const { toastRef, showToast } = useToast();

    const { title, salary, sector, location, expiry_date, description, contract_type } =
        l.state.jobDetails;

    const { t } = useCustomTranslation();

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [fileName, setFileName] = useState("");

    const [isSubmitting, setIsSubmitting] = useState(false);

    const dispatch = useDispatch();

    const apiLink =
        contentsArray.length > 0 && contentsArray[0].contentKey
            ? contentsArray[0].contentKey
            : null;

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        message: "",
        linkedin: "",
        job_id: 1,
        file: null,
    });

    const [formErrors, setFormErrors] = useState({
        name: t("validationString.name"),
        email: t("validationString.email"),
        mobile: t("validationString.mobile"),
        file: t("validationString.file"),
        message: t("validationString.message"),
    });

    const validateForm = () => {
        const errors = {};

        if (!ALPHABET_SPACE_PATTERN.test(formData.name) || formData.name.length < 3) {
            errors.name =
                formData.name === ""
                    ? t("validationString.name")
                    : t("validationString.validName2");
        }

        if (!EMAIL_PATTERN.test(formData.email) || formData.email.length < 3) {
            errors.email =
                formData.email === ""
                    ? t("validationString.email")
                    : t("validationString.validEmail");
        }

        if (!MOBILE_NO_PATTERN.test(formData.mobile)) {
            errors.mobile =
                formData.mobile === ""
                    ? t("validationString.mobile")
                    : t("validationString.validMobile");
        }

        if (!formData.file) {
            errors.file = t("validationString.file");
        }

        if (!formData.message) {
            errors.message = t("validationString.message");
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (fieldName, value) => {
        setFormData({ ...formData, [fieldName]: value });

        if (isFormSubmitted) {
            validateForm();
        }

        switch (fieldName) {
            case "name": {
                const isValid = ALPHABET_SPACE_PATTERN.test(value);
                setFormErrors({
                    ...formErrors,
                    [fieldName]:
                        value === ""
                            ? t("validationString.name")
                            : !isValid
                            ? t("validationString.validName")
                            : value !== "" && value.length >= 3
                            ? ""
                            : t("validationString.minCharacter"),
                });
                break;
            }
            case "email": {
                const isValid = EMAIL_PATTERN.test(value);
                setFormErrors({
                    ...formErrors,
                    [fieldName]:
                        value === ""
                            ? t("validationString.email")
                            : !isValid
                            ? t("validationString.validEmail")
                            : value !== "" && value.length >= 3
                            ? ""
                            : t("validationString.minCharacter"),
                });
                break;
            }
            case "mobile": {
                const isValid = MOBILE_NO_PATTERN.test(value);
                setFormErrors({
                    ...formErrors,
                    [fieldName]:
                        value === ""
                            ? t("validationString.mobile")
                            : !isValid
                            ? t("validationString.validMobile")
                            : "",
                });
                break;
            }

            case "message": {
                setFormErrors({
                    ...formErrors,
                    [fieldName]:
                        value === ""
                            ? t("validationString.message")
                            : value !== "" && value.length >= 3
                            ? ""
                            : t("validationString.minCharacter"),
                });
                break;
            }
            default:
                break;
        }
    };

    const handleFileChange = (e) => {
        const fileInput = e.target;
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.split(".").pop().toLowerCase();
            const allowedExtensions = [
                allowedExtensionsString.docx,
                allowedExtensionsString.doc,
                allowedExtensionsString.pdf,
            ];
            setFileName(fileName);

            if (allowedExtensions.includes(fileExtension)) {
                setFormData((prev) => ({
                    ...prev,
                    file,
                }));
                setFileName(fileName);
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    file: "",
                }));
                fileInput.value = "";
            } else {
                showToast(
                    "error",
                    t("constantString.error"),
                    t("validationString.invalidTypeMessage"),
                );
                setFormData((prev) => ({
                    ...prev,
                    file: null,
                }));
                setFileName("");
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    file: t("validationString.validFile"),
                }));
                fileInput.value = "";
            }
        }
    };

    const handleSubmit = () => {
        setIsFormSubmitted(true);
        setIsSubmitting(true);
        const isValid = validateForm();

        if (!formData.file) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                file: t("validationString.file"),
            }));
            setFileName("");
        }

        if (isValid && formData.file) {
            const data = new FormData();
            Object.keys(formData).forEach((key) => {
                data.append(key, formData[key]);
            });
            dispatch(savejobApplication({ formData: data, apiLink }))
                .then((result) => {
                    if (result.payload.success) {
                        showToast("success", t("constantString.success"), result.payload.message);
                        setFormData({
                            name: "",
                            email: "",
                            linkedin: "",
                            message: "",
                            mobile: "",
                            job_id: 1,
                            file: "",
                        });
                        setFileName("");
                        setIsFormSubmitted(false);
                        setIsSubmitting(false);
                    }
                })
                .catch(() => {
                    showToast(
                        "error",
                        t("constantString.error"),
                        t("validationString.errorMessage"),
                    );
                });
        } else {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Loader isLoading={isSubmitting} />

            <Toast ref={toastRef} />
            <section className="jobDetailWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3
                                className="heading mt-3"
                                style={{ "--accent_color1": colorObj?.accent_color1 }}
                            >
                                {heading}
                            </h3>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <BreadCrumbs lableName={t("breadCrumbsString.jobDetails")} />
                        </div>
                        <div className="col-12">
                            <div className="jobBoxHldr">
                                <h5 style={{ "--primary_color": colorObj?.primary_color }}>
                                    {title}
                                </h5>
                                <div className="txt-main-hldr">
                                    {sector && (
                                        <div
                                            className="jobDag"
                                            style={{ "--accent_color1": colorObj?.accent_color1 }}
                                        >
                                            {sector}
                                        </div>
                                    )}
                                    <div className="timeLocHldr">
                                        {contract_type && (
                                            <span
                                                className="tagMain"
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                    "--accent_color2": colorObj?.accent_color2,
                                                }}
                                            >
                                                <i className="fa fa-clock-o"></i>
                                                {contract_type}
                                            </span>
                                        )}
                                        {salary !== "" && (
                                            <span
                                                className="tagMain"
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                    "--accent_color2": colorObj?.accent_color2,
                                                }}
                                            >
                                                <span className="me-1">
                                                    <MoneySvg />
                                                </span>
                                                {salary}
                                            </span>
                                        )}
                                        {location && (
                                            <span
                                                className="tagMain"
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                    "--accent_color2": colorObj?.accent_color2,
                                                }}
                                            >
                                                <i className="bi bi-geo-alt"></i>
                                                {location}
                                            </span>
                                        )}
                                    </div>
                                    {expiry_date && (
                                        <div
                                            className="applicationDate"
                                            style={{
                                                "--accent_color1": colorObj?.accent_color1,
                                            }}
                                        >
                                            <span className="me-1">
                                                {t("constantString.applicationDeadline")}
                                            </span>{" "}
                                            {expiry_date}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div
                                className="jobDescriptionHldr"
                                style={{
                                    "--accent_color1": colorObj?.accent_color1,
                                }}
                            >
                                <h4>{t("constantString.jobDescription")}</h4>
                                {parse(description)}
                            </div>
                            <hr />
                        </div>
                        <div className="col-12 applicationHldr">
                            <h2
                                style={{
                                    "--accent_color1": colorObj?.accent_color1,
                                }}
                            >
                                {t("constantString.application")}
                            </h2>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {t("constantString.name")}
                                            <sup>*</sup>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={formData.name}
                                            onChange={(e) =>
                                                handleChange(e.target.name, e.target.value)
                                            }
                                            name="name"
                                            onKeyDown={(event) => {
                                                removeSpaceOnFirstCharacter(event);
                                            }}
                                        />
                                        {isFormSubmitted && formErrors.name !== "" && (
                                            <small className="error-message">
                                                {formErrors.name}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {t("constantString.email")}
                                            <sup>*</sup>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            value={formData.email}
                                            onChange={(e) =>
                                                handleChange(e.target.name, e.target.value)
                                            }
                                        />
                                        {isFormSubmitted && formErrors.email !== "" && (
                                            <small className="error-message">
                                                {formErrors.email}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {t("constantString.mobile")}
                                            <sup>*</sup>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={(e) =>
                                                handleChange(e.target.name, e.target.value)
                                            }
                                        />
                                        {isFormSubmitted && formErrors.mobile !== "" && (
                                            <small className="error-message">
                                                {formErrors.mobile}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <div className="form-group mb-0">
                                                <label className="form-label">
                                                    {t("constantString.uploadCV")}
                                                    <sup>*</sup>
                                                </label>
                                            </div>
                                            <div
                                                className="input-group"
                                                style={{
                                                    "--primary_color": colorObj?.primary_color,
                                                    "--accent_color4": colorObj?.accent_color4,
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={fileName}
                                                    readOnly
                                                />
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="fileInput"
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                />
                                                <span
                                                    className="input-group-text"
                                                    onClick={() =>
                                                        document.getElementById("fileInput").click()
                                                    }
                                                >
                                                    {t("btnText.choosefile")}
                                                </span>
                                            </div>
                                            {isFormSubmitted && formErrors.file && (
                                                <small className="error-message">
                                                    {formErrors.file}
                                                </small>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    {t("constantString.linkedIn")}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={formData.linkedin}
                                                    onChange={(e) =>
                                                        handleChange(e.target.name, e.target.value)
                                                    }
                                                    name="linkedin"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                    <div className="form-group">
                                        <label className="form-label">
                                            {t("constantString.coverLetter")}
                                            <sup>*</sup>
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="6"
                                            name="message"
                                            value={formData.message}
                                            onChange={(e) =>
                                                handleChange(e.target.name, e.target.value)
                                            }
                                        ></textarea>
                                        {isFormSubmitted && formErrors.message !== "" && (
                                            <small className="error-message">
                                                {formErrors.message}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button
                                        type="button"
                                        className="btnGreen"
                                        style={{
                                            "--accent_color4": colorObj?.accent_color4,
                                            "--primary_color": colorObj?.primary_color,
                                        }}
                                        onClick={() => handleSubmit()}
                                    >
                                        {t("btnText.submit")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default JobSpotDetails;
