import React, { useCallback, useEffect, useState } from "react";
import "./ImageTiles.css";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import * as posterImage from "../../utils/usePosterImage";
import { useFilteredContent } from "../../utils/filterContent";
import { useGetLink } from "../../utils/useOpenLink";
import CustomLink from "../../utils/customLink";
import { ARTICLE_DETAILS } from "../../constants/strings";

function ImageTiles({
    heading,
    body,
    contents: contentsArray,
    block_source,
    colors: colorObj,
    block_buttons,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const [visibleCount, setVisibleCount] = useState(18);

    const filteredContent = useFilteredContent(contentsArray, content);

    const { getLink } = useGetLink();

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = content[contentKey];
            let resp = respKeys.map((k) => content[k]);
            resp = JSON.parse(JSON.stringify(resp));
            for await (const element of resp) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        if (block_source === ARTICLE_DETAILS) {
            setCurrentContent(contentsArray[0].contentKey);
        } else {
            const arr = contentsArray
                .map((c) => {
                    if (c.content_source === "Content") {
                        return {
                            image: c.content_image,
                            title: c.heading,
                            summary: c.body,
                            contentLink: c.contentKey,
                            nid: "",
                            type: c.type,
                            content_source: c.content_source,
                        };
                    }
                    return null;
                })
                .filter(Boolean);
            setContentReferenceData([...filteredContent, ...arr]);
        }
    }, [filteredContent, contentsArray, block_source]);

    const handleShowMore = () => {
        setVisibleCount(contentReferenceData.length);
    };

    const showLessCards = () => {
        setVisibleCount(18);
    };

    return (
        <>
            <section className="SportscategoryWrapper py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12 titleHldr">
                            <h2 style={{ "--primary_color": colorObj?.primary_color }}>
                                {heading}
                            </h2>
                            <p style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                {parse(body)}
                            </p>
                        </div>
                        {contentReferenceData.slice(0, visibleCount).map((v, i) => (
                            <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-3 px-2" key={i}>
                                <div className={`sportCard ${v.image?.src ? "" : "no-image"}`}>
                                    {v.image?.src && (
                                        <CustomLink
                                            to={getLink(
                                                v?.content_source === "Content"
                                                    ? v?.contentLink
                                                    : v?.path,
                                            )}
                                            type={v?.type}
                                        >
                                            <div className="sportCardImage">
                                                <img
                                                    src={v.image?.src}
                                                    className="cursor-pointer"
                                                    alt={v.title}
                                                ></img>
                                            </div>
                                        </CustomLink>
                                    )}
                                    <div className="sport-body d-flex flex-column">
                                        <CustomLink
                                            to={getLink(
                                                v?.content_source === "Content"
                                                    ? v?.contentLink
                                                    : v?.path,
                                            )}
                                            type={v?.type}
                                        >
                                            <h3
                                                className="cursor-pointer"
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                }}
                                            >
                                                {v?.title}
                                            </h3>
                                        </CustomLink>
                                        <p style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                            {v?.summary && parse(v?.summary)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {block_buttons?.length > 0 && (
                            <div className="col-12 my-3 d-flex justify-content-center">
                                {block_buttons.map((v) => (
                                    <CustomLink to={getLink(v.action)} key={v.label}>
                                        <button
                                            type="button"
                                            className="button me-3"
                                            style={{
                                                "--primary_color": colorObj?.primary_color,
                                                "--accent_color4": colorObj?.accent_color4,
                                            }}
                                        >
                                            {v.label}
                                        </button>
                                    </CustomLink>
                                ))}
                            </div>
                        )}

                        {visibleCount < contentReferenceData.length && (
                            <div className="showMore" onClick={handleShowMore}>
                                Show More<i className="bi bi-arrow-down btn-icon ms-2"></i>
                            </div>
                        )}
                        {visibleCount > 18 && (
                            <div className="showMore" onClick={showLessCards}>
                                Show Less<i className="bi bi-arrow-up btn-icon ms-2"></i>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ImageTiles;
