import React from "react";
import { useStats } from "react-instantsearch";
import useCustomTranslation from "../../utils/useCustomTranslation";

function TotalRecords() {
    const { nbHits } = useStats();

    const { t } = useCustomTranslation();

    return (
        <div className="total-records">
            {nbHits} {t("constantString.resultsString")}
        </div>
    );
}

export default TotalRecords;
