import moment from "moment";
import React from "react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";

function FeaturedCard2({ content }) {
    const navigate = useNavigate();

    const { image, title, author, date, summary, nid, posterImage } = content;

    const handleClick = () => navigate(`/articles/${nid}`);

    return (
        <>
            <div className="newsCard card">
                <div className="dateName">
                    <span className="name">{author}</span>
                    <span className="date">
                        {author && <i className="dot"></i>}
                        {date ? moment(date).format(`MMM DD${","} YYYY`) : ""}
                    </span>
                </div>
                <div className="cardImg">
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={image?.src || posterImage}
                        onClick={handleClick}
                    />
                </div>
                <div className="sub-card-body">
                    <h3>{title}</h3>
                    <div className="textMain">{summary ? parse(summary) : ""}</div>
                </div>
            </div>
        </>
    );
}

export default FeaturedCard2;
