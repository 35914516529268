import React from "react";

function Pill({ onClick, text }) {
    return (
        <li
            className="cursor-pointer bg-accent_color2 text-accent_color1 px-4 py-2 rounded-md hover:bg-primary_color hover:text-accent_color4"
            onClick={onClick}
        >
            {text}
        </li>
    );
}

export default Pill;
