import { useMemo } from "react";

export const useFilteredContent = (contentToRender, content) => {
    return useMemo(() => {
        // get article content and return only the first index card
        const processArticleContent = (contentKey) => {
            const respKeys = content[contentKey];
            const resp = respKeys.map((k) => content[k]).slice(0, 1); // Only get the first card

            return resp.length ? resp : [];
        };

        // Filter content when content_source is "article"
        const articleContent = contentToRender
            .filter((c) => c.content_source === "Article")
            .map((c) => processArticleContent(c.contentKey)[0]); // Only the first card for articles

        return articleContent;
    }, [contentToRender, content]);
};
