import React, { useEffect, useState } from "react";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";

function SubMenuStyle2({ data }) {
    const { getLink } = useGetLink();

    const [mainArr, setMainArr] = useState([]);
    const [featuredItem, setFeaturedItem] = useState(null);

    useEffect(() => {
        if (data && data.contents) {
            let tempArr = [];
            for (let i = 0; i < data.contents.length; i++) {
                const item = data.contents[i];
                if (item.feature === 1) {
                    setFeaturedItem(item);
                } else {
                    tempArr.push(item);
                }
            }
            setMainArr(tempArr);
        }
    }, [data]);

    const renderFeaturedItem = () => {
        if (!featuredItem) {
            return null;
        }

        return (
            <div className="flex flex-col rounded-2xl md:rounded-none mx-6 md:mx-0 basis-[291px] px-8 py-8 bg-accent_color2 border-l border-accent_color3 border-solid flex-grow rtl-linkMain">
                <div className="text-primary_color uppercase font-medium mb-2 featured-title">
                    {featuredItem.heading}
                </div>
                {featuredItem.contents.map((name, ind) => (
                    <Link key={ind} to={getLink(name.content_reference)}>
                        <div className="text-accent_color1 text-sm py-2">{name.heading}</div>
                    </Link>
                ))}
            </div>
        );
    };

    const renderImageLink = (data, index) => {
        return (
            <Link to={getLink(data.content_reference)}>
                <div
                    key={index}
                    style={{ flexDirection: "column" }} // needed because Shorthand takes of flex-col / flex-row
                    className="flex space-x-4 items-center md:flex-col md:justify-center md:space-x-0"
                >
                    <img
                        className="w-52 object-cover rounded-xl mb-4"
                        src={data.content_image.src}
                    />
                    <div className="text-sm text-accent_color1 text-center">{data.heading}</div>
                </div>
            </Link>
        );
    };

    return (
        <>
            <div className="p-6 pt-10 lg:p-12 flex flex-col md:flex-row justify-between gap-x-10 gap-y-8 flex-grow-[3]">
                {mainArr.map((contentItem, index) => renderImageLink(contentItem, index))}
            </div>
            {renderFeaturedItem()}
        </>
    );
}

export default SubMenuStyle2;
