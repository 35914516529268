import { useSelector } from "react-redux";

export const TempComp = ({ type, title, content_reference: contentToRender }) => {
    const content = useSelector((state) => state.layout.content);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderStyle: "solid",
                borderColor: "grey",
                borderWidth: 5,
                width: "100%",
            }}
        >
            <p>{type}</p>
            <p>{title}</p>
            {contentToRender.map((c) => (
                <p key={`id-${c}`}>{content?.[c]?.title}</p>
            ))}
        </div>
    );
};
