import React, { useContext, useEffect, useState } from "react";
import "./Directory.css";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchDirectoryData,
    fetchDirectoryById,
    directoryDataUsingSearch,
} from "../../redux/directorySlice";
import { Paginator } from "primereact/paginator";
import DefaultUserSvg from "../../assets/DefaultUserSvg";
import parse from "html-react-parser";
import { WindowContext } from "../../components/WindowResize/resize";
import { Dropdown } from "primereact/dropdown";
import Loader from "../../components/Loader/loader";
import { defaultDirectoryId } from "../../utils/config";
import useCustomTranslation from "../../utils/useCustomTranslation";

function DirectoryPage({ colors: colorObj, contents: contentsArray }) {
    const dispatch = useDispatch();

    const { isMobile } = useContext(WindowContext);

    const { t } = useCustomTranslation();

    const [didDispatch, setDidDispatch] = useState(false);
    const isLoading = useSelector((state) => state.directory.isLoading);

    const [hasTabChanged, setHasTabChanged] = useState(false);

    const contents = useSelector((state) => state.directory.contents);
    const activeDirectory = useSelector((state) => state.directory.activeDirectory);
    const roleDropdownArr = useSelector((state) => state.directory.roleArr);
    const categorySideBarMenu = useSelector((state) => state.directory.categoryArr);
    const searchContentData = useSelector((state) => state.directory.searchContentData);
    const mainContents = useSelector((state) => state.directory.mainContents);

    const [actualMemberDetails, setActualmemberDetails] = useState([]);

    const [bindMemberDetails, setBindmemberDetails] = useState([]);

    const [peopleArr, setPeopleArr] = useState([]);

    const [totalPageCount, setTotalPageCount] = useState(0);

    const [category, setCategory] = useState("");
    const categoryDropdownArr = category ? contents[category] : [];

    const [filterArr, setFilterArr] = useState([]);

    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const [manualSelection, setManualSelection] = useState(false);

    const [selectedIdDetails, setSelectedIdDetails] = useState({
        email: "",
        name: "",
        description: "",
        id: "",
        address: "",
        prayerTimes: "",
    });

    const [filterReq, setFilterReq] = useState({
        pageSize: 5,
        pageNumber: 0,
        search: "",
    });

    const [filter, setFilter] = useState({
        search: "",
        role: "",
    });

    const [searchKeyword, setSearchKeyword] = useState("");

    const apiLink =
        contentsArray.length > 0 && contentsArray[0].contentKey
            ? contentsArray[0].contentKey
            : null;

    useEffect(() => {
        // Only set default details on initial load or when dependencies change
        if ((isInitialLoad || hasTabChanged) && !manualSelection) {
            if (category === t("constantString.institutions")) {
                if (defaultDirectoryId) {
                    const defaultItem = categoryDropdownArr.find(
                        (e) => e.id === defaultDirectoryId,
                    );

                    if (defaultItem) {
                        setSelectedIdDetails({
                            id: defaultItem.id,
                            name: defaultItem.name,
                            email: defaultItem.email,
                            address: defaultItem.address,
                            description: defaultItem.description,
                            prayerTimes: defaultItem.prayer_times,
                        });
                    } else {
                        setSelectedIdDetails({ id: "" });
                    }
                    setIsInitialLoad(false); // Set to false after initial load
                    setHasTabChanged(false);
                } else {
                    setSelectedIdDetails({ id: "" });
                }
            }
        }
    }, [
        categoryDropdownArr,
        category,
        defaultDirectoryId,
        isInitialLoad,
        hasTabChanged,
        manualSelection,
    ]);

    useEffect(() => {
        if (!didDispatch) {
            dispatch(fetchDirectoryData(apiLink));
            setDidDispatch(true);
        }
        if (contents[t("constantString.institutions")].length) {
            selectTab(t("constantString.institutions"));
            setCategory(t("constantString.institutions"));
        }
    }, [isLoading, contents, didDispatch]);

    useEffect(() => {
        if (selectedIdDetails.id) {
            dispatch(fetchDirectoryById({ id: selectedIdDetails.id }));
        }
    }, [selectedIdDetails]);

    useEffect(() => {
        if (searchKeyword !== "" && searchKeyword.length > 2) {
            dispatch(directoryDataUsingSearch(searchKeyword));
        }
    }, [searchKeyword]);

    useEffect(() => {
        let peopleArr = [];
        if (searchContentData != []) {
            for (const iterator of searchContentData) {
                peopleArr = peopleArr.concat(iterator.people);
            }
        }
        setPeopleArr(peopleArr);
    }, [searchContentData]);

    useEffect(() => {
        setActualmemberDetails([]);
        setTotalPageCount(0);

        if (peopleArr) {
            let count = 0;
            let members = [];
            for (const iterator of peopleArr) {
                const role = iterator.role;
                for (const j of iterator.members) {
                    members.push({
                        ID: j.ID,
                        Surname: j.Surname,
                        Role: role,
                        forename: j.forename,
                        home_number: j.home_number,
                        mobile: j.mobile,
                        photo: j.photo,
                        title: j.title,
                        committees: j.committees,
                        isShow: true,
                    });
                }
                count += iterator.members.length;
            }
            setActualmemberDetails(members);
            setFilterArr(members);
            setTotalPageCount(count);
        } else {
            setPeopleArr([]);
        }
    }, [peopleArr]);

    useEffect(() => {
        pagination();
    }, [filterReq]);

    useEffect(() => {
        let members = filterArr;
        if (filter.search !== "") {
            const searchQuery = filter.search.toLowerCase();
            members = members.filter((o) => {
                const fullName = `${o.forename} ${o.Surname}`.toLowerCase();
                return (
                    o.Role.toLowerCase().includes(searchQuery) ||
                    o.forename.toLowerCase().includes(searchQuery) ||
                    o.Surname.toLowerCase().includes(searchQuery) ||
                    fullName.includes(searchQuery)
                );
            });
        }

        if (filter.role !== "" && filter.role !== "All") {
            members = members.filter((e) => e.Role === filter.role);
        }

        setTotalPageCount(members.length);
        setActualmemberDetails(members);
        setFilterReq({ ...filterReq, pageNumber: 0 });
    }, [filter]);

    useEffect(() => {
        if (actualMemberDetails.length > 0) {
            pagination();
        }
    }, [actualMemberDetails]);

    const onChangeCategoryDropdown = async (e) => {
        const directoryContentsData = contents[category];
        const index = directoryContentsData.findIndex((v) => v.id === e);

        if (index < 0) setSelectedIdDetails({ id: "" });
        else
            setSelectedIdDetails({
                id: directoryContentsData[index].id,
                name: directoryContentsData[index].name,
                email: directoryContentsData[index].email,
                address: directoryContentsData[index].address,
                description: directoryContentsData[index].description,
                prayerTimes: directoryContentsData[index].prayer_times,
            });
        setFilterReq({ ...filterReq, pageNumber: 0 });
    };

    const selectTab = (category) => {
        setSelectedIdDetails({
            id: "",
        });
        setCategory(category);
    };

    useEffect(() => {
        setActualmemberDetails([]);
        setTotalPageCount(0);

        if (activeDirectory.content) {
            let count = 0;
            let members = [];
            for (const iterator of activeDirectory.content) {
                const role = iterator.role;
                for (const j of iterator.members) {
                    members.push({
                        ID: j.ID,
                        Surname: j.Surname,
                        Role: role,
                        forename: j.forename,
                        home_number: j.home_number,
                        mobile: j.mobile,
                        photo: j.photo,
                        title: j.title,
                        committees: j.committees,
                        isShow: false,
                        email: j.email ? j.email : "",
                    });
                }
                count += iterator.members.length;
            }
            setActualmemberDetails(members);
            setFilterArr(members);
            setTotalPageCount(count);
        }
    }, [activeDirectory]);

    const pagination = () => {
        const pageSize = filterReq.pageSize;
        const page = filterReq.pageNumber + 1;
        const data = actualMemberDetails.slice(pageSize * (page - 1), pageSize * page);
        setBindmemberDetails(data);
    };

    const onPageChange = (event) => {
        setFilterReq({ ...filterReq, pageSize: event.rows, pageNumber: event.page });
    };

    const clearSearch = () => {
        setFilter({
            ...filter,
            search: "",
        });
    };

    const collapseDropdown = (i) => {
        const arr = [...bindMemberDetails];
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            element.isShow = index === i ? !element.isShow : false;
        }

        setBindmemberDetails(arr);
    };

    const collapseDropdownForSearchDirectory = (i) => {
        const arr = [...bindMemberDetails];
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            element.isShow = index === i ? !element.isShow : true;
        }

        setBindmemberDetails(arr);
    };

    const clickOnViewDetails = (value) => {
        setManualSelection(true);
        // Find the category based on the selected item's ID
        const itemData = mainContents.find((v) => v.id === value.id);
        if (!itemData) {
            return;
        }

        const category = itemData.category.toLowerCase();

        // Update the category and reset search keyword
        setCategory(category);
        selectTab(category);
        setSearchKeyword("");

        // Get directory contents data for the selected category
        const directoryContentsData = contents[category];

        if (!directoryContentsData) {
            setSelectedIdDetails({ id: "" });
            return;
        }

        // Find the selected item in the directory contents
        const selectedItem = directoryContentsData.find((v) => v.id === value.id);

        if (selectedItem) {
            // If item is found, update selected details
            setSelectedIdDetails({
                id: selectedItem.id,
                name: selectedItem.name,
                email: selectedItem.email,
                address: selectedItem.address,
                description: selectedItem.description,
                prayerTimes: selectedItem.prayer_times,
            });
        } else {
            // If item is not found, reset selected details
            setSelectedIdDetails({ id: "" });
        }

        // Reset filter request and scroll to top
        setFilterReq({ ...filterReq, pageNumber: 0 });
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const applyAciveClassForSideMenu = (moduleName) => {
        let activeClass = "";
        switch (moduleName) {
            case "Institutions":
                if (category === t("constantString.institutions")) {
                    activeClass = "active";
                } else {
                    activeClass = "";
                }
                break;

            case "Committees":
                if (category === t("constantString.committees")) {
                    activeClass = "active";
                } else {
                    activeClass = "";
                }
                break;

            case "Jamatkhana":
                if (category === t("constantString.jamatkhana")) {
                    activeClass = "active";
                } else {
                    activeClass = "";
                }
                break;

            default:
                break;
        }
        return activeClass;
    };

    const applyIconsForSideMenu = (moduleName) => {
        let iconName = "";
        switch (moduleName) {
            case "Institutions":
                iconName = "fa fa-institution";
                break;

            case "Committees":
                iconName = "fa fa-user-o";
                break;

            case "Jamatkhana":
                iconName = "fa fa-building-o";
                break;

            default:
                break;
        }
        return iconName;
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <section
                className="institutionsSecation padTop padBottom"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="boxWrapper marBottom"
                                style={{ background: colorObj?.accent_color2 }}
                            >
                                <div className="search-hldr">
                                    <i className="fa fa-search"></i>
                                    <input
                                        type="text"
                                        className="form-control ml-2"
                                        placeholder={t("placeHolder.searchDirectory")}
                                        value={searchKeyword}
                                        onChange={(e) => setSearchKeyword(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        {searchKeyword !== "" && searchKeyword.length > 2 && (
                            <div className="col-12">
                                <div
                                    className="boxWrapper tableMainWrapper"
                                    style={{ background: colorObj?.accent_color2 }}
                                >
                                    <div className="row">
                                        <div className="col-12 my-req-filters d-flex justify-content-end mb-3">
                                            <div className="align-items-center selectDropdown me-3">
                                                <Dropdown
                                                    onChange={(e) =>
                                                        setFilter({
                                                            ...filter,
                                                            role: e.target.value,
                                                        })
                                                    }
                                                    options={roleDropdownArr}
                                                    optionLabel=""
                                                    placeholder={t("placeHolder.role")}
                                                    value={filter.role}
                                                    className="customDropdown"
                                                />
                                            </div>
                                            <div className="align-items-center selectDropdown">
                                                {filter.search !== "" && (
                                                    <span
                                                        className="cursor-pointer"
                                                        onClick={clearSearch}
                                                    >
                                                        <i className="search fa fa-close"></i>
                                                    </span>
                                                )}
                                                <div className="search-hldr">
                                                    <i className="fa fa-search"></i>
                                                    <input
                                                        type="text"
                                                        className="form-control ml-2"
                                                        placeholder={t("placeHolder.search")}
                                                        onChange={(e) =>
                                                            setFilter({
                                                                ...filter,
                                                                search: e.target.value,
                                                            })
                                                        }
                                                        value={filter.search}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table-listing">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Phone</th>
                                                            <th>Email</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {searchContentData.length > 0 && (
                                                            <>
                                                                {bindMemberDetails.map((v, i) => (
                                                                    <>
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <div className="userImg">
                                                                                        {v.photo ? (
                                                                                            <img
                                                                                                src={
                                                                                                    v.photo
                                                                                                }
                                                                                            />
                                                                                        ) : (
                                                                                            <DefaultUserSvg />
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="userInfo">
                                                                                        <label
                                                                                            className="userName"
                                                                                            style={{
                                                                                                "--accent_color1":
                                                                                                    colorObj?.accent_color1,
                                                                                            }}
                                                                                        >
                                                                                            {v.forename +
                                                                                                " " +
                                                                                                v.Surname}{" "}
                                                                                        </label>
                                                                                        <span
                                                                                            className="text-muted"
                                                                                            style={{
                                                                                                "--accent_color3":
                                                                                                    colorObj?.accent_color3,
                                                                                            }}
                                                                                        >
                                                                                            {v.Role}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <span
                                                                                    className="downArrow action-icon expand-request-btn cursor-pointer"
                                                                                    onClick={() =>
                                                                                        collapseDropdownForSearchDirectory(
                                                                                            i,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <i
                                                                                        className={`${
                                                                                            !v.isShow
                                                                                                ? "fa fa-angle-down"
                                                                                                : "fa fa-angle-up"
                                                                                        }`}
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={
                                                                                            "#collapse" +
                                                                                            i
                                                                                        }
                                                                                        aria-expanded="false"
                                                                                        aria-controls={
                                                                                            "collapse" +
                                                                                            i
                                                                                        }
                                                                                    ></i>
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <a
                                                                                    href="#"
                                                                                    className="table-link"
                                                                                    style={{
                                                                                        "--accent_color3":
                                                                                            colorObj?.accent_color3,
                                                                                    }}
                                                                                >
                                                                                    {v.mobile ? (
                                                                                        <>
                                                                                            <i className="fa fa-phone me-2"></i>
                                                                                            <a
                                                                                                href={`tel:${v.mobile}`}
                                                                                            >
                                                                                                {
                                                                                                    v.mobile
                                                                                                }
                                                                                            </a>{" "}
                                                                                        </>
                                                                                    ) : !isMobile ? (
                                                                                        "-"
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </a>
                                                                            </td>
                                                                            <td>
                                                                                {!isMobile && (
                                                                                    <a
                                                                                        href="#"
                                                                                        className="table-link"
                                                                                        style={{
                                                                                            "--accent_color3":
                                                                                                colorObj?.accent_color3,
                                                                                        }}
                                                                                    >
                                                                                        -
                                                                                    </a>
                                                                                )}
                                                                            </td>
                                                                            {v.committees ? (
                                                                                <td className="arrowMain">
                                                                                    <span
                                                                                        className="action-icon expand-request-btn cursor-pointer"
                                                                                        onClick={() =>
                                                                                            collapseDropdownForSearchDirectory(
                                                                                                i,
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <i
                                                                                            className={`${
                                                                                                !v.isShow
                                                                                                    ? "fa fa-angle-down"
                                                                                                    : "fa fa-angle-up"
                                                                                            }`}
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target={
                                                                                                "#collapse" +
                                                                                                i
                                                                                            }
                                                                                            aria-expanded="false"
                                                                                            aria-controls={
                                                                                                "collapse" +
                                                                                                i
                                                                                            }
                                                                                        ></i>
                                                                                    </span>
                                                                                </td>
                                                                            ) : (
                                                                                <td></td>
                                                                            )}
                                                                        </tr>

                                                                        {v.isShow && (
                                                                            <tr
                                                                                id={"collapse" + i}
                                                                                className={`hide ${
                                                                                    v.isShow
                                                                                        ? "show"
                                                                                        : ""
                                                                                }`}
                                                                                aria-labelledby={
                                                                                    "collapse" + i
                                                                                }
                                                                                data-bs-parent={
                                                                                    "#collapse" + i
                                                                                }
                                                                            >
                                                                                <td
                                                                                    colSpan={8}
                                                                                    className="approval-actions"
                                                                                >
                                                                                    <div
                                                                                        className="userDataWrapper list-group list-group-horizontal overflow-auto custom-scrollbar"
                                                                                        style={{
                                                                                            "--accent_color1":
                                                                                                colorObj?.accent_color1,
                                                                                        }}
                                                                                    >
                                                                                        <ul>
                                                                                            {v.committees && (
                                                                                                <>
                                                                                                    {v.committees.map(
                                                                                                        (
                                                                                                            value,
                                                                                                        ) => (
                                                                                                            <li
                                                                                                                key={
                                                                                                                    value.name
                                                                                                                }
                                                                                                            >
                                                                                                                <h2>
                                                                                                                    {
                                                                                                                        value.name
                                                                                                                    }
                                                                                                                </h2>
                                                                                                                <p>
                                                                                                                    {
                                                                                                                        v.Role
                                                                                                                    }
                                                                                                                </p>
                                                                                                                <span
                                                                                                                    className="btn btn-link mt-3"
                                                                                                                    onClick={() =>
                                                                                                                        clickOnViewDetails(
                                                                                                                            value,
                                                                                                                        )
                                                                                                                    }
                                                                                                                    style={{
                                                                                                                        "--primary_color":
                                                                                                                            colorObj?.primary_color,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span>
                                                                                                                        View
                                                                                                                        Details
                                                                                                                    </span>{" "}
                                                                                                                    <i className="btn-icon bi bi-chevron-right"></i>
                                                                                                                </span>
                                                                                                            </li>
                                                                                                        ),
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </>
                                                        )}

                                                        {searchContentData.length === 0 && (
                                                            <tr className="text-center no-record-tr">
                                                                <td colSpan={4}>
                                                                    <strong>
                                                                        No members found.
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            {searchContentData.length > 0 && (
                                                <div
                                                    className="table-pagination"
                                                    style={{
                                                        "--primary_color": colorObj?.primary_color,
                                                        "--accent_color4": colorObj?.accent_color4,
                                                    }}
                                                >
                                                    <Paginator
                                                        first={
                                                            filterReq.pageNumber *
                                                            filterReq.pageSize
                                                        }
                                                        rows={filterReq.pageSize}
                                                        totalRecords={totalPageCount}
                                                        onPageChange={onPageChange}
                                                        pageLinkSize={isMobile ? 1 : 5}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {searchKeyword.length <= 2 && (
                            <>
                                <div className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12">
                                    <div
                                        className="boxWrapper h-100"
                                        style={{ background: colorObj?.accent_color2 }}
                                    >
                                        <ul
                                            className="leftLink"
                                            style={{
                                                "--primary_color": colorObj?.primary_color,
                                                "--accent_color4": colorObj?.accent_color4,
                                            }}
                                        >
                                            {categorySideBarMenu.map((value) => (
                                                <li key={value}>
                                                    <span
                                                        className={`cursor-pointer ${applyAciveClassForSideMenu(
                                                            value,
                                                        )}`}
                                                        onClick={() => {
                                                            selectTab(value.toLowerCase());
                                                            setHasTabChanged(true);
                                                            setManualSelection(false);
                                                        }}
                                                    >
                                                        <i
                                                            className={`${applyIconsForSideMenu(
                                                                value,
                                                            )}`}
                                                        ></i>
                                                        {value}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-12 col-xl-9 col-lg-8 col-md-8 col-sm-12">
                                    <div
                                        className="boxWrapper infoDropMain marBottom"
                                        style={{ background: colorObj?.accent_color2 }}
                                    >
                                        <div>
                                            <label
                                                className="form-label"
                                                style={{
                                                    color: colorObj?.primary_color,
                                                    "--accent_color3": colorObj?.accent_color3,
                                                }}
                                            >
                                                View details of any jamati Institution, such as what
                                                it done and who are it’s members, including contact
                                                information
                                            </label>
                                            <select
                                                className="floating-select"
                                                onChange={(e) => {
                                                    onChangeCategoryDropdown(e.target.value);
                                                }}
                                                value={selectedIdDetails.id}
                                                style={{
                                                    "--accent_color3": colorObj?.accent_color3,
                                                }}
                                            >
                                                <option value="">
                                                    {category === t("constantString.institutions")
                                                        ? "Select Institutions"
                                                        : category ===
                                                          t("constantString.committees")
                                                        ? "Select Committees"
                                                        : "Select Jamatkhana"}
                                                </option>
                                                {categoryDropdownArr && (
                                                    <>
                                                        {categoryDropdownArr.map((values) => {
                                                            return (
                                                                <option
                                                                    value={values.id}
                                                                    key={values.id}
                                                                >
                                                                    {values.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </select>
                                        </div>
                                    </div>

                                    {selectedIdDetails.id &&
                                        category !== t("constantString.jamatkhana") && (
                                            <>
                                                <div
                                                    className="boxWrapper marBottom"
                                                    style={{ background: colorObj?.accent_color2 }}
                                                >
                                                    <div className="Council-Addr-Hldr">
                                                        <div className="Council-address-main">
                                                            <div className="council">
                                                                <h2>{selectedIdDetails.name}</h2>
                                                                <div className="d-flex justify-content">
                                                                    <i className="fa fa-envelope-o mt-1 me-2"></i>
                                                                    <p>
                                                                        <a
                                                                            href={`mailto:${selectedIdDetails.email}`}
                                                                        >
                                                                            {
                                                                                selectedIdDetails.email
                                                                            }
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                                <div className="mt-2 d-none d-lg-block">
                                                                    <hr />
                                                                </div>
                                                                <p>
                                                                    {selectedIdDetails.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    {selectedIdDetails.id &&
                                        category === t("constantString.jamatkhana") && (
                                            <div
                                                className="boxWrapper marBottom"
                                                style={{ background: colorObj?.accent_color2 }}
                                            >
                                                <div className="addressMain">
                                                    <div className="textMain">
                                                        <h2
                                                            style={{
                                                                color: colorObj?.accent_color1,
                                                            }}
                                                        >
                                                            {selectedIdDetails.name}
                                                        </h2>
                                                        <p
                                                            style={{
                                                                color: colorObj?.accent_color3,
                                                            }}
                                                        >
                                                            {parse(selectedIdDetails.prayerTimes)}
                                                        </p>
                                                    </div>
                                                    <div className="textMain">
                                                        <p>
                                                            <b>Address:</b>
                                                        </p>
                                                        <p>{parse(selectedIdDetails.address)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    {selectedIdDetails.id && (
                                        <div
                                            className="boxWrapper tableMainWrapper"
                                            style={{ background: colorObj?.accent_color2 }}
                                        >
                                            <div className="row">
                                                <div className="col-md-6 TitleWrapper">
                                                    <h2>{selectedIdDetails.name}</h2>
                                                </div>
                                                <div className="col-md-6 my-req-filters d-flex justify-content-end mb-3">
                                                    <div className="align-items-center selectDropdown me-3 w-50">
                                                        <Dropdown
                                                            onChange={(e) =>
                                                                setFilter({
                                                                    ...filter,
                                                                    role: e.target.value,
                                                                })
                                                            }
                                                            options={roleDropdownArr}
                                                            optionLabel=""
                                                            placeholder={t("placeHolder.role")}
                                                            value={filter.role}
                                                            className="customDropdown"
                                                        />
                                                    </div>
                                                    <div className="align-items-center selectDropdown w-50">
                                                        {filter.search !== "" && (
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={clearSearch}
                                                            >
                                                                <i className="fa fa-close"></i>
                                                            </span>
                                                        )}
                                                        <div className="search-hldr1">
                                                            <i className="fa fa-search"></i>
                                                            <input
                                                                type="text"
                                                                className="form-control ml-2"
                                                                placeholder={t(
                                                                    "placeHolder.search",
                                                                )}
                                                                onChange={(e) =>
                                                                    setFilter({
                                                                        ...filter,
                                                                        search: e.target.value,
                                                                    })
                                                                }
                                                                value={filter.search}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                                <div className="col-12">
                                                    <div className="table-responsive">
                                                        <table
                                                            className="table-listing"
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                                "--accent_color4":
                                                                    colorObj?.accent_color4,
                                                                "--accent_color1":
                                                                    colorObj?.accent_color1,
                                                            }}
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Phone</th>
                                                                    <th>Email</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {bindMemberDetails && (
                                                                    <>
                                                                        {bindMemberDetails.map(
                                                                            (v, i) => (
                                                                                <>
                                                                                    <tr key={i}>
                                                                                        <td>
                                                                                            <div className="d-flex">
                                                                                                <div className="userImg">
                                                                                                    {v.photo ? (
                                                                                                        <img
                                                                                                            src={
                                                                                                                v.photo
                                                                                                            }
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <DefaultUserSvg />
                                                                                                    )}
                                                                                                </div>
                                                                                                <div className="userInfo">
                                                                                                    <label className="userName">
                                                                                                        {v.forename +
                                                                                                            " " +
                                                                                                            v.Surname}
                                                                                                    </label>
                                                                                                    <span className="text-muted">
                                                                                                        {
                                                                                                            v.Role
                                                                                                        }
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span
                                                                                                className="downArrow action-icon expand-request-btn cursor-pointer"
                                                                                                onClick={() =>
                                                                                                    collapseDropdown(
                                                                                                        i,
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i
                                                                                                    className={`${
                                                                                                        !v.isShow
                                                                                                            ? "fa fa-angle-down"
                                                                                                            : "fa fa-angle-up"
                                                                                                    }`}
                                                                                                    data-bs-toggle="collapse"
                                                                                                    data-bs-target={
                                                                                                        "#collapse" +
                                                                                                        i
                                                                                                    }
                                                                                                    aria-expanded="false"
                                                                                                    aria-controls={
                                                                                                        "collapse" +
                                                                                                        i
                                                                                                    }
                                                                                                ></i>
                                                                                            </span>
                                                                                        </td>
                                                                                        <td>
                                                                                            {v.mobile && (
                                                                                                <a
                                                                                                    href="#"
                                                                                                    className="table-link"
                                                                                                    style={{
                                                                                                        "--accent_color3":
                                                                                                            colorObj?.accent_color3,
                                                                                                    }}
                                                                                                >
                                                                                                    {v.mobile !==
                                                                                                    "" ? (
                                                                                                        <>
                                                                                                            <i className="fa fa-phone me-2"></i>
                                                                                                            <a
                                                                                                                href={`tel:${v.mobile}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    v.mobile
                                                                                                                }
                                                                                                            </a>{" "}
                                                                                                        </>
                                                                                                    ) : !isMobile ? (
                                                                                                        <i className="fa fa-phone me-2"></i>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                </a>
                                                                                            )}
                                                                                        </td>
                                                                                        <td>
                                                                                            {v.email !==
                                                                                                "" &&
                                                                                                !isMobile && (
                                                                                                    <a
                                                                                                        href="#"
                                                                                                        className="table-link"
                                                                                                        style={{
                                                                                                            "--accent_color3":
                                                                                                                colorObj?.accent_color3,
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fa fa-envelope-o me-2"></i>
                                                                                                        <a
                                                                                                            href={`mailto::${v.email}`}
                                                                                                        >
                                                                                                            {
                                                                                                                v.email
                                                                                                            }
                                                                                                        </a>{" "}
                                                                                                    </a>
                                                                                                )}
                                                                                        </td>
                                                                                        {v.committees ? (
                                                                                            <td className="arrowMain">
                                                                                                <span
                                                                                                    className="action-icon expand-request-btn cursor-pointer"
                                                                                                    onClick={() =>
                                                                                                        collapseDropdown(
                                                                                                            i,
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i
                                                                                                        className={`${
                                                                                                            !v.isShow
                                                                                                                ? "fa fa-angle-down"
                                                                                                                : "fa fa-angle-up"
                                                                                                        }`}
                                                                                                        data-bs-toggle="collapse"
                                                                                                        data-bs-target={
                                                                                                            "#collapse" +
                                                                                                            i
                                                                                                        }
                                                                                                        aria-expanded="false"
                                                                                                        aria-controls={
                                                                                                            "collapse" +
                                                                                                            i
                                                                                                        }
                                                                                                    ></i>
                                                                                                </span>
                                                                                            </td>
                                                                                        ) : (
                                                                                            <td></td>
                                                                                        )}
                                                                                    </tr>

                                                                                    {v.isShow && (
                                                                                        <tr
                                                                                            id={
                                                                                                "collapse" +
                                                                                                i
                                                                                            }
                                                                                            className={`hide ${
                                                                                                v.isShow
                                                                                                    ? "show"
                                                                                                    : ""
                                                                                            }`}
                                                                                            aria-labelledby={
                                                                                                "collapse" +
                                                                                                i
                                                                                            }
                                                                                            data-bs-parent={
                                                                                                "#collapse" +
                                                                                                i
                                                                                            }
                                                                                        >
                                                                                            <td
                                                                                                colSpan={
                                                                                                    8
                                                                                                }
                                                                                                className="approval-actions"
                                                                                            >
                                                                                                <div
                                                                                                    className="userDataWrapper list-group list-group-horizontal overflow-auto custom-scrollbar"
                                                                                                    style={{
                                                                                                        "--accent_color1":
                                                                                                            colorObj?.accent_color1,
                                                                                                    }}
                                                                                                >
                                                                                                    <ul>
                                                                                                        {v.committees && (
                                                                                                            <>
                                                                                                                {v.committees.map(
                                                                                                                    (
                                                                                                                        value,
                                                                                                                    ) => (
                                                                                                                        <li
                                                                                                                            key={
                                                                                                                                value.name
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <h2>
                                                                                                                                {
                                                                                                                                    value.name
                                                                                                                                }
                                                                                                                            </h2>
                                                                                                                            <p>
                                                                                                                                {
                                                                                                                                    v.Role
                                                                                                                                }
                                                                                                                            </p>
                                                                                                                            <span
                                                                                                                                className="btn btn-link mt-3"
                                                                                                                                onClick={() =>
                                                                                                                                    clickOnViewDetails(
                                                                                                                                        value,
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                style={{
                                                                                                                                    "--primary_color":
                                                                                                                                        colorObj?.primary_color,
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    View
                                                                                                                                    Details
                                                                                                                                </span>{" "}
                                                                                                                                <i className="btn-icon bi bi-chevron-right"></i>
                                                                                                                            </span>
                                                                                                                        </li>
                                                                                                                    ),
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </>
                                                                            ),
                                                                        )}
                                                                    </>
                                                                )}

                                                                {bindMemberDetails.length === 0 && (
                                                                    <tr className="text-center no-record-tr">
                                                                        <td colSpan={4}>
                                                                            <strong>
                                                                                No members found.
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {bindMemberDetails.length > 0 && (
                                                        <div
                                                            className="table-pagination"
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                                "--accent_color4":
                                                                    colorObj?.accent_color4,
                                                            }}
                                                        >
                                                            <Paginator
                                                                first={
                                                                    filterReq.pageNumber *
                                                                    filterReq.pageSize
                                                                }
                                                                rows={filterReq.pageSize}
                                                                totalRecords={totalPageCount}
                                                                onPageChange={onPageChange}
                                                                pageLinkSize={isMobile ? 1 : 5}
                                                                style={{
                                                                    "--accent_color":
                                                                        colorObj?.accent_color4,
                                                                    "--primary_color":
                                                                        colorObj?.primary_color,
                                                                    "--accent_color4":
                                                                        colorObj?.accent_color4,
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default DirectoryPage;
