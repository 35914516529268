import { shallowEqual, useSelector } from "react-redux";
import { selectCountriesLanguageArray, selectCountryLanguageCodes } from "../redux/authSlice";
import { useGetLink } from "./useOpenLink";

const GLOBAL = "global";

const useGetArticleMeta = () => {
    const countriesLanguagesArray = useSelector(
        selectCountriesLanguageArray,
        (prev, next) => prev.length === next.length,
    );
    const { countryCode: countryCodeFromState } = useSelector(
        selectCountryLanguageCodes,
        shallowEqual,
    );

    const { getLink } = useGetLink();

    const getLocation = (geographies) => {
        if (geographies?.toLowerCase().includes(GLOBAL)) return GLOBAL;
        return countriesLanguagesArray.find(
            (c) => c.country_code.toLowerCase() === countryCodeFromState.toLowerCase(),
        )?.country;
    };

    const getBreadcrumbArray = (breadcrumbObj) => {
        return [
            {
                label: breadcrumbObj?.level_1?.label,
                link: getLink(breadcrumbObj?.level_1?.link),
            },
            {
                label: breadcrumbObj?.level_2?.label,
                link: getLink(breadcrumbObj?.level_2?.link),
            },
        ].filter((a) => !!a.label);
    };

    const getSection = (breadcrumbObj) => {
        return breadcrumbObj?.display_section?.label;
    };

    const getTheme = (breadcrumbObj) => {
        return breadcrumbObj?.display_theme?.label;
    };

    return { getLocation, getBreadcrumbArray, getSection, getTheme };
};

export default useGetArticleMeta;
