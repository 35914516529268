import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import fonts from "./fonts";
import { shallowEqual, useSelector } from "react-redux";
import { selectCountryLanguageCodes } from "../redux/authSlice";

function MetaTags() {
    const metaTags = useSelector((state) => state.layout.metaTags);

    const { languageCode } = useSelector(selectCountryLanguageCodes, shallowEqual);
    useEffect(() => {
        document.body.style.fontFamily = fonts[languageCode] || fonts.en;
    }, [languageCode]);

    return (
        <div>
            <Helmet
                htmlAttributes={{
                    lang: languageCode,
                }}
            >
                {(metaTags || []).map((v, i) => (
                    <meta name={v.meta_tag?.toLowerCase()} content={v.meta_content} key={i} />
                ))}
            </Helmet>
        </div>
    );
}

export default MetaTags;
