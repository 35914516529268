import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../utils/networkRequests";

const SLICE_NAME = "mailing";

export const fetchMailiListData = createAsyncThunk(
    `${SLICE_NAME}/fetchMailiListData`,
    async (apiLink, { getState }) => {
        try {
            const state = getState();

            const response = await axiosInstance.get(
                `${apiLink || state.auth?.configs?.MESSAGES_API_LINK}?type=get_mail_list`,
            );
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const updateConsentData = createAsyncThunk(
    `${SLICE_NAME}/updateConsentData`,
    async ({ apiLink, reqObj }) => {
        let query = "";

        for (const [key, value] of Object.entries(reqObj)) {
            query = query.concat(`&${key}=${value}`);
        }

        try {
            const response = await axiosInstance.get(
                `${apiLink}?type=update_consent_data&${query}`,
            );
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const updateMailStatus = createAsyncThunk(
    `${SLICE_NAME}/updateMailStatus`,
    async ({ apiLink, reqObj }) => {
        let query = "";

        for (const [key, value] of Object.entries(reqObj)) {
            query = query.concat(`&${key}=${value}`);
        }

        try {
            const response = await axiosInstance.get(`${apiLink}?type=update_mail_status&${query}`);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const updateSubscriptionsData = createAsyncThunk(
    `${SLICE_NAME}/updateSubscriptionsData`,
    async ({ apiLink, reqObj }) => {
        let query = "";

        for (const [key, value] of Object.entries(reqObj)) {
            query = query.concat(`&${key}=${value}`);
        }

        try {
            const response = await axiosInstance.get(
                `${apiLink}?type=update_mail_subscription&${query}`,
            );
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

const initialState = {
    userData: {},
    consentData: {},
    mailiData: [],
    isLoading: true,
    description: "",
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const mailingSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        // Example reducer:
        // fetchContentAndLayout: (state) => {
        //   const contentKeysMappedToLowercase = {}
        //   Object.keys(contentFromII.content).forEach(
        //     (k) => (contentKeysMappedToLowercase[k.toLowerCase()] = contentFromII.content[k]),
        //   )
        //   state.content = contentKeysMappedToLowercase
        //   state.layout = layoutFromII.layout
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMailiListData.fulfilled, (state, action) => {
                const data = action.payload;
                state.userData = data.userData;
                state.consentData = data.consentData;
                state.mailiData = data.mailData;
                state.description = data.description;
            })
            .addMatcher(
                (action) =>
                    action.type.startsWith(`${SLICE_NAME}/fetchMailiListData`) &&
                    action.type.endsWith("pending"),
                (state) => {
                    state.isLoading = true;
                },
            )
            .addMatcher(
                (action) =>
                    action.type.startsWith(`${SLICE_NAME}/fetchMailiListData`) &&
                    action.type.endsWith("fulfilled"),
                (state) => {
                    state.isLoading = false;
                },
            )
            .addMatcher(
                (action) =>
                    action.type.startsWith(`${SLICE_NAME}/fetchMailiListData`) &&
                    action.type.endsWith("rejected"),
                (state) => {
                    state.isLoading = false;
                },
            );
    },
});

// Action creators are generated for each case reducer function
// export const { fetchContentAndLayout } = layoutSlice.actions

export default mailingSlice.reducer;
