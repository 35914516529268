import React, { useCallback, useEffect, useState } from "react";
import "./ScrollingArticle.css";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { ARTICLE_DETAILS, BLOCK_DETAILS } from "../../constants/strings";

function ScrollingArticle({
    contents: contentToRender,
    block_source,
    heading,
    body,
    section_image,
    colors: colorObj,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    const [scrollingArticleDetails, setScrollingArticleDetails] = useState({
        heading: "",
        body: "",
        image: "",
    });

    useEffect(() => {
        if (block_source === BLOCK_DETAILS) {
            setScrollingArticleDetails({
                ...scrollingArticleDetails,
                heading: heading,
                body: body,
                image: section_image,
            });
        }

        if (block_source === ARTICLE_DETAILS) {
            setScrollingArticleDetails({
                ...scrollingArticleDetails,
                heading: contentReferenceData[0]?.title,
                body: contentReferenceData[0]?.summary,
                image: contentReferenceData[0]?.image,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                const resp = respKeys.map((k) => contentFromStore[k]);

                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    return (
        <>
            <section
                className="SecondBlockWrapper my-30"
                style={{ backgroundImage: `url(${scrollingArticleDetails.image?.src})` }}
            >
                <div className="container position-relative z-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <h2 style={{ "--accent_color4": colorObj?.accent_color4 }}>
                                {scrollingArticleDetails?.heading}
                            </h2>
                            <p style={{ "--accent_color4": colorObj?.accent_color4 }}>
                                {scrollingArticleDetails?.body &&
                                    parse(scrollingArticleDetails?.body)}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ScrollingArticle;
