import { useState, useEffect } from "react";

export default () => {
    const [data, setData] = useState({
        x: 0,
        y: 0,
        lastX: 0,
        lastY: 0,
    });

    const handleScroll = () => {
        setData((last) => {
            return {
                x: window.scrollX,
                y: window.scrollY,
                lastX: last.x,
                lastY: last.y,
            };
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return data;
};
