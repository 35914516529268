/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./ProgrammeStdMenu.css";
import { useGetLink } from "../../utils/useOpenLink";
import { enableDragScroll } from "../../utils/scrollLinks";
import CustomLink from "../../utils/customLink";
import clsx from "clsx";
import { useClickOutside } from "../../utils/useClickOutside";
import { useOpenMenu } from "../../utils/useOpenMenu";

function ProgrammeStdMenu({ contents: contentsArray = [], colors: colorObj }) {
    const { getLink } = useGetLink();

    const [collapsedIndexes, setCollapsedIndexes] = useState([]);

    const { menuRef, handleClick, handleMouseEnter, handleMouseLeave, activeIndex } = useOpenMenu();

    const handleToggleCollapse = (index) => {
        setCollapsedIndexes((prevState) =>
            prevState.includes(index)
                ? prevState.filter((i) => i !== index)
                : [...prevState, index],
        );
    };

    const MenuWrapper = ({ link, children }) => {
        return link ? (
            <CustomLink to={getLink(link)}>{children}</CustomLink>
        ) : (
            <div>{children}</div>
        );
    };

    return (
        <section
            className="prog-link-wrapper"
            style={{
                borderTop: `1px solid ${colorObj?.accent_color2}`,
                borderBottom: `1px solid ${colorObj?.accent_color2}`,
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 p-0">
                        <div ref={menuRef} className="desktop-std-menu1 overflow-x-visible">
                            {contentsArray &&
                                contentsArray.map((value, index) => {
                                    return (
                                        <span
                                            key={value.heading}
                                            className="nav-item cursor-pointer"
                                            onClick={() => {
                                                if (value.contents?.length > 0) handleClick(index);
                                            }}
                                            onMouseEnter={() => {
                                                if (value.contents?.length > 0)
                                                    handleMouseEnter(index);
                                            }}
                                            onMouseLeave={() => {
                                                if (value.contents?.length > 0) handleMouseLeave();
                                            }}
                                        >
                                            <MenuWrapper
                                                link={
                                                    !value.contents?.length
                                                        ? value.content_reference
                                                        : null
                                                }
                                            >
                                                <span
                                                    className={
                                                        activeIndex === index ? "active" : ""
                                                    }
                                                    style={{
                                                        color: colorObj?.primary_color,
                                                        "--link-hover-color":
                                                            colorObj?.primary_color,
                                                        "--primary_color": colorObj?.primary_color,
                                                    }}
                                                >
                                                    {value.heading}
                                                    {value.contents?.length > 0 && (
                                                        <i
                                                            className={`${
                                                                !value.isActive
                                                                    ? "fa fa-angle-down ms-2"
                                                                    : "fa fa-angle-down up-arrow"
                                                            }`}
                                                            data-bs-toggle="dropdown"
                                                            id="MainNav1"
                                                            aria-expanded="false"
                                                            // onMouseEnter={() => {
                                                            //     setHoveredIndex(index);
                                                            // }}
                                                        ></i>
                                                    )}
                                                </span>
                                            </MenuWrapper>

                                            {value.contents?.length > 0 && (
                                                <ul
                                                    className={clsx(
                                                        "dropdown-menu",
                                                        activeIndex === index ? "block" : "hidden",
                                                    )}
                                                    aria-labelledby="MainNav1"
                                                    style={{
                                                        "--primary_color": colorObj?.primary_color,
                                                        "--accent_color2": colorObj?.accent_color2,
                                                    }}
                                                >
                                                    {value.contents.map((subValue, i) => (
                                                        <li key={i}>
                                                            <CustomLink
                                                                to={getLink(
                                                                    subValue.content_reference,
                                                                )}
                                                                type={subValue.type}
                                                                key={i}
                                                            >
                                                                <div className="dropdown-item">
                                                                    {subValue.heading}
                                                                </div>
                                                            </CustomLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </span>
                                    );
                                })}
                        </div>

                        <div className="mobile-std-menu">
                            <div className="accordion navMainWrapper" id="navigationMain">
                                {contentsArray.map((value, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={`${index}`}>
                                            <MenuWrapper
                                                link={
                                                    !value.contents?.length
                                                        ? value.content_reference
                                                        : null
                                                }
                                            >
                                                <span
                                                    className="accordion-button collapsed"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${index}`}
                                                    aria-expanded={
                                                        collapsedIndexes.includes(index)
                                                            ? "true"
                                                            : "false"
                                                    }
                                                    aria-controls={`collapse${index}`}
                                                    onClick={() => {
                                                        handleToggleCollapse(index);
                                                    }}
                                                    style={{
                                                        color: colorObj?.primary_color,
                                                        "--primary_color": colorObj?.primary_color,
                                                        display:
                                                            value.contents?.length > 0
                                                                ? "flex"
                                                                : "block",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    {value.heading}
                                                    {value.contents?.length > 0 && (
                                                        <i
                                                            className={`fa ${
                                                                collapsedIndexes.includes(index)
                                                                    ? "fa-angle-up arrow-icon"
                                                                    : "fa-angle-down arrow-icon"
                                                            } ms-2`}
                                                            style={{
                                                                color: colorObj?.primary_color,
                                                            }}
                                                        ></i>
                                                    )}
                                                </span>
                                            </MenuWrapper>
                                        </h2>
                                        {value.contents?.length > 0 && (
                                            <div
                                                id={`collapse${index}`}
                                                className={`accordion-collapse hide ${
                                                    collapsedIndexes.includes(index) ? "show" : ""
                                                }`}
                                                aria-labelledby={`collapse${index}`}
                                                data-bs-parent="#navigationMain"
                                            >
                                                <div className="accordion-body">
                                                    <ul>
                                                        {value.contents &&
                                                            value.contents.map((v, i) => (
                                                                <li key={i}>
                                                                    <CustomLink
                                                                        to={getLink(
                                                                            v.content_reference,
                                                                        )}
                                                                        type={v.type}
                                                                    >
                                                                        <span
                                                                            className="cursor-pointer"
                                                                            style={{
                                                                                color: colorObj?.primary_color,
                                                                            }}
                                                                        >
                                                                            {v.heading}
                                                                        </span>
                                                                    </CustomLink>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProgrammeStdMenu;
