import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useSearchBox } from "react-instantsearch";
// import Loader from "../Loader/loader";

const CustomSearchBox = ({ initialQuery, setInputCBValue = () => null, color }) => {
    const { refine } = useSearchBox();

    const [inputValue, setInputStateValue] = useState(initialQuery);

    // const [loading, setLoading] = useState(false);

    useEffect(() => {
        refine(initialQuery);
    }, [initialQuery]);

    function setQuery(newQuery) {
        setInputStateValue(newQuery);
        setInputCBValue(newQuery);
        // setLoading(true);
        refine(newQuery);
        // setTimeout(() => setLoading(false), 500);
    }

    // useEffect(() => {
    //     setInputStateValue(initialQuery);
    //     refine(initialQuery);
    // }, [initialQuery, refine]);

    return (
        <>
            {/* <Loader isLoading={loading} /> */}
            <i
                className="bi bi-search search-icon"
                aria-hidden="true"
                style={{ "--accent_color3": color?.accent_color3 }}
            ></i>
            <InputText
                className="p-inputtext-lg"
                type="text"
                placeholder="Enter a search keyword"
                style={{
                    "--accent_color1": color?.accent_color1,
                    "--accent_color2": color?.accent_color2,
                }}
                value={inputValue}
                onChange={(event) => {
                    setQuery(event.currentTarget.value);
                }}
            />
            <i
                className="bi bi-x-lg close-icon cursor-pointer"
                style={{ color: color?.accent_color3 }}
                onClick={() => {
                    setQuery("");
                }}
            ></i>
        </>
    );
};

export default CustomSearchBox;
