import ImageWithObjectFit from "../ImageWrapperComponent";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import { calculateReadTime } from "../../utils/articleHelpers";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import CustomLink from "../../utils/customLink";

export const RelatedCard2 = ({ content, colorsObj }) => {
    const { image, title: Title, tags: Tags, path, microsite, posterImage, type } = content;

    const tags = Tags?.split(",")[0];

    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    return (
        <div className="newsCard card">
            <CustomLink to={getLink(path, type)} type={type}>
                <div className="cardImg">
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={image?.src || posterImage}
                    />
                </div>
            </CustomLink>
            <div className="card-body d-flex flex-column text-start">
                <div className="tag-heading mb-2" style={{ color: colorsObj.primary_color }}>
                    {microsite !== "" ? decodeHtmlEntities(microsite) : <>&nbsp;</>}
                </div>
                <CustomLink to={getLink(path, type)} type={type}>
                    <div
                        className="title hover:underline cursor-pointer"
                        style={{ color: colorsObj.accent_color1 }}
                    >
                        {Title}
                    </div>
                </CustomLink>
                <div className="d-flex mt-auto">
                    {tags && (
                        <div className="author me-1" style={{ color: colorsObj.accent_color1 }}>
                            {tags}
                        </div>
                    )}
                    <div className="date" style={{ color: colorsObj.accent_color3 }}>
                        {tags && calculateReadTime(content) ? " | " : ""}
                        {calculateReadTime(content)
                            ? `${calculateReadTime(content)} ${t("constantString.minRead")}`
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};
