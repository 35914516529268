import React, { useState } from "react";
import MegaMenu from "../MegaMenu/MegaMenu";
import { useDispatch } from "react-redux";
import { setIsVisibleMenu } from "../../redux/layoutSlice";

function MegaMenuList({ value, activeSubMenu, setActiveSubMenu }) {
    const dispatch = useDispatch();
    const [delayHandler, setDelayHandler] = useState(null);

    const onMouseLeave = () => {
        // setActiveSubMenu(null);
        // dispatch(setIsVisibleMenu(false));
        // clearTimeout(delayHandler);
    };
    return (
        <div
            onMouseLeave={onMouseLeave}
            onMouseEnter={() => {
                setDelayHandler(
                    setTimeout(() => {
                        setActiveSubMenu(value.content_reference);
                        dispatch(setIsVisibleMenu(true));
                    }, 2000),
                );
            }}
        >
            <div
                className="relative nav-link cursor-pointer text-accent_color4 p-4 text-base hover:underline hover:text-accent_color4 z-20"
                onClick={() => {
                    clearTimeout(delayHandler);
                    if (activeSubMenu !== value.content_reference) {
                        setActiveSubMenu(value.content_reference);
                        dispatch(setIsVisibleMenu(true));
                    } else {
                        setActiveSubMenu(null);
                        dispatch(setIsVisibleMenu(false));
                    }
                }}
            ></div>
            {activeSubMenu === value.content_reference && (
                <MegaMenu onMouseLeave={onMouseLeave} content_reference={value.content_reference} />
            )}
        </div>
    );
}

export default MegaMenuList;
