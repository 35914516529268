import React from "react";

const MoneySvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 10 9"
            fill="none"
        >
            <path
                d="M5 0.125C2.58398 0.125 0.625 2.08398 0.625 4.5C0.625 6.91602 2.58398 8.875 5 8.875C7.41602 8.875 9.375 6.91602 9.375 4.5C9.375 2.08398 7.41602 0.125 5 0.125ZM5 8.13281C2.99414 8.13281 1.36719 6.50586 1.36719 4.5C1.36719 2.49414 2.99414 0.867188 5 0.867188C7.00586 0.867188 8.63281 2.49414 8.63281 4.5C8.63281 6.50586 7.00586 8.13281 5 8.13281ZM6.34766 6.08398H4.58789V6.03809C4.85547 5.87012 5.0166 5.5459 5.0166 5.14844C5.0166 5.01074 4.99512 4.87598 4.96484 4.74805H5.92773C5.9707 4.74805 6.00586 4.71289 6.00586 4.66992V4.37695C6.00586 4.33398 5.9707 4.29883 5.92773 4.29883H4.83398C4.76367 4.07812 4.70312 3.85254 4.70312 3.61035C4.70312 3.18555 5.03516 2.9248 5.55566 2.9248C5.76562 2.9248 5.9707 2.96484 6.14551 3.02734C6.19629 3.0459 6.24902 3.00781 6.24902 2.95312V2.56738C6.24902 2.53516 6.22852 2.50586 6.19824 2.49414C6.01465 2.42383 5.77051 2.37012 5.51172 2.37012C4.60449 2.37012 4.03223 2.80469 4.03223 3.54492C4.03223 3.80176 4.09961 4.05273 4.1748 4.29785H3.65234C3.60938 4.29785 3.57422 4.33301 3.57422 4.37598V4.66895C3.57422 4.71191 3.60938 4.74707 3.65234 4.74707H4.30762C4.34082 4.89062 4.36523 5.03418 4.36523 5.17871C4.36523 5.62012 4.08398 5.99219 3.6543 6.09863C3.61914 6.10742 3.59473 6.13867 3.59473 6.1748V6.55078C3.59473 6.59375 3.62988 6.62891 3.67285 6.62891H6.34766C6.39062 6.62891 6.42578 6.59375 6.42578 6.55078V6.16211C6.42578 6.11914 6.39062 6.08398 6.34766 6.08398Z"
                fill="#292D32"
            />
        </svg>
    );
};

export default MoneySvg;
