import React, { useCallback, useEffect, useState } from "react";
import "./QuotePanel.css";
import { useSelector } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { ARTICLE_DETAILS } from "../../constants/strings";

function QuotePanel({
    contents: contentToRender,
    heading,
    colors: colorObj,
    block_source,
    section_image,
    body,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = content[contentKey];
            const resp = respKeys.map((k) => content[k]);
            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        setCurrentContent(contentToRender[0].contentKey);
    }, []);

    useEffect(() => {
        if (block_source === ARTICLE_DETAILS) {
            setCurrentContent(contentToRender[0].contentKey);
        } else {
            const arr = contentToRender.slice(0, 1).map((c) => {
                return {
                    image: section_image,
                    microsite: "",
                    title: body,
                    author: "",
                    date: c.heading,
                    summary: c.body,
                    link: c.contentKey,
                    nid: "",
                    type: c.type,
                };
            });
            setContentReferenceData(arr);
        }
    }, []);

    return (
        <>
            <div className="quote-panel my-30" style={{ background: colorObj?.background_color }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="quote-border-box"
                                style={{ "--accent_color3": colorObj?.accent_color3 }}
                            >
                                <div className="col-12">
                                    {contentReferenceData.map((v, i) => (
                                        <div className="row" key={i}>
                                            <div className="col-lg-9 col-md-9 col-sm-12 col-12 pe-lg-0 pe-md-0">
                                                <h3
                                                    className="card-heading"
                                                    style={{
                                                        "--primary_color": colorObj?.primary_color,
                                                    }}
                                                >
                                                    {heading}
                                                </h3>
                                                <h4
                                                    className="mt-5 mb-3 card-title underline"
                                                    style={{
                                                        "--accent_color1": colorObj?.accent_color1,
                                                    }}
                                                >
                                                    {parse(v.title)}
                                                </h4>
                                                <h5
                                                    className="date"
                                                    style={{
                                                        "--accent_color1": colorObj?.accent_color1,
                                                    }}
                                                >
                                                    {moment(v.date).format(`DD MMMM YYYY`)}
                                                </h5>

                                                <div
                                                    className="mt-3 mb-3 line"
                                                    style={{
                                                        "--accent_color2": colorObj?.accent_color2,
                                                    }}
                                                >
                                                    <hr />
                                                </div>

                                                <div
                                                    className="card-body"
                                                    style={{
                                                        "--accent_color1": colorObj?.accent_color1,
                                                    }}
                                                >
                                                    {v.summary && parse(v.summary)}
                                                </div>
                                            </div>

                                            <div className="col-lg-3 col-md-3 col-12 imgPanel cursor-pointer">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={v.image ? v.image.src : ""}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuotePanel;
