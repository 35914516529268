import React from "react";
import "./SimpleBanner2.css";
import parse from "html-react-parser";
import ImageWithObjectFit from "../ImageWrapperComponent";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { useGetLink } from "../../utils/useOpenLink";
import CustomLink from "../../utils/customLink";
import { RTL_LANGUAGE_DIRECTION } from "../../constants/strings";

function SimpleBanner2({ heading, body, section_image, colors: colorObj, block_buttons }) {
    const { getLink } = useGetLink();

    const { direction } = useCustomTranslation();

    return (
        <>
            <section
                className="conciliationArbritationSection my-30"
                style={{
                    background: colorObj?.background_color,
                    "--accent_color2": colorObj?.accent_color2,
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="HelpWrapper">
                                <div className="row">
                                    <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                                        <div className="TxtMain">
                                            <h3
                                                style={{
                                                    "--primary_color": colorObj?.primary_color,
                                                }}
                                            >
                                                {heading}
                                            </h3>
                                            <span
                                                className="helplineTxt"
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                }}
                                            >
                                                {parse(body)}
                                            </span>
                                            {block_buttons.map((v) => (
                                                <CustomLink to={getLink(v.action)} key={v.label}>
                                                    <div
                                                        className="btn btn-link"
                                                        key={v.label}
                                                        style={{
                                                            "--primary_color":
                                                                colorObj?.primary_color,
                                                        }}
                                                    >
                                                        {v.label}
                                                        <i
                                                            className={`${
                                                                direction === RTL_LANGUAGE_DIRECTION
                                                                    ? "fa fa-angle-left btn-icon"
                                                                    : "bi bi-arrow-right-short btn-icon"
                                                            }`}
                                                        ></i>
                                                    </div>
                                                </CustomLink>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-12 col-sm-12 col-12 mobileImg">
                                        <ImageWithObjectFit src={section_image?.src} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SimpleBanner2;
