import React from "react";
import "./TextLinks2.css";
import { useGetLink } from "../../utils/useOpenLink";
import CustomLink from "../../utils/customLink";

function TextLinks2({ heading, contents: contentsArray, colors: colorObj }) {
    const { getLink } = useGetLink();

    return (
        <>
            <div
                className="programmeServiceWrapper py-50"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="titleMain"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                <h3>{heading}</h3>
                            </div>
                        </div>

                        <div className="col-12">
                            <div
                                className="programmesTagWrapper"
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                    "--accent_color1": colorObj?.accent_color1,
                                }}
                            >
                                <ul className="overflow-x-auto scrollbarStyles hidescrollbar">
                                    {contentsArray.map((value) => (
                                        <CustomLink
                                            to={getLink(value.contentKey)}
                                            key={value.heading}
                                            type={value.type}
                                        >
                                            <li key={value.heading}>{value.heading}</li>
                                        </CustomLink>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextLinks2;
