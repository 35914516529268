import moment from "moment";
import React from "react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";

function TagCard({ content }) {
    const navigate = useNavigate();

    const { image, title, author, date, summary, nid, posterImage } = content;

    const handleClick = () => navigate(`/articles/${nid}`);

    return (
        <div className="newsCard card">
            <div className="cardImg">
                <ImageWithObjectFit
                    className="cursor-pointer"
                    src={image?.src || posterImage}
                    onClick={handleClick}
                />
            </div>
            <div className="card-body d-flex flex-column text-start">
                <h3 className="underline" onClick={handleClick}>
                    {title}
                </h3>
                <div className="date">
                    {author ? author : ""}
                    {author && date ? " | " : ""}
                    {date ? moment(date).format(`MMM DD${","} YYYY`) : ""}
                </div>
                <div className="textMain">{summary ? parse(summary) : ""}</div>
            </div>
        </div>
    );
}

export default TagCard;
