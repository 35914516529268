const TEST_CODE_VERIFIER =
    "Wr9iPhNo-CEDCx8lzryPLwc-CTlDR3GOkeobtADcFpX_Nq9IXq_vusjpFy1lkTIeK-4cfHd9YTg";
const TEST_CODE_CHALLENGE = "wYmlfOxGip_XGwIy_r6dqIgSuEjGUXDtBQkiR_0DYAk";

// GENERATING CODE VERIFIER
function dec2hex(dec) {
    return ("0" + dec.toString(16)).substr(-2);
}

const generateCodeVerifier = () => {
    const array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec2hex).join("");
};

function sha256(plain) {
    // returns promise ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
    var str = "";
    var bytes = new Uint8Array(a);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        str += String.fromCharCode(bytes[i]);
    }
    return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

const generateCodeChallengeFromVerifier = async (v) => {
    var hashed = await sha256(v);
    var base64encoded = base64urlencode(hashed);
    return base64encoded;
};

export const generatePkceCodePair = async () => {
    // this try/catch block is implemented becuase in some dev envs,
    // window.crypto.subtle.digest() doesn't work. If that function
    // throws an error, we will fall back to the test PKCE pair
    try {
        const codeVerifier = generateCodeVerifier();
        const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);

        window.sessionStorage.setItem("codeVerifier", codeVerifier);
        return codeChallenge;
    } catch {
        window.sessionStorage.setItem("codeVerifier", TEST_CODE_VERIFIER);
        return TEST_CODE_CHALLENGE;
    }
};

export const getCodeVerifier = () => {
    return window.sessionStorage.getItem("codeVerifier");
};

export const removeCodeVerifier = () => {
    window.sessionStorage.removeItem("codeVerifier");
};
