import React, { useEffect, useState } from "react";
import "./HeroFocus2.css";
import { Button } from "../Button";
import { useSelector } from "react-redux";
import moment from "moment";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";
import * as posterImage from "../../utils/usePosterImage";

function HeroFocus2({ contents: contentToRender = [], background }) {
    const navigate = useNavigate();

    const content = useSelector((state) => state.layout.content);

    const [heroFocusData, setheroFocusData] = useState([]);

    useEffect(() => {
        const fetchDefaultData = async () => {
            let heroFocusContent = content[contentToRender[0].contentKey]
                .map((k) => content[k])
                .slice(0, 1);
            heroFocusContent = JSON.parse(JSON.stringify(heroFocusContent));
            for await (const element of heroFocusContent) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (heroFocusContent.length) setheroFocusData(heroFocusContent);
        };
        fetchDefaultData();
    }, []);

    const handleClick = (id) => navigate(`/articles/${id}`);

    return (
        <>
            <div className="architectureSection py-50" style={{ background: background }}>
                <div className="container">
                    {heroFocusData.map((value) => (
                        <div className="row" key={value.title}>
                            <div className="width">
                                <div className="col-12 px-lg-2 px-md-2 px-0">
                                    <div className="bigNewsImg">
                                        <a href="#" className="backArrow">
                                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                        </a>
                                        <ImageWithObjectFit
                                            className="cursor-pointer"
                                            src={value?.image?.src || value?.posterImage}
                                            onClick={() => handleClick(value.nid)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <h4 className="date">
                                        {moment(value.date).format(`MMM DD${","} YYYY`)}
                                    </h4>
                                    <div className="line mb-3">
                                        <hr />
                                    </div>
                                    <div className="title">
                                        <h3
                                            className="underline"
                                            onClick={() => handleClick(value.nid)}
                                        >
                                            {value.title}
                                        </h3>
                                    </div>

                                    <div className="text">{parse(value.summary)}</div>

                                    <div className="mt-3">
                                        <Button className="button max-w-auto p-2 px-6">
                                            Read more
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default HeroFocus2;
