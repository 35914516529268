import { useEffect, useRef, useState } from "react";
import { useClickOutside } from "./useClickOutside";
import { useLocation } from "react-router-dom";

export const useOpenMenu = (timeoutDelay = 500) => {
    const { pathname } = useLocation();
    const [initialPathname, setInitialPathname] = useState(pathname);

    useEffect(() => {
        if (pathname !== initialPathname) {
            setActiveIndex(null);
            setInitialPathname(pathname);
        }
    }, [pathname]);

    const [activeIndex, setActiveIndex] = useState(null);
    const [didClick, setDidClick] = useState(false);

    const menuRef = useRef(null);
    const timeoutOnMouseEnter = useRef(null);

    useClickOutside(menuRef, () => {
        clearTimeout(timeoutOnMouseEnter.current);
        setActiveIndex(null);
        setDidClick(false);
    });

    const handleClick = (index) => {
        clearTimeout(timeoutOnMouseEnter.current);

        const shouldActivate =
            activeIndex === null || activeIndex !== index || (activeIndex === index && !didClick);

        setActiveIndex(shouldActivate ? index : null);
        setDidClick(shouldActivate);
    };

    const handleMouseEnter = (index) => {
        clearTimeout(timeoutOnMouseEnter.current);
        if (!didClick)
            timeoutOnMouseEnter.current = setTimeout(() => {
                setActiveIndex(index);
            }, timeoutDelay);
    };

    const handleMouseLeave = () => {
        if (!didClick)
            timeoutOnMouseEnter.current = setTimeout(() => {
                setActiveIndex(null);
                clearTimeout(timeoutOnMouseEnter.current);
            }, timeoutDelay);
    };

    return { menuRef, handleClick, handleMouseEnter, handleMouseLeave, activeIndex };
};
