import React, { useEffect, useState } from "react";
import "./FeaturedCallOut.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import ImageWithObjectFit from "../ImageWrapperComponent";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { RTL_LANGUAGE_DIRECTION } from "../../constants/strings";

function FeaturedCallOut({ contents: contentToRender = [], background, heading }) {
    const navigate = useNavigate();

    const content = useSelector((state) => state.layout.content);

    const [featuredCallbackData, setFeaturedCallbackData] = useState([]);

    useEffect(() => {
        const fetchDefaultData = () => {
            const featuredCallbackData = content[contentToRender[0].contentKey]
                .map((k) => content[k])
                .slice(0, 1);

            if (featuredCallbackData.length) setFeaturedCallbackData(featuredCallbackData);
        };
        fetchDefaultData();
    }, []);

    const handleClick = (id) => navigate(`/articles/${id}`);

    const { direction, t } = useCustomTranslation();

    return (
        <>
            <section className="FeaturedVideoSection" style={{ background: background }}>
                <div className="container">
                    {featuredCallbackData.map((value) => (
                        <div className="row" key={value.title}>
                            <div className="col-12 mb-3">
                                <div className="titleMain">
                                    <h3>{heading}</h3>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="videoImg">
                                    <ImageWithObjectFit
                                        className="cursor-pointer"
                                        src={value.image.src}
                                        onClick={() => handleClick(value.nid)}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="textMain arrow-left">
                                    {parse(value.summary)}
                                    <a className="btn btn-link" href="#">
                                        {t("btnText.WatchMore")}
                                        <i
                                            className={`${
                                                direction === RTL_LANGUAGE_DIRECTION
                                                    ? "fa fa-angle-left btn-icon"
                                                    : "fa fa-angle-right btn-icon"
                                            }`}
                                        ></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
}

export default FeaturedCallOut;
