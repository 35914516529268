import { useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../utils/useClickOutside";
import useCustomTranslation from "../../utils/useCustomTranslation";

export const DropDownItem = ({
    label,
    selectedValue,
    listItems,
    onItemSelected,
    withSearch,
    placeholder = "",
}) => {
    const dropdownRef = useRef(null);
    const [filteredItems, setFilteredItems] = useState(listItems);
    const [listVisible, setListVisible] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");

    const { t } = useCustomTranslation();

    useClickOutside(dropdownRef, () => {
        setListVisible(false);
    });

    useEffect(() => {
        if (!searchKeyword || searchKeyword.length === 0) {
            setFilteredItems(listItems);
            return;
        }
        if (listItems.length > 0) {
            const filteredList = listItems.filter((o) =>
                Object.keys(o).some(() =>
                    o.display.toLowerCase().includes(searchKeyword.toLowerCase()),
                ),
            );
            setFilteredItems(filteredList);
        }
    }, [searchKeyword, listItems, withSearch]);

    return (
        <div ref={dropdownRef}>
            <div className="text-accent_color3_default text-xs">{label}</div>
            <div
                className="flex flex-row border-solid border-b border-b-accent_color3_default py-2 mb-8 justify-between items-center"
                onClick={() => {
                    setListVisible((e) => !e);
                }}
            >
                <div className="text-accent_color1_default text-base">
                    {selectedValue || placeholder}
                </div>
                <i className="bi bi-chevron-down text-primary_color_default"></i>
            </div>

            <div className="relative z-50">
                {listVisible && (
                    <div className="absolute top-0 left-0 right-0 bg-white shadow-lg -mt-8 max-h-60 overflow-y-auto text-base">
                        {withSearch && (
                            <input
                                className="px-4 py-2 w-full"
                                type="text"
                                placeholder={t("placeHolder.search")}
                                title="Enter search keyword"
                                value={searchKeyword}
                                onChange={(e) => setSearchKeyword(e.target.value)}
                            />
                        )}
                        {filteredItems.map((item) => {
                            return (
                                <div
                                    key={item.display}
                                    onClick={() => {
                                        onItemSelected(item.value);
                                        setListVisible(false);
                                    }}
                                    className="text-base text-accent_color1_default px-4 py-2 flex flex-row items-start cursor-pointer hover:bg-primary_color_default hover:text-white"
                                >
                                    <div>{item.display}</div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
