import React, { useCallback, useEffect, useState } from "react";
import "./ProgrammeStdBody.css";
import { Button } from "../Button";
import { useGetLink } from "../../utils/useOpenLink";
import { useSelector } from "react-redux";
import useCustomTranslation from "../../utils/useCustomTranslation";
import CustomLink from "../../utils/customLink";
import { parseWithRemoveBrTagsFromBody } from "../../utils/articleHelpers";
import { ARTICLE_DETAILS, BLOCK_DETAILS } from "../../constants/strings";

function ProgrammeStdBody({
    contents: contentToRender = [],
    body,
    block_buttons,
    block_source,
    colors: colorObj,
}) {
    const [programmeStdBodyDetails, setProgrammeStdBodyDetails] = useState({
        body: "",
        summary: "",
        path: "",
        nid: "",
    });

    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    useEffect(() => {
        if (block_source === BLOCK_DETAILS) {
            setProgrammeStdBodyDetails({ ...programmeStdBodyDetails, body: body });
        }

        if (block_source === ARTICLE_DETAILS) {
            setProgrammeStdBodyDetails({
                ...programmeStdBodyDetails,
                body: contentReferenceData[0]?.body,
                path: contentReferenceData[0]?.path,
                nid: contentReferenceData[0]?.nid,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                const resp = respKeys.map((k) => contentFromStore[k]);

                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    return (
        <div className="programme-std-body-container py-50">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div
                            className="programme-std-body-content current"
                            style={{ "--primary_color": colorObj?.primary_color }}
                        >
                            <p style={{ color: colorObj?.accent_color1 }}>
                                {parseWithRemoveBrTagsFromBody(
                                    programmeStdBodyDetails.body || "",
                                    colorObj?.primary_color,
                                )}
                            </p>
                            <>
                                {block_source === BLOCK_DETAILS ? (
                                    block_buttons?.length > 0 && (
                                        <div className="btnHldr">
                                            {block_buttons.map((v) => (
                                                <CustomLink to={getLink(v.action)} key={v.label}>
                                                    <Button
                                                        style={{
                                                            border: `1px solid ${colorObj?.primary_color}`,
                                                            color: colorObj?.accent_color1,
                                                        }}
                                                        className="link"
                                                    >
                                                        {v.label}
                                                        <i
                                                            className="fa fa-angle-right ms-2"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </Button>
                                                </CustomLink>
                                            ))}
                                        </div>
                                    )
                                ) : (
                                    <CustomLink to={getLink(programmeStdBodyDetails?.path)}>
                                        <div className="btnHldr">
                                            <Button
                                                style={{
                                                    border: `1px solid ${colorObj?.primary_color}`,
                                                    color: colorObj?.accent_color1,
                                                }}
                                                className="link"
                                            >
                                                {t("btnText.ReadMore")}
                                                <i
                                                    className="fa fa-angle-right ms-2"
                                                    aria-hidden="true"
                                                ></i>
                                            </Button>
                                        </div>
                                    </CustomLink>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgrammeStdBody;
