import { useSelector } from "react-redux";

export const useGetServerConfig = () => {
    const configs = useSelector((state) => state.auth.configs);

    const host = configs?.SEARCH_API_LINK?.startsWith("https://")
        ? configs?.SEARCH_API_LINK.slice(8)
        : configs?.SEARCH_API_LINK;
    return {
        apiKey: configs?.SEARCH_API_KEY,
        nodes: [
            {
                host: host,
                port: "443",
                protocol: "https",
            },
        ],
    };
};

export const QUERY_PARAMS = ["title"];
export const FACET_PARAMS = [];
