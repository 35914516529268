import React from "react";

function DropDownMenuList({ value, openLink, colors }) {
    return (
        <li className="nav-item dropdown cursor-pointer">
            <span
                className="nav-link"
                onClick={() => openLink(value.content_reference)}
                style={{ color: colors?.accent_color4 }}
            >
                {value.heading}
                <i
                    className={`${
                        !value.isActive ? "fa fa-angle-down" : "fa fa-angle-down up-arrow"
                    }`}
                    data-bs-toggle="dropdown"
                    id="MainNav1"
                    aria-expanded="false"
                ></i>
            </span>
            <ul
                className="dropdown-menu"
                aria-labelledby="MainNav1"
                style={{ background: colors?.accent_color4 }}
            >
                {value.contents.map((subValue) => (
                    <li key={subValue.heading}>
                        <span
                            className="dropdown-item"
                            onClick={() => openLink(subValue.content_reference, subValue.type)}
                            style={{ color: colors?.accent_color1 }}
                        >
                            {subValue.heading}
                        </span>
                    </li>
                ))}
            </ul>
        </li>
    );
}

export default DropDownMenuList;
