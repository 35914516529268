import React, { useCallback, useEffect, useState } from "react";
import { useGetLink } from "../../utils/useOpenLink";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { APP_COLORS } from "../../constants/styles";
import Pill from "./Pill";
import CustomLink from "../../utils/customLink";
import { ARTICLE_DETAILS } from "../../constants/strings";

export const TextLinks = ({
    heading,
    body,
    contents: contentsArray,
    colors: colorObj,
    block_source,
}) => {
    const { getLink } = useGetLink();

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                let resp = respKeys.map((k) => contentFromStore[k]);
                resp = JSON.parse(JSON.stringify(resp));
                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentsArray.length > 0) {
            setCurrentContent(contentsArray[0].contentKey);
        }
    }, []);

    return (
        <div style={APP_COLORS(colorObj)} className="bg-background_color py-14 container">
            <div className="flex flex-col text-accent_color1 mb-3 items-center">
                <h1 className="text-2xl font-medium">{heading}</h1>
                <p className="mt-2">{parse(body)}</p>
            </div>

            <ul className="flex flex-row flex-wrap gap-x-6 gap-y-4 justify-center">
                {block_source !== ARTICLE_DETAILS
                    ? contentsArray.map((value) => (
                          <CustomLink to={getLink(value.contentKey)} key={value.title}>
                              <Pill key={value.heading} text={value.heading} />
                          </CustomLink>
                      ))
                    : contentReferenceData.map((value) => (
                          <CustomLink to={getLink(value.path)} key={value.title}>
                              <Pill text={value.title} />
                          </CustomLink>
                      ))}
            </ul>
        </div>
    );
};
