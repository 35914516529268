const COUNTRY_CODE_KEY = "country_code";
const LANGUAGE_CODE_KEY = "language_code";
const LANGUAGE_DIRECTION_KEY = "language_direction";
const REGION_ID_KEY = "region_id";

export const DEFAULT_COUNTRY_CODE = "global";
const DEFAULT_LANGUAGE_CODE = "en";
const DEFAULT_LANGUAGE_DIRECTION = "ltr";
const DEFAULT_REGION_ID = null;

export const setCountryInfoLocalStorage = ({ code }) => {
    localStorage.setItem(COUNTRY_CODE_KEY, code);
};

export const setLanguageInfoLocalStorage = ({ code, direction }) => {
    localStorage.setItem(LANGUAGE_CODE_KEY, code);
    localStorage.setItem(LANGUAGE_DIRECTION_KEY, direction);
};

export const setRegionInfoLocalStorage = ({ id }) => {
    if (id) localStorage.setItem(REGION_ID_KEY, id);
    else localStorage.removeItem(REGION_ID_KEY);
};

export const getCountryLanguageInfoLocalStorage = () => {
    const countryCode = localStorage.getItem(COUNTRY_CODE_KEY);
    const languageCode = localStorage.getItem(LANGUAGE_CODE_KEY);
    const languageDirection = localStorage.getItem(LANGUAGE_DIRECTION_KEY);
    const regionId = localStorage.getItem(REGION_ID_KEY) || null;

    return {
        countryCode,
        languageCode,
        languageDirection,
        regionId: regionId >= 0 ? +regionId : null,
    };
};

export const getValidCountryLanguageCode = (
    countryLanguageArray,
    countryCode,
    languageCode = null,
    regionId = null,
) => {
    if (!countryLanguageArray.length || !countryCode || countryCode.length !== 2) {
        return {
            countryCode: DEFAULT_COUNTRY_CODE,
            languageCode: DEFAULT_LANGUAGE_CODE,
            languageDirection: DEFAULT_LANGUAGE_DIRECTION,
        };
    }

    const countryIndex = countryLanguageArray.findIndex(
        (c) => c.country_code.toLowerCase() === countryCode.toLowerCase(),
    );
    const languageIndexFromProps =
        countryIndex >= 0 && languageCode
            ? countryLanguageArray[countryIndex].language.findIndex(
                  (l) => l.code.toLowerCase() === languageCode.toLowerCase(),
              )
            : -1;

    const defaultLanguageIndex =
        countryIndex >= 0
            ? countryLanguageArray[countryIndex].language.findIndex((l) => l.isDefault === "1")
            : 0;

    const languageIndex =
        languageIndexFromProps >= 0 ? languageIndexFromProps : defaultLanguageIndex;
    const regionIndex =
        countryIndex >= 0 && regionId >= 0
            ? countryLanguageArray[countryIndex].regions.findIndex((r) => r.id === regionId)
            : null;

    return {
        countryCode:
            countryIndex >= 0
                ? countryLanguageArray[countryIndex].country_code
                : DEFAULT_COUNTRY_CODE,
        languageCode:
            languageIndex >= 0 || defaultLanguageIndex >= 0
                ? countryLanguageArray[countryIndex]?.language?.[languageIndex]?.code
                : DEFAULT_LANGUAGE_CODE,
        languageDirection:
            languageIndex >= 0
                ? countryLanguageArray[countryIndex]?.language?.[languageIndex]?.direction
                : DEFAULT_LANGUAGE_DIRECTION,
        regionId:
            regionIndex >= 0
                ? countryLanguageArray[countryIndex]?.regions?.[regionIndex]?.id
                : DEFAULT_REGION_ID,
    };
};
