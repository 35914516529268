import React from "react";
import { useNavigate } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";

export const HeroCard = ({ content }) => {
    const navigate = useNavigate();

    const { image, title: Title, nid, posterImage } = content;

    const handleClick = () => navigate(`/articles/${nid}`);

    return (
        <div className="heroCardHldr">
            <ImageWithObjectFit
                className="cursor-pointer"
                src={image?.src || posterImage}
                onClick={handleClick}
            />
            <div className="centered">
                <h3 className="underline" onClick={handleClick}>
                    {Title}
                </h3>
            </div>
        </div>
    );
};
