import React from "react";
import ImageWithObjectFit from "../ImageWrapperComponent";
import moment from "moment";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import useCustomTranslation from "../../utils/useCustomTranslation";

function MediaCard({ content }) {
    const navigate = useNavigate();

    const { image, title, date, summary, nid, tags: Tags } = content;

    const handleClick = () => {
        navigate(`/articles`, { state: { nid: nid } });
    };

    const { t } = useCustomTranslation();

    const tags = Tags.split(",")[0];

    return (
        <div className="newsCard card">
            <div className="cardImg">
                <ImageWithObjectFit
                    className="cursor-pointer"
                    src={image?.src}
                    onClick={() => handleClick()}
                />
            </div>

            <div className="card-body d-flex flex-column text-start">
                <div className="date">
                    {moment(date).format(`MMM DD${","} YYYY`)}
                    {tags && date ? " | " : ""}
                    {tags}
                </div>
                <h3 className="underline" onClick={() => handleClick()}>
                    {title}
                </h3>
                <div className="textMain">{parse(summary)}</div>

                <div className="btn btn-link download-btn mt-3" onClick={() => handleClick()}>
                    {t("mediaArticleString.go_to_download")}
                    <i className="fa fa-angle-right btn-icon ms-2"></i>
                </div>
            </div>
        </div>
    );
}

export default MediaCard;
