import React from "react";
import "./TileLinks.css";
import parse from "html-react-parser";
import { useGetLink } from "../../utils/useOpenLink";
import CustomLink from "../../utils/customLink";

function TileLinks({ contents: contentsArray = [], colors: colorObj }) {
    const { getLink } = useGetLink();

    return (
        <div
            className="contactUsSection py-50 mb-15"
            style={{ background: colorObj?.background_color }}
        >
            <div className="container">
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {contentsArray.map((value) => (
                        <div className="col-12 mt-0" key={value.heading}>
                            <div
                                className="card"
                                style={{ "--accent_color2": colorObj?.accent_color2 }}
                            >
                                {value?.content_image?.src && (
                                    <img src={value?.content_image?.src} />
                                )}
                                <div className="card-body">
                                    <CustomLink to={getLink(value.contentKey)} type={value.type}>
                                        <h5
                                            className="card-title underline"
                                            style={{ "--accent_color1": colorObj?.accent_color1 }}
                                        >
                                            {value.heading}
                                        </h5>
                                    </CustomLink>
                                    <p
                                        className="card-text"
                                        style={{ "--accent_color1": colorObj?.accent_color1 }}
                                    >
                                        {parse(value.body)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TileLinks;
