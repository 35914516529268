import { Navigation, Pagination } from "swiper/modules";

export const swiperCarousel = {
    spaceBetween: 10,
    slidesPerView: 3,
    modules: [Pagination, Navigation],
    pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
    navigation: true,
    breakpoints: {
        345: {
            slidesPerView: 1.1,
            spaceBetween: 20,
        },
        576: {
            slidesPerView: 1.3,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
        },
        1024: {
            spaceBetween: 20,
            slidesPerView: 3,
        },
        1280: {
            spaceBetween: 20,
            slidesPerView: 3,
        },
    },
};
