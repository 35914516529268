import React, { useCallback, useEffect, useState } from "react";
import "./InfoPanel2.css";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { ARTICLE_DETAILS, BLOCK_DETAILS } from "../../constants/strings";

function InfoPanel2({ contents: contentToRender, heading, body, block_source, colors: colorObj }) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    const [infoPanel2Details, setInfoPanel2Details] = useState({
        heading: "",
        body: "",
    });

    useEffect(() => {
        if (block_source === BLOCK_DETAILS) {
            setInfoPanel2Details({ ...infoPanel2Details, heading: heading, body: body });
        }

        if (block_source === ARTICLE_DETAILS) {
            setInfoPanel2Details({
                ...infoPanel2Details,
                heading: contentReferenceData[0]?.title,
                body: contentReferenceData[0]?.summary,
                nid: contentReferenceData[0]?.nid,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                const resp = respKeys.map((k) => contentFromStore[k]);

                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, [contentToRender]);

    return (
        <>
            <div
                className="infoPanel2Wrapper my-30"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1
                                className="text-center mb-3"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                {infoPanel2Details.heading}
                            </h1>
                            <p style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                {parse(infoPanel2Details.body ? infoPanel2Details.body : "")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InfoPanel2;
