import React, { useEffect, useState } from "react";
import "../Carousel/CardCarousel.css";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function FeaturedCard({ contents: contentToRender = [] }) {
    const [featuredContentsRefData, setFeaturedContentsRefData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchDefaultData = () => {
            const featuredContent = content[contentToRender[0].contentKey]
                .map((k) => content[k])
                .slice(0, 1);
            if (featuredContent.length) setFeaturedContentsRefData(featuredContent);
        };
        fetchDefaultData();
    }, []);

    const handleClick = (nid) => {
        navigate(`/articles/${nid}`);
    };

    return (
        <div className="container">
            <div className="latestWrapper">
                <div className="row">
                    <div className="col-12 mb-2">
                        {featuredContentsRefData.map((value) => (
                            <div className="newsCardBig" key={value.title}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 pe-lg-0 pe-md-0">
                                        <div className="NewsImg">
                                            <ImageWithObjectFit
                                                className="cursor-pointer"
                                                src={value.image.src}
                                                onClick={() => handleClick(value.nid)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 ps-lg-0 ps-md-0">
                                        <div className="card-body">
                                            <h3
                                                className="underline"
                                                onClick={() => handleClick(value.nid)}
                                            >
                                                {value.title}
                                            </h3>
                                            <div className="date">
                                                {value.author}
                                                {value.author && value.date ? " | " : ""}
                                                {moment(value.date).format(`MMM DD${","} YYYY`)}
                                            </div>
                                            <div className="textMain">{parse(value.summary)}</div>
                                            <div className="d-flex gap-2 justify-center">
                                                <div className="hashtag">#{value.tags}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeaturedCard;
