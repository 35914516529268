import React from "react";
import { shareOnWhatsapp } from "../../utils/articleHelpers";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

function ShareOnSocialLinks() {
    return (
        <ul className="socialHldr">
            <li className="cursor-pointer">
                <i className="bi bi-whatsapp" onClick={() => shareOnWhatsapp()}></i>
            </li>
            <li>
                <TwitterShareButton url={window.location.href}>
                    <i className="bi bi-twitter-x"></i>
                </TwitterShareButton>
            </li>
            <li>
                <LinkedinShareButton url={window.location.href}>
                    <i className="bi bi-linkedin"></i>
                </LinkedinShareButton>
            </li>
            <li>
                <FacebookShareButton url={window.location.href}>
                    <i className="bi bi-facebook"></i>
                </FacebookShareButton>
            </li>
        </ul>
    );
}

export default ShareOnSocialLinks;
