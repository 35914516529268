import { useNavigate } from "react-router-dom";
import { getUrlParams, removeParamsFromUrl } from "./urlUtils";
import { useDispatch, useSelector } from "react-redux";
import { checkCode, clearRedirectLink, selectRedirectLink } from "../redux/authSlice";
import { useEffect, useState } from "react";

export const useCheckOauthCode = () => {
    const hashParams = getUrlParams();
    const { code, state } = hashParams;

    const redirectLinkFromOauth = useSelector(selectRedirectLink);
    const [codeCheckReady, setCodeCheckReady] = useState(!code);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (redirectLinkFromOauth) {
            navigate(redirectLinkFromOauth);
            clearRedirectLink();
        }
    }, [redirectLinkFromOauth]);

    useEffect(() => {
        const _checkCode = async () => {
            if (code && !codeCheckReady && !redirectLinkFromOauth) {
                const details = localStorage.getItem("oauthServersDetails");
                const oauthDetails = JSON.parse(details);
                await dispatch(checkCode({ code, state, oauthDetails }));
                setCodeCheckReady(true);
                removeParamsFromUrl();
            }
        };
        _checkCode();
    }, [code, codeCheckReady, redirectLinkFromOauth]);

    return { codeCheckReady };
};
