import React from "react";
import { useGetLink } from "../../utils/useOpenLink";
import ImageWithObjectFit from "../ImageWrapperComponent";
import CustomLink from "../../utils/customLink";

function ImageOnlyCard({ content }) {
    const { content_image, contentKey, type } = content;

    const { getLink } = useGetLink();

    return (
        <>
            <div className="card">
                <CustomLink to={getLink(contentKey, type)} type={type}>
                    <div className="cardImg cursor-pointer underline">
                        <ImageWithObjectFit className="cursor-pointer" src={content_image?.src} />
                    </div>
                </CustomLink>
            </div>
        </>
    );
}

export default ImageOnlyCard;
