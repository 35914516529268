import React, { useContext, useEffect, useState } from "react";
import "./FuneralsListing.css";
import { Button } from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchFuneralsSamarsData } from "../../redux/funeralsSamarsSlice";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import Loader from "../Loader/loader";
import parse from "html-react-parser";
import { Dialog } from "primereact/dialog";
import { WindowContext } from "../WindowResize/resize";
import useCustomTranslation from "../../utils/useCustomTranslation";

function Funeralslisting({ colors: colorObj, contents: contentsArray }) {
    const dispatch = useDispatch();

    const [didDispatch, setDidDispatch] = useState(false);

    const [openFuneralsModal, setOpenFuneralsModal] = useState(false);

    const [openSamarsModal, setOpenSamarsModal] = useState(false);

    const funeralsSamarsData = useSelector((state) => state.funeralsSamars.funeralsSamarsDetails);

    const isLoading = useSelector((state) => state.funeralsSamars.isLoading);

    const [totalPageCount, setTotalPageCount] = useState(0);

    const [funeralsSamarsModelDetails, setFuneralsSamarsModelDetails] = useState({});

    const [actualFuneralsSamarsDetails, setActualFuneralsSamarsDetails] = useState([]);

    const [bindFuneralsSamarsDetails, setBindFuneralsSamarsDetails] = useState([]);

    const { t } = useCustomTranslation();

    const { isMobile } = useContext(WindowContext);

    const apiLink =
        contentsArray.length > 0 && contentsArray[0].contentKey
            ? contentsArray[0].contentKey
            : null;

    const [typeArr, setTypeArr] = useState([
        {
            name: t("funeralsSamarsString.funerals"),
            isSelected: true,
        },
        {
            name: t("funeralsSamarsString.samars"),
            isSelected: false,
        },
    ]);

    useEffect(() => {
        if (isLoading && !didDispatch) {
            dispatch(fetchFuneralsSamarsData(apiLink));
            setDidDispatch(true);
        }
    }, [isLoading, didDispatch, funeralsSamarsData]);

    const [filterReq, setFilterReq] = useState({
        pageSize: 5,
        pageNumber: 0,
    });

    useEffect(() => {
        if (funeralsSamarsData && Object.keys(funeralsSamarsData).length > 0) {
            const filterType = typeArr.find((e) => e.isSelected);
            tabArrayList(filterType.name);
        }
    }, [typeArr, funeralsSamarsData]);

    useEffect(() => {
        if (actualFuneralsSamarsDetails.length > 0) {
            pagination();
        }
    }, [actualFuneralsSamarsDetails]);

    useEffect(() => {
        pagination();
    }, [filterReq]);

    const selectType = (i) => {
        const arr = [...typeArr];
        for (let index = 0; index < typeArr.length; index++) {
            const element = typeArr[index];
            element.isSelected = i === index;
        }
        setTypeArr(arr);
    };

    const tabArrayList = (typeName) => {
        if (funeralsSamarsData && Object.keys(funeralsSamarsData).length > 0) {
            let arr = [];
            let count = 0;

            for (const iterator of funeralsSamarsData[typeName]) {
                arr.push({
                    name: iterator.Name,
                    location: iterator.Location,
                    age: iterator.Age,
                    city: iterator.City,
                    jamat: iterator.Jamat,
                    eventDate: iterator.event_date,
                    eventTime: iterator.event_time || "",
                    image: iterator.Image || "",
                    type: typeName,
                    from_jamat: iterator.from_jamat,
                    condolence_Jamat: iterator.Condolence_Jamat,
                    condolence_Date: iterator.Condolence_Date,
                    samar_jamat: iterator.samar_jamat,
                    samar_time: iterator.samar_time,
                    burial_location: iterator.burial_location,
                    funeral_message: iterator.funeral_message,
                });
            }
            count += arr.length;
            setTotalPageCount(count);
            setActualFuneralsSamarsDetails(arr);
            setFilterReq({ ...filterReq, pageNumber: 0 });
        }
    };

    const pagination = () => {
        const pageSize = filterReq.pageSize;
        const page = filterReq.pageNumber + 1;
        const data = actualFuneralsSamarsDetails.slice(pageSize * (page - 1), pageSize * page);
        setBindFuneralsSamarsDetails(data);
    };

    const onPageChange = (event) => {
        setFilterReq({ ...filterReq, pageSize: event.rows, pageNumber: event.page });
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="funeralListingSection">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="heading">
                                            <h3 className="mb-2">
                                                {t("funeralsSamarsString.heading")}
                                            </h3>
                                        </div>
                                        <div className="sub-heading">
                                            <h4 className="mb-3">
                                                {t("funeralsSamarsString.subHeading")}
                                            </h4>
                                        </div>
                                        <div className="card-padding">
                                            <div
                                                className="nav nav-tabs"
                                                id="nav-tab"
                                                role="tablist"
                                            >
                                                <>
                                                    {typeArr.map((item, index) => (
                                                        <>
                                                            <button
                                                                className={`nav-link capitalize-first-letter ${
                                                                    item.isSelected ? "active" : ""
                                                                }`}
                                                                id="desktop-tab"
                                                                data-bs-toggle={"tab1"}
                                                                data-bs-target="#desktop"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="desktop"
                                                                style={{
                                                                    "--primary_color":
                                                                        colorObj?.primary_color,
                                                                    "--accent_color3":
                                                                        colorObj?.accent_color3,
                                                                }}
                                                                aria-selected={`${
                                                                    item.isSelected ? true : false
                                                                }`}
                                                                onClick={() =>
                                                                    selectType(index, item.name)
                                                                }
                                                            >
                                                                {item.name}
                                                            </button>
                                                        </>
                                                    ))}
                                                </>
                                            </div>

                                            <div className="mt-3" id={"tab1"}>
                                                {bindFuneralsSamarsDetails.map((v, index) => (
                                                    <div
                                                        className="commonTable packageTable"
                                                        key={index}
                                                    >
                                                        <div className="detailsHldr">
                                                            <div
                                                                className="name"
                                                                style={{
                                                                    color: colorObj?.accent_color1,
                                                                }}
                                                            >
                                                                {v.name}
                                                            </div>
                                                            <div
                                                                className="text-muted"
                                                                style={{
                                                                    width: "300px",
                                                                    color: colorObj?.accent_color1,
                                                                }}
                                                            >
                                                                <i className="fa-solid fa-location-dot"></i>
                                                                {v.location !== "" && (
                                                                    <i
                                                                        className="bi bi-geo-alt me-2"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                )}
                                                                {v.location}
                                                            </div>
                                                        </div>

                                                        <div className="cursor-pointer detailsBtn">
                                                            {v.type ===
                                                            t("funeralsSamarsString.funerals") ? (
                                                                <span
                                                                    className="btn-link1"
                                                                    style={{
                                                                        color: colorObj?.primary_color,
                                                                    }}
                                                                    onClick={() => {
                                                                        setFuneralsSamarsModelDetails(
                                                                            v,
                                                                        );
                                                                        setOpenFuneralsModal(true);
                                                                    }}
                                                                >
                                                                    {t(
                                                                        "funeralsSamarsString.details",
                                                                    )}
                                                                    <i className="bi bi-chevron-right ms-2 btn-icon"></i>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="btn-link1"
                                                                    style={{
                                                                        color: colorObj?.primary_color,
                                                                    }}
                                                                    onClick={() => {
                                                                        setFuneralsSamarsModelDetails(
                                                                            v,
                                                                        );
                                                                        setOpenSamarsModal(true);
                                                                    }}
                                                                >
                                                                    {t(
                                                                        "funeralsSamarsString.details",
                                                                    )}
                                                                    <i className="bi bi-chevron-right ms-2 btn-icon"></i>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}

                                                {bindFuneralsSamarsDetails.length > 0 && (
                                                    <div className="table-pagination">
                                                        <Paginator
                                                            first={
                                                                filterReq.pageNumber *
                                                                filterReq.pageSize
                                                            }
                                                            rows={filterReq.pageSize}
                                                            totalRecords={totalPageCount}
                                                            onPageChange={onPageChange}
                                                            pageLinkSize={isMobile ? 1 : 5}
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                                "--accent_color4":
                                                                    colorObj?.accent_color4,
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog
                    visible={openFuneralsModal}
                    onHide={() => {
                        if (!openFuneralsModal) return;
                        setOpenFuneralsModal(false);
                    }}
                    style={{ width: "30vw" }}
                    draggable={false}
                    breakpoints={{
                        "960px": "75vw",
                        "641px": "100vw",
                        "576px": "100vw",
                        "376px": "100vw",
                    }}
                    showHeader={false}
                >
                    <div
                        className="modal-header cursor-pointer mt-3"
                        onClick={() => setOpenFuneralsModal(false)}
                    >
                        <div className="closeIconMain ms-auto"></div>
                    </div>
                    <div className="funeralListingSection modal-content">
                        <div className="modal-header modal-header-border">
                            <div className="modal-icon">
                                <i
                                    className="fa fa-info-circle"
                                    style={{ "--accent_color3": colorObj?.accent_color3 }}
                                ></i>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="heading" style={{ color: colorObj?.accent_color1 }}>
                                <h3 className="mb-0">{t("funeralsSamarsString.modelHeading")}</h3>
                            </div>

                            <div className="userImg mt-3">
                                <img
                                    src={
                                        funeralsSamarsModelDetails?.image ||
                                        "/assets/images/funerals_default_image.svg"
                                    }
                                />
                            </div>

                            <div className="heading">
                                <h3
                                    className="mb-0 mt-3"
                                    style={{ color: colorObj?.accent_color1 }}
                                >
                                    {funeralsSamarsModelDetails?.name}
                                </h3>
                            </div>

                            <div className="mt-3">
                                {parse(
                                    funeralsSamarsModelDetails.funeral_message
                                        ? funeralsSamarsModelDetails.funeral_message
                                        : "",
                                )}
                            </div>
                            <div className="mt-3" onClick={() => setOpenFuneralsModal(false)}>
                                <Button
                                    className="button max-w-auto p-2 px-6"
                                    style={{
                                        color: colorObj?.accent_color4,
                                        background: colorObj?.primary_color,
                                    }}
                                >
                                    <i className="fa fa-times cross-icon" aria-hidden="true"></i>
                                    {t("funeralsSamarsString.btnText")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>

                <Dialog
                    visible={openSamarsModal}
                    onHide={() => {
                        if (!openSamarsModal) return;
                        setOpenSamarsModal(false);
                    }}
                    style={{ width: "30vw", top: 0 }}
                    breakpoints={{
                        "960px": "75vw",
                        "641px": "100vw",
                        "576px": "100vw",
                        "376px": "100vw",
                    }}
                    draggable={false}
                    showHeader={false}
                >
                    <div
                        className="modal-header cursor-pointer mt-3"
                        onClick={() => setOpenSamarsModal(false)}
                    >
                        <div className="closeIconMain ms-auto"></div>
                    </div>
                    <div className="funeralListingSection modal-content">
                        <div className="modal-body">
                            <div>
                                <p className="summary">
                                    {funeralsSamarsModelDetails?.name},
                                    {`${
                                        funeralsSamarsModelDetails?.age
                                            ? t("funeralsSamarsString.aged") +
                                              " " +
                                              funeralsSamarsModelDetails?.age +
                                              " " +
                                              t("funeralsSamarsString.years") +
                                              " "
                                            : null
                                    }`}
                                    {t("funeralsSamarsString.text6")}{" "}
                                    {funeralsSamarsModelDetails?.city}{" "}
                                    {t("funeralsSamarsString.text5")}{" "}
                                    {funeralsSamarsModelDetails?.jamat}.
                                    {t("funeralsSamarsString.text4")}{" "}
                                    {funeralsSamarsModelDetails?.jamat}{" "}
                                    {t("funeralsSamarsString.on")}
                                    {moment(funeralsSamarsModelDetails?.eventDate).format(
                                        `dddd`,
                                    )}{" "}
                                    {moment(funeralsSamarsModelDetails?.eventDate).format(
                                        `Do MMMM YYYY`,
                                    )}
                                    .
                                </p>
                            </div>

                            <div className="mt-3" onClick={() => setOpenSamarsModal(false)}>
                                <Button
                                    className="button max-w-auto p-2 px-6"
                                    style={{
                                        color: colorObj?.accent_color4,
                                        background: colorObj?.primary_color,
                                    }}
                                >
                                    <i className="fa fa-times cross-icon" aria-hidden="true"></i>
                                    {t("funeralsSamarsString.btnText")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default Funeralslisting;
