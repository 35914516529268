import React from "react";

function PaddingBlock({ number_of_rows, colors: colorObj }) {
    return (
        <div
            style={{ background: colorObj?.background_color, padding: `${20 * number_of_rows}px` }}
        ></div>
    );
}

export default PaddingBlock;
