import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ to, type, children, ...props }) => {
    if (!to) return <div>{children}</div>;

    return (
        <Link to={to} target={type === "link_new" ? "_blank" : "_self"} {...props}>
            {children}
        </Link>
    );
};

export default CustomLink;
