import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { Button } from "../Button";
import { useCallback, useEffect, useState } from "react";
import "./InfoPanel.css";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useGetLink } from "../../utils/useOpenLink";
import * as posterImage from "../../utils/usePosterImage";
import CustomLink from "../../utils/customLink";
import { ARTICLE_DETAILS, BLOCK_DETAILS } from "../../constants/strings";
import useCustomTranslation from "../../utils/useCustomTranslation";

export const InfoPanel = ({
    contents: contentToRender,
    block_source,
    heading,
    body,
    colors: colorObj,
    section_image,
    block_buttons,
}) => {
    const { t } = useCustomTranslation();

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    const [infoPanelDetails, setInfoPanelDetails] = useState({
        heading: "",
        body: "",
        nid: "",
        image: "",
    });

    useEffect(() => {
        if (block_source === BLOCK_DETAILS) {
            setInfoPanelDetails({
                ...infoPanelDetails,
                heading: heading,
                body: body,
                image: section_image?.src,
            });
        }

        if (block_source === ARTICLE_DETAILS) {
            setInfoPanelDetails({
                ...infoPanelDetails,
                heading: contentReferenceData[0]?.title,
                body: contentReferenceData[0]?.summary,
                path: contentReferenceData[0]?.path,
                image: contentReferenceData[0]?.image?.src || contentReferenceData[0]?.posterImage,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                let resp = respKeys.map((k) => contentFromStore[k]);
                resp = JSON.parse(JSON.stringify(resp));
                for await (const element of resp) {
                    if (element && element.link) {
                        element.posterImage = await posterImage.usePosterImage(
                            element?.link,
                            element?.image,
                        );
                    }
                }
                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    const { getLink } = useGetLink();

    return (
        <div className="info-panel py-50">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div
                            className="info-border-box mb-5"
                            style={{
                                "--accent_color2": colorObj?.accent_color2,
                                "--accent_color4": colorObj?.accent_color4,
                            }}
                        >
                            <div className="logo">
                                <ImageWithObjectFit src={infoPanelDetails?.image} />
                            </div>
                            <div className="w-100 d-block">
                                <h5
                                    className="header mb-3 mt-3"
                                    style={{ "--primary_color": colorObj?.primary_color }}
                                >
                                    {infoPanelDetails.heading}
                                </h5>
                                <p style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                    {parse(infoPanelDetails.body ? infoPanelDetails.body : "")}
                                </p>
                                {block_source === ARTICLE_DETAILS ? (
                                    <div className="mt-3 mb-3 text-center">
                                        <CustomLink to={getLink(infoPanelDetails?.path)}>
                                            <Button
                                                className="button max-w-auto p-2 px-6"
                                                style={{
                                                    "--primary_color": colorObj?.primary_color,
                                                    "--accent_color4": colorObj?.accent_color4,
                                                }}
                                            >
                                                {t("btnText.learnMore")}
                                            </Button>
                                        </CustomLink>
                                    </div>
                                ) : (
                                    block_buttons.map((v) => (
                                        <CustomLink to={getLink(v.action)} key={v.label}>
                                            <div className="mt-3 mb-3 text-center">
                                                <Button
                                                    className="button max-w-auto p-2 px-6"
                                                    style={{
                                                        "--primary_color": colorObj?.primary_color,
                                                        "--accent_color4": colorObj?.accent_color4,
                                                    }}
                                                >
                                                    {v.label}
                                                </Button>
                                            </div>
                                        </CustomLink>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
