import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { APP_COLORS } from "../../constants/styles";
import SubMenuStyle1 from "./SubMenuStyle1";
import SubMenuStyle2 from "./SubMenuStyle2";
import SubMenuStyle3 from "./SubMenuStyle3";
import SubMenuStyle4 from "./SubMenuStyle4";

function MegaMenuMobile({ subMenuHeader, style, setMobileMenuOpen, sections = [], logo }) {
    const { pathname } = useLocation();

    const [initialPathname] = useState(pathname);

    const [activeSubMenuId, setActiveSubMenuId] = useState(null);

    const [subMenuContent, setSubMenuContent] = useState(null);

    useEffect(() => {
        if (pathname !== initialPathname) setMobileMenuOpen(false);
    }, [pathname]);

    useEffect(() => {
        const contentIds = subMenuHeader.map((value) => parseInt(value.content_reference));
        const subMenuContentItems = new Map(
            sections.filter((item) => contentIds.includes(item.id)).map((item) => [item.id, item]),
        );
        setSubMenuContent(subMenuContentItems);
    }, [subMenuHeader]);

    const renderSubMenu = (data) => {
        if (!data) {
            return null;
        }

        let innerMenu;
        switch (data?.layout_type) {
            case "sub_menu_style1":
                innerMenu = <SubMenuStyle1 data={data} />;
                break;
            case "sub_menu_style2":
                innerMenu = <SubMenuStyle2 data={data} />;
                break;
            case "sub_menu_style3":
                innerMenu = <SubMenuStyle3 data={data} />;
                break;
            case "sub_menu_style4":
                innerMenu = <SubMenuStyle4 data={data} />;
                break;
            default:
                return null;
        }

        return (
            <div style={APP_COLORS(data.colors)}>
                <div className="relative bg-white rounded-2xl overflow-clip flex flex-col md:flex-row z-20">
                    {innerMenu}
                </div>
            </div>
        );
    };

    const renderHeader = ({ text, subMenuId }) => {
        const selected = activeSubMenuId == subMenuId;
        return (
            <div
                className={clsx(
                    "flex flex-row justify-between items-center cursor-pointer capitalize text-lg px-6 py-4",
                    selected && "bg-primary_color text-accent-4",
                    !selected && "bg-white text-accent-4",
                )}
                onClick={() => {
                    if (activeSubMenuId != subMenuId) {
                        setActiveSubMenuId(subMenuId);
                    } else {
                        setActiveSubMenuId(null);
                    }
                }}
            >
                <div>{text}</div>

                {selected ? (
                    <i className="bi bi-chevron-up"></i>
                ) : (
                    <i className="bi bi-chevron-down"></i>
                )}
            </div>
        );
    };

    return (
        <div
            style={{ ...style }}
            className={
                "block md:hidden fixed top-0 left-0 w-full h-screen bg-white overflow-auto z-200"
            }
        >
            <div className="flex flex-row justify-between p-6 items-center">
                <Link to={"/"}>
                    <img className="h-12" src={logo} />
                </Link>
                <i
                    className="bi bi-x cursor-pointer text-4xl -mr-2"
                    onClick={() => setMobileMenuOpen(false)}
                ></i>
            </div>
            <div
                className={clsx(
                    "flex flex-col justify-start transition-transform ease-linear duration-300 relative z-10 bg-white rtl-subMenu",
                )}
            >
                {subMenuHeader.map((value) => {
                    return (
                        <div className="relative" key={value.heading}>
                            {renderHeader({
                                text: value.heading,
                                subMenuId: parseInt(value.content_reference),
                            })}
                            {activeSubMenuId == parseInt(value.content_reference) &&
                                renderSubMenu(
                                    subMenuContent && subMenuContent.get(activeSubMenuId),
                                )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default MegaMenuMobile;
