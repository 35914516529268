import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setLanguageInfo } from "../../redux/authSlice";
import { WindowContext } from "../WindowResize/resize";
import { useClickOutside } from "../../utils/useClickOutside";

const LanguageSelector = ({ selectedCountry, countriesData, Isheader2 }) => {
    const dropdownRef = useRef(null);

    const [toggleSelectLanguage, setoggleSelectLanguage] = useState(false);

    const [availableLanguage, setAvailableLanguage] = useState([]);

    const [selectedLanguage, setSelectedLanguage] = useState("");

    const dispatch = useDispatch();

    const { isDesktop } = useContext(WindowContext);

    const languageNameFromLocalStorage = localStorage.getItem("selectedLanguage");

    useEffect(() => {
        const filter = countriesData.filter((e) => e.country === selectedCountry);
        if (filter.length > 0 && filter[0].language) {
            const selectedLang = filter[0].language.find(
                (item) => item.code === languageNameFromLocalStorage,
            );

            if (selectedLang && selectedLang.label !== selectedLanguage) {
                setSelectedLanguage(selectedLang.label);
            }
        }
    }, [countriesData, selectedCountry, languageNameFromLocalStorage, selectedLanguage]);

    useClickOutside(dropdownRef, () => setoggleSelectLanguage(false));

    const changeLanguage = (language, dir) => {
        setoggleSelectLanguage(false);
        dispatch(setLanguageInfo({ code: language, direction: dir }));
    };

    useEffect(() => {
        if (selectedCountry && countriesData.length > 0) {
            const filter = countriesData.filter((e) => e.country === selectedCountry);

            if (filter.length > 0 && filter[0].language) {
                const arr = filter[0].language.map((iterator) => ({
                    displayName: iterator.label,
                    dir: iterator.direction,
                    code: iterator.code,
                }));

                if (JSON.stringify(arr) !== JSON.stringify(availableLanguage)) {
                    setAvailableLanguage(arr);
                }
            } else {
                if (availableLanguage.length !== 0) {
                    setAvailableLanguage([]);
                }
            }
        }
    }, [selectedCountry, countriesData, availableLanguage, selectedLanguage, changeLanguage]);

    return (
        <>
            {Isheader2 && isDesktop && (
                <div
                    className={`ms-1 ${availableLanguage.length > 1 ? "cursor-pointer" : ""}`}
                    onClick={() =>
                        toggleSelectLanguage
                            ? setoggleSelectLanguage(false)
                            : setoggleSelectLanguage(true)
                    }
                >
                    {selectedLanguage}
                </div>
            )}
            {availableLanguage.length > 1 && (
                <>
                    {Isheader2 && !isDesktop && (
                        <img
                            src="/assets/images/translate.svg"
                            className="translate-icon cursor-pointer ms-3"
                            onClick={() => setoggleSelectLanguage(!toggleSelectLanguage)}
                            alt="Translate Icon"
                        />
                    )}
                    <span>
                        {!Isheader2 && (
                            <img
                                src="/assets/images/translate.svg"
                                className="translate-icon cursor-pointer ms-3"
                                onClick={() => setoggleSelectLanguage(!toggleSelectLanguage)}
                                alt="Translate Icon"
                            />
                        )}
                        {toggleSelectLanguage && (
                            <div className="searchMain" ref={dropdownRef}>
                                <div className="userMain">
                                    <div className="contPopup1 langPopup">
                                        <h3 className="heading mb-2">Select Language</h3>
                                        <div className="headingLine mb-2"></div>
                                        <div className="contMain1">
                                            <ul className="cursor-pointer">
                                                {availableLanguage.map((item, index) => (
                                                    <li key={index}>
                                                        <div
                                                            onClick={() => {
                                                                changeLanguage(item.code, item.dir);
                                                            }}
                                                        >
                                                            <label className="form-check-label country">
                                                                {item.displayName}
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </span>
                </>
            )}
        </>
    );
};

export default LanguageSelector;
