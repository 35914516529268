import React, { useEffect, useState } from "react";
import "./MediaFeaturedCardCarousel.css";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperCarousel } from "../../utils/carouselFunction";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import MediaFeaturedCard from "../Card/MediaFeaturedCard";
import { useNavigate } from "react-router";
import useCustomTranslation from "../../utils/useCustomTranslation";

function MediaFeaturedCardCarousel({ contents: contentToRender = [], heading }) {
    const content = useSelector((state) => state.layout.content);

    const navigate = useNavigate();

    const { t } = useCustomTranslation();

    const [mediaFeaturedContentsRefData, setMediaFeaturedContentsRefData] = useState([]);

    const [mediaCardCarouselContentsRefData, setMediaCardCarouselContentsRefData] = useState([]);

    useEffect(() => {
        const fetchDefaultData = () => {
            const mediaFeaturedContent = content[contentToRender[0].contentKey]
                .map((k) => content[k])
                .slice(0, 1);
            const mediaCardContent = content[contentToRender[1].contentKey].map((k) => content[k]);

            if (mediaFeaturedContent.length) setMediaFeaturedContentsRefData(mediaFeaturedContent);

            if (mediaCardContent.length) setMediaCardCarouselContentsRefData(mediaCardContent);
        };
        fetchDefaultData();
    }, []);

    const { dir } = useCustomTranslation();

    const handleClick = (nid) => {
        navigate(`/articles`, { state: { nid: nid } });
    };

    return (
        <div className="container">
            <div className="mediaFeaturedCardWrapper">
                <div className="row">
                    <div className="col-12">
                        {mediaFeaturedContentsRefData.map((v) => (
                            <div className="newsCardBig" key={v.title}>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 pe-lg-0 pe-md-0 card-order d-flex align-items-center">
                                        <div className="card-body">
                                            <div className="heading">{heading}</div>

                                            <div className="textMain">{v.title}</div>

                                            <div className="summaryText">{parse(v.summary)}</div>

                                            <div className="mt-auto">
                                                <div
                                                    className="btn btn-link button"
                                                    onClick={() => handleClick(v.nid)}
                                                >
                                                    {t("btnText.ReadMore")}
                                                    <i className="fa fa-angle-right btn-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-5 col-sm-12 col-12 pe-lg-0 pe-md-0">
                                        <div className="NewsImg">
                                            <ImageWithObjectFit
                                                className="cursor-pointer"
                                                src={v.image?.src}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="col-12 featuredCard">
                        <div className="main-heading">{t("mediaArticleString.latest")}</div>
                        <Swiper dir={dir} key={dir} {...swiperCarousel} spaceBetween={20}>
                            {mediaCardCarouselContentsRefData.map((card) => (
                                <SwiperSlide key={card.title}>
                                    <MediaFeaturedCard content={card} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MediaFeaturedCardCarousel;
