import React, { useEffect, useState } from "react";
import "./SpeechDetails.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticle } from "../../redux/layoutSlice";
import moment from "moment";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";
import BreadCrumbs from "../BreadCrumbs";
import useGetArticleMeta from "../../utils/useGetArticleMeta";

function SpeechDetails({ contents, colors: colorObj }) {
    const [didDispatch, setDidDispatch] = useState(false);

    const { state } = useLocation();

    const id = contents?.[0]?.id || state?.id;

    const dispatch = useDispatch();

    const content = useSelector((state) => state.layout.content);

    useEffect(() => {
        if (!didDispatch) {
            dispatch(fetchArticle({ id }));
            setDidDispatch(true);
        }
    }, [didDispatch, content]);

    const {
        title,
        date,
        body,
        detailImage,
        speech_location,
        related_links,
        speech_speaker,
        breadcrumb,
    } = content[id] || {};

    const { getBreadcrumbArray } = useGetArticleMeta();
    const breadcrumbArray = getBreadcrumbArray(breadcrumb);

    return (
        <>
            <section className="speechDetailsSection padTop padBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <BreadCrumbs data={breadcrumbArray} colorObj={colorObj} />
                        </div>
                        <div className="col-12">
                            <h3
                                className="heading mt-3"
                                style={{ "--accent_color1": colorObj?.accent_color1 }}
                            >
                                {title}
                            </h3>
                        </div>
                        <div className="col-12 mt-3 d-flex p-0">
                            {/* {author !== "" && (
                                <div className="col-md-6">
                                    <div
                                        className="author"
                                        style={{ "--accent_color3": colorObj?.accent_color3 }}
                                    >
                                        <span className="labelName me-1">
                                            {t("constantString.author:")}
                                        </span>
                                        {author}
                                    </div>
                                </div>
                            )} */}
                            <div className="col-md-12">
                                <div
                                    className="date"
                                    style={{ "--accent_color3": colorObj?.accent_color3 }}
                                >
                                    {date ? moment(date).format(`DD MMM YYYY`) : ""}
                                    {/* {date && microsite ? " | " : ""}
                                    {microsite} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="speechDetailImageMain">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 mt-3">
                                <div className="only-img">
                                    <ImageWithObjectFit
                                        className="cursor-pointer"
                                        src={detailImage?.src}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {(speech_speaker !== "" || speech_location !== "" || related_links !== "") && (
                    <>
                        <div className="speechInfoSecation mt-3">
                            <div className="container">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-12 col-sm-12 col-12"></div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="container">
                    <div className="row">
                        <div className="speechTextSecation">
                            <div className="container">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                        <div className="text">{parse(body ? body : "")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SpeechDetails;
