import { encodeObject } from "./objEncoder";

const scopes = ["offline_access"];
const responseType = "code";

export const getBaseUrl = () => {
    const url = window.location.href;
    const path = window.location.pathname;
    const urlParts = url.match(/(http[s]?:\/\/[^/]*)\/?/) || []; // match the base url with trailing slash removed, return in [1]

    return { base: `${urlParts[1]}/` || null, path };
};

const getAuthorizeHref = ({ authEndpoint, clientId, state: stateObj, codeChallenge }) => {
    const state = encodeObject(stateObj);
    const url = getBaseUrl().base;

    return `${authEndpoint}?client_id=${clientId}&scope=${scopes.join(
        "%20",
    )}&response_type=${responseType}&state=${state}&redirect_uri=${url}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
};

const getLogoutHref = ({ idToken }) => {
    const url = `${getBaseUrl().base}logout`;
    return idToken
        ? `https://www.iiuk.org/hydra/oauth2/sessions/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${url}`
        : url;
};

export const storeAuthToken = (token) => {
    return window.localStorage.setItem("token", token);
};

export const getAuthToken = () => {
    return window.localStorage.getItem("token");
};

export const removeAuthToken = () => {
    return window.localStorage.removeItem("token");
};

export const handleOauth = ({ authEndpoint, clientId, state, codeChallenge }) => {
    window.open(
        getAuthorizeHref({
            authEndpoint,
            clientId,
            state,
            codeChallenge,
        }),
        "_self",
    );
};

export const handleLogout = ({ idToken }) => {
    window.open(getLogoutHref({ idToken }), "_self");
};
