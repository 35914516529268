import React, { useContext } from "react";
import "./ArticleDetail.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { fetchArticle } from "../../redux/layoutSlice";
import Breadcrumbs from "../../components/BreadCrumbs";
import ImageWithObjectFit from "../../components/ImageWrapperComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import PrintSvg from "../../assets/PrintSvg";
import PDFSvg from "../../assets/PDFSvg";
import { WindowContext } from "../../components/WindowResize/resize";
import moment from "moment";
import { calculateReadTime, parseWithRemoveBrTagsFromBody } from "../../utils/articleHelpers";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { Dropdown } from "primereact/dropdown";
import PhotoGallery from "../../components/PhotoGallery";
import fonts from "../../utils/fonts";
import { Navigation, Pagination } from "swiper/modules";
import ReactPlayer from "react-player";
import useGetArticleMeta from "../../utils/useGetArticleMeta";
import ShareOnSocialLinks from "../../components/ShareOnSocialLinks";

function Articles({ contents, colors: colorObj }) {
    const { articleId: articleIdFromParams } = useParams();

    const id = contents?.[0]?.id || articleIdFromParams;

    const dispatch = useDispatch();

    const { isMobile } = useContext(WindowContext);

    const [isLoading, setIsLoading] = useState(!!id);

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const content = useSelector((state) => state.layout.content);

    const { t } = useCustomTranslation();

    const {
        title,
        date,
        summary,
        body,
        microsite,
        image_carousel,
        detailImage,
        link,
        region,
        article_pdf,
        translate_languages,
        image_series,
        breadcrumb,
        language,
    } = content[id] || {};

    useEffect(() => {
        if (isLoading && !title) {
            dispatch(fetchArticle({ id }));
        }
        if (isLoading && title) {
            setIsLoading(false);
        }
    }, [isLoading, title]);

    useEffect(() => {
        if (translate_languages) {
            const index = translate_languages.findIndex((e) => e.language === language);
            if (index !== -1) {
                setSelectedLanguage(translate_languages[index]);
            }
            document.querySelectorAll(".articleMainSecation").forEach((element) => {
                element.style.fontFamily = fonts[translate_languages[index].lang_code];
            });
        }
    }, [language]);

    const handleLanguageChange = (e) => {
        dispatch(fetchArticle({ id, lang: e.value }));
    };

    const { getLocation, getBreadcrumbArray, getTheme } = useGetArticleMeta();
    const location = getLocation(microsite);
    const breadcrumbArray = getBreadcrumbArray(breadcrumb);
    const displayTheme = getTheme(breadcrumb);

    const swiperCarousel = {
        spaceBetween: 10,
        slidesPerView: 1,
        modules: [Pagination, Navigation],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        navigation: true,
    };

    return (
        <>
            <div className="articleMainSecation">
                <div className="container">
                    <div className="row mb-5 d-flex justify-content-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <Breadcrumbs data={breadcrumbArray} colorObj={colorObj} />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div
                                className="tagMain mt-3"
                                style={{ color: colorObj?.primary_color }}
                            >
                                {displayTheme}
                            </div>
                            <h1 className="mb-5 mt-2" style={{ color: colorObj?.accent_color1 }}>
                                {title}
                            </h1>
                            <div className="iconHldr">
                                <div className="dateMain">
                                    <div
                                        className="dateTitleMain"
                                        style={{
                                            borderLeft: `2px solid ${colorObj?.accent_color1}`,
                                        }}
                                    >
                                        <div className="d-flex">
                                            <h4
                                                className="capitalize"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                {location}
                                            </h4>
                                        </div>
                                        <div className="dateTime">
                                            <span style={{ color: colorObj?.accent_color3 }}>
                                                {region ? `${region} • ` : ""}
                                                {calculateReadTime(content[id])
                                                    ? `${calculateReadTime(content[id])} ${t(
                                                          "constantString.minRead",
                                                      )} • `
                                                    : ""}
                                                {moment(date).format("DD MMMM YYYY")}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <ShareOnSocialLinks />

                                <ul>
                                    <li className="cursor-pointer" onClick={() => window.print()}>
                                        <PrintSvg />
                                    </li>
                                    <li
                                        className="cursor-pointer"
                                        onClick={() => window.open(article_pdf, "_blank")}
                                    >
                                        <PDFSvg />
                                    </li>
                                </ul>
                                {translate_languages?.length > 1 && (
                                    <Dropdown
                                        options={translate_languages}
                                        optionLabel="language"
                                        value={selectedLanguage}
                                        onChange={handleLanguageChange}
                                        placeholder="language"
                                        unstyled
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="articleImageMain">
                    <div className="container">
                        {link ? (
                            <div className="row d-flex justify-content-center">
                                <div className="col-12 mobile-view">
                                    <ReactPlayer url={link} controls className="video-ratio" />
                                </div>
                                <div className="col-12">
                                    <div
                                        className="imgtext"
                                        style={{ color: colorObj?.accent_color1 }}
                                    >
                                        {detailImage?.title}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Swiper
                                {...swiperCarousel}
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                    "--accent_color4": colorObj?.accent_color4,
                                }}
                            >
                                {(image_carousel?.length
                                    ? image_carousel
                                    : [detailImage || {}]
                                )?.map((card) => (
                                    <SwiperSlide key={card?.title}>
                                        <div className="card h-100">
                                            <div className="cardImg">
                                                <ImageWithObjectFit src={card?.src} />
                                            </div>
                                            <div className="card-body d-flex flex-column">
                                                <h3>{card?.title && parse(card?.title)}</h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </div>

                {isMobile && (
                    <div className="col-12 mt-3 mb-3">
                        <div className="iconHldr-mobile">
                            <ShareOnSocialLinks />
                            <ul>
                                <li
                                    className="me-4"
                                    onClick={() => navigator.share({ title, url: "" })}
                                >
                                    <PrintSvg />
                                </li>
                                <li
                                    className="me-4"
                                    onClick={() => window.open(article_pdf, "_blank")}
                                >
                                    <PDFSvg />
                                </li>
                            </ul>
                        </div>
                    </div>
                )}

                {!!summary && (
                    <div className="titleSecation">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div
                                        className="summary"
                                        style={{
                                            color: colorObj?.accent_color1,
                                            borderLeft: `2px solid ${colorObj?.primary_color}`,
                                        }}
                                    >
                                        {parse(summary || "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {body !== "" && (
                    <div
                        className="articleTextSecation"
                        style={{ "--primary_color": colorObj?.primary_color }}
                    >
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div>
                                        {parseWithRemoveBrTagsFromBody(
                                            body || "",
                                            colorObj?.primary_color,
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {image_series !== null && <PhotoGallery image_series={image_series} />}
            </div>
        </>
    );
}

export default Articles;
