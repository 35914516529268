import React from "react";

const DefaultUserSvg = () => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect y="9.15527e-05" width="34" height="34" rx="17" fill="#E7E7E7" />
            <path
                d="M17 17.5626C14.6225 17.5626 12.6875 15.6276 12.6875 13.2501C12.6875 10.8726 14.6225 8.93759 17 8.93759C19.3775 8.93759 21.3125 10.8726 21.3125 13.2501C21.3125 15.6276 19.3775 17.5626 17 17.5626ZM17 10.0626C15.245 10.0626 13.8125 11.4951 13.8125 13.2501C13.8125 15.0051 15.245 16.4376 17 16.4376C18.755 16.4376 20.1875 15.0051 20.1875 13.2501C20.1875 11.4951 18.755 10.0626 17 10.0626Z"
                fill="#292D32"
            />
            <path
                d="M23.4426 25.0626C23.1351 25.0626 22.8801 24.8076 22.8801 24.5001C22.8801 21.9126 20.2401 19.8126 17.0001 19.8126C13.7601 19.8126 11.1201 21.9126 11.1201 24.5001C11.1201 24.8076 10.8651 25.0626 10.5576 25.0626C10.2501 25.0626 9.99512 24.8076 9.99512 24.5001C9.99512 21.2976 13.1376 18.6876 17.0001 18.6876C20.8626 18.6876 24.0051 21.2976 24.0051 24.5001C24.0051 24.8076 23.7501 25.0626 23.4426 25.0626Z"
                fill="#292D32"
            />
        </svg>
    );
};

export default DefaultUserSvg;
