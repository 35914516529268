import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticle } from "../../redux/layoutSlice";
import parse from "html-react-parser";

function Article4({ contents }) {
    const { articleId: articleIdFromParams } = useParams();
    const id = contents?.[0]?.id || articleIdFromParams;

    const dispatch = useDispatch();
    const content = useSelector((state) => state.layout.content);

    const [isLoading, setIsLoading] = useState(!!id);
    const [didDispatch, setDidDispatch] = useState(false);

    useEffect(() => {
        if (isLoading && !didDispatch) {
            dispatch(fetchArticle({ id }));
            setDidDispatch(true);
        }
        if (isLoading && content[id]) {
            setIsLoading(false);
        }
    }, [isLoading, content, didDispatch, id, dispatch]);

    // replace the <script> tag with <div>
    const { body: _body } = content[id] || {};
    const body = _body
        ?.replace(/<script src="([^"]*\/)embed.js"/g, '<div data-shorthand="$1"')
        .replace(/<\/script>/g, "</div>");

    useEffect(() => {
        // add shorthand embed script when a shorthand element is present
        // shorthand has an issue where the analytics function is trying
        //  to capture scroll events which is causing an error after the
        //  user navigates away from this page.
        // In order to stop that, the following code adds a listener that
        //  prevents the listener to capture the scroll event.
        const stopPropagation = function (event) {
            event.stopImmediatePropagation();
        };

        window.addEventListener("scroll", stopPropagation, true);

        const shorthandEmbed = document.querySelector("[data-shorthand]");

        if (shorthandEmbed) {
            let url = shorthandEmbed.getAttribute("data-shorthand");
            if (url.indexOf("http") === -1) url = "https://" + url;

            const script = document.createElement("script");
            script.src = url.replace(/\/?$/, "/embed.js");
            script.onload = () => console.log("Script loaded successfully");
            script.onerror = (error) => console.error("Error loading script:", error);
            shorthandEmbed.parentElement.insertBefore(script, shorthandEmbed);

            // also, let the header scroll
            const headerWrapper = document.getElementById("header-wrapper");
            const headerWrapperClassList = headerWrapper.classList;
            headerWrapperClassList.replace("fixed", "relative");
            headerWrapperClassList.remove("z-10");

            const header = document.getElementById("header");
            const headerClassList = header.classList;
            headerClassList.remove("z-20");

            const content = document.getElementById("content");
            content.setAttribute("style", "padding-top: 0");
            // // Cleanup on component unmount
            return () => {
                window.removeEventListener("scroll", stopPropagation);
                headerWrapperClassList.replace("relative", "fixed");
                headerWrapperClassList.add("z-10");
                headerClassList.add("z-20");
                content.setAttribute("style", "padding-top: 160px");
                location.reload();
            };
        }
    }, [body]);

    return <div style={{ width: "100%" }}>{body && parse(body)}</div>;
}

export default Article4;
