import React, { useCallback, useEffect, useState } from "react";
import "./FeatureCarousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { Navigation, Pagination } from "swiper/modules";
import parse from "html-react-parser";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import * as posterImage from "../../utils/usePosterImage";
import ImageWithObjectFit from "../ImageWrapperComponent";
import CustomLink from "../../utils/customLink";
import { ARTICLE_DETAILS } from "../../constants/strings";

function FeatureCarousel({ contents: contentToRender = [], colors: colorObj, block_source }) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const { direction, t } = useCustomTranslation();

    const { getLink } = useGetLink();

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (block_source === ARTICLE_DETAILS) {
                if (contentKey && content[contentKey]) {
                    const respKeys = content[contentKey];
                    let resp = respKeys.map((k) => content[k]);
                    resp = JSON.parse(JSON.stringify(resp));
                    for await (const element of resp) {
                        if (element && element.link) {
                            element.posterImage = await posterImage.usePosterImage(
                                element?.link,
                                element?.image,
                            );
                        }
                    }
                    if (resp.length) {
                        setContentReferenceData(resp);
                    }
                }
            }
        },
        [content],
    );

    useEffect(() => {
        if (contentToRender.length > 0 && block_source === ARTICLE_DETAILS) {
            setCurrentContent(contentToRender[0].contentKey);
        } else {
            const arr = contentToRender.map((c) => {
                return {
                    image: c.content_image,
                    title: c.heading,
                    summary: c.body,
                    nid: "",
                    content_buttons: c.content_buttons,
                };
            });
            setContentReferenceData(arr);
        }
    }, []);

    const settingsSwiper = {
        spaceBetween: 0,
        slidesPerView: 1,
        modules: [Pagination, Navigation],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        navigation: true,
        dir: direction,
        key: direction,
    };

    return (
        <>
            <section className="FeatureCarouselWrapper py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Swiper
                                {...settingsSwiper}
                                style={{
                                    "--accent_color4": colorObj?.accent_color4,
                                    "--primary_color": colorObj?.primary_color,
                                }}
                            >
                                {contentReferenceData.map((card) => {
                                    return (
                                        <SwiperSlide key={card.title}>
                                            <div className="featureImgHldr">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={card?.image?.src || card?.posterImage}
                                                />
                                                <div className="txtBoxHldr">
                                                    <h2
                                                        style={{
                                                            "--primary_color":
                                                                colorObj?.primary_color,
                                                        }}
                                                    >
                                                        {card?.title}
                                                    </h2>
                                                    <p
                                                        style={{
                                                            "--accent_color1":
                                                                colorObj?.accent_color1,
                                                        }}
                                                    >
                                                        {card?.summary && parse(card?.summary)}
                                                    </p>
                                                    {block_source === ARTICLE_DETAILS ? (
                                                        <CustomLink
                                                            to={getLink(card.path)}
                                                            type={card.type}
                                                        >
                                                            <span
                                                                className="readingLink cursor-pointer"
                                                                style={{
                                                                    "--accent_color4":
                                                                        colorObj?.accent_color4,
                                                                    "--primary_color":
                                                                        colorObj?.primary_color,
                                                                }}
                                                            >
                                                                {t("btnText.CountinueReading")}
                                                                <i className="bi bi-chevron-right ms-2"></i>
                                                            </span>
                                                        </CustomLink>
                                                    ) : (
                                                        card?.content_buttons?.length > 0 &&
                                                        card?.content_buttons.map((v) => (
                                                            <CustomLink
                                                                to={getLink(v.action)}
                                                                key={v.label}
                                                            >
                                                                <span
                                                                    className="readingLink cursor-pointer"
                                                                    style={{
                                                                        "--accent_color4":
                                                                            colorObj?.accent_color4,
                                                                        "--primary_color":
                                                                            colorObj?.primary_color,
                                                                    }}
                                                                >
                                                                    {v.label}
                                                                    <i className="bi bi-chevron-right ms-2"></i>
                                                                </span>
                                                            </CustomLink>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FeatureCarousel;
