import React, { useCallback, useEffect, useState } from "react";

export const WindowContext = React.createContext({ height: 0, width: 0 });

export const WindowContextProvider = ({ children }) => {
    const getHeight = useCallback(() => {
        return Math.max(document.documentElement.height || 0, window.innerHeight || 0);
    }, []);

    const getWidth = useCallback(() => {
        return Math.max(document.documentElement.width || 0, window.innerWidth || 0);
    }, []);

    const [height, setHeight] = useState(getHeight());

    const [width, setWidth] = useState(getWidth());

    const [isMobile, setIsMobile] = useState();

    const [isDesktop, setIsDesktop] = useState();

    useEffect(() => {
        const handleResize = () => {
            setHeight(getHeight());
            setWidth(getWidth());
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [getHeight, getWidth, height, width]);

    useEffect(() => {
        if (width < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [width]);

    useEffect(() => {
        if (width > 768) {
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
    }, [width]);

    return (
        <WindowContext.Provider value={{ height, width, isMobile, isDesktop }}>
            {children}
        </WindowContext.Provider>
    );
};
