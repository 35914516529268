import { useSelector } from "react-redux";
import { translateStaticStrings } from "../redux/layoutSlice";

const useCustomTranslation = () => {
    const languageDirection = useSelector((s) => s.auth);

    const strings = useSelector(translateStaticStrings);

    const t = (key, defaultKey = "") => {
        const keys = key.split(".");
        let translation = strings;
        let keyNotFound = false;

        keys.forEach((k) => {
            if (!translation?.[k]) {
                keyNotFound = true;
            } else {
                translation = translation[k];
            }
        });

        if (keyNotFound) {
            return defaultKey;
        }

        return translation;
    };

    return { direction: languageDirection, t };
};

export default useCustomTranslation;
