import axios from "axios";
import { getAuthToken } from "./oauthHandler";
import store from "../redux/store";
import { setShowModal } from "../redux/authSlice";

const axiosInstance = axios.create({
    headers: {
        "content-type": "text/plain",
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const getToken = getAuthToken();

        if (getToken && getToken !== "null") {
            config.headers["authorization"] = `Bearer ${getToken}`;
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error && error.response) {
            const errResp = error.response;

            if (errResp) {
                setTimeout(() => {
                    if (error.response && error.response.status === 511) {
                        if (error.response.data === "") {
                            store.dispatch(setShowModal(true));
                        }
                    }
                }, 200);
            }
        }

        return Promise.reject(error);
    },
);

export { axiosInstance };
