import React, { useCallback, useEffect, useState } from "react";
import "./TitleCarousel.css";
import { useSelector } from "react-redux";
import TitleCard from "../Card/TitleCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperCarousel } from "../../utils/carouselFunction";
import useCustomTranslation from "../../utils/useCustomTranslation";
import * as posterImage from "../../utils/usePosterImage";
import { ARTICLE_DETAILS } from "../../constants/strings";

function TitleCarousel({
    contents: contentToRender = [],
    heading,
    block_source,
    colors: colorObj,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const { direction } = useCustomTranslation();

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (block_source === ARTICLE_DETAILS) {
                const respKeys = content[contentKey];
                let resp = respKeys.map((k) => content[k]).slice(0, 3);
                resp = JSON.parse(JSON.stringify(resp));
                for await (const element of resp) {
                    if (element && element.link) {
                        element.posterImage = await posterImage.usePosterImage(
                            element?.link,
                            element?.image,
                        );
                    }
                }
                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [content],
    );

    useEffect(() => {
        if (contentToRender.length > 0 && block_source === ARTICLE_DETAILS) {
            setCurrentContent(contentToRender[0].contentKey);
        } else {
            const arr = contentToRender
                .map((c) => {
                    return {
                        image: c.content_image,
                        microsite: "",
                        title: c.heading,
                        author: "",
                        date: "",
                        summary: c.body,
                        path: c.contentKey,
                        nid: "",
                        type: c.type,
                    };
                })
                .slice(0, 3);
            setContentReferenceData(arr);
        }
    }, []);

    return (
        <>
            <section
                className="ResourceSection py-50"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="titleMain"
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                }}
                            >
                                <h3>{heading}</h3>
                            </div>
                        </div>

                        <div className="col-12">
                            <Swiper
                                {...swiperCarousel}
                                dir={direction}
                                key={direction}
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                {contentReferenceData.map((card) => (
                                    <SwiperSlide key={card.title}>
                                        <TitleCard content={card} colors={colorObj} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TitleCarousel;
