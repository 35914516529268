import React from "react";
import CustomLink from "../../utils/customLink";

function DropDownMenuListResponsive({
    value,
    openLink,
    activeDropdown,
    setActiveDropdown,
    setActiveSubMenu,
    colors,
}) {
    const isCollapsed = activeDropdown === value.heading;

    const handleAccordionClick = () => {
        setActiveDropdown(isCollapsed ? null : value.heading);
        setActiveSubMenu(null);
    };

    return (
        <div className="mega-menu w-100">
            <div className="accordion navMainWrapper" id="navigationMain">
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`${value.content_reference}`}>
                        <span
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${value.content_reference}`}
                            aria-expanded={isCollapsed ? "true" : "false"}
                            aria-controls={`collapse${value.content_reference}`}
                            onClick={handleAccordionClick}
                            style={{
                                color: isCollapsed ? colors?.accent_color4 : colors.accent_color1,
                                "--primary_color": isCollapsed
                                    ? colors?.accent_color4
                                    : colors.accent_color1,
                                "--up-down-arrow": isCollapsed ? `"\\f106"` : `"\\f107"`,
                                background: isCollapsed ? colors.primary_color : "none",
                            }}
                        >
                            {value.heading}
                        </span>
                    </h2>
                    <div
                        id={`collapse${value.content_reference}`}
                        className={`accordion-collapse hide ${isCollapsed ? "show" : ""}`}
                        aria-labelledby={`collapse${value.content_reference}`}
                        data-bs-parent="#navigationMain"
                    >
                        <div className="accordion-body">
                            <div className="menuContainer">
                                <div className="subMenuWrapper">
                                    <div className="row">
                                        <div className="col-12">
                                            {value.contents.map((subValue) => (
                                                <li key={subValue.heading}>
                                                    <CustomLink
                                                        to={openLink(subValue.content_reference)}
                                                        type={subValue.type}
                                                    >
                                                        <span
                                                            className="dropdown-item"
                                                            style={{ color: colors.accent_color1 }}
                                                        >
                                                            {subValue.heading}
                                                        </span>
                                                    </CustomLink>
                                                </li>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DropDownMenuListResponsive;
