import React, { useEffect, useState } from "react";
import "./TwoColumnHero.css";
import Loader from "../Loader/loader";
import TwoColumnHeroCard from "../Card/TwoColumnHeroCard";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import useCustomTranslation from "../../utils/useCustomTranslation";

function TwoColumnHeroPage({ contents: contentsArray, background: background }) {
    const content = useSelector((state) => state.layout.content);

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [mainItemsContentsRefData, setMainItemsContentsRefData] = useState([]);

    const [subItemsContentsRefData, setSubItemsContentsRefData] = useState([]);

    useEffect(() => {
        const fetchDefaultData = () => {
            setIsLoading(true);
            const mainContent = content[contentsArray[0].contentKey]
                .map((k) => content[k])
                .slice(0, 5);
            const subContent = content[contentsArray[1].contentKey]
                .map((k) => content[k])
                .slice(0, 2);

            if (mainContent.length) setMainItemsContentsRefData(mainContent);

            if (subContent.length) setSubItemsContentsRefData(subContent);

            setIsLoading(false);
        };
        fetchDefaultData();
    }, []);

    const handleClick = (id) => {
        navigate(`/articles/${id}`);
    };

    const { dir } = useCustomTranslation();

    const settings_swiper = {
        spaceBetween: 10,
        slidesPerView: 1,
        modules: [Pagination],
        pagination: {
            clickable: "true",
            type: "bullets",
            renderBullet: function (index, className) {
                return (
                    '<span class="' + className + '">' + "</em>" + "<i></i>" + "<b></b>" + "</span>"
                );
            },
        },
    };

    return (
        <>
            <Loader isLoading={isLoading} />

            <section className="homeNewsWrapper" style={{ backgroundColor: `#${background}` }}>
                <div className="container">
                    <div className="row">
                        <div className="card-container">
                            <div className="col-12 col-md-12 col-lg-7 p-0">
                                <Swiper dir={dir} key={dir} {...settings_swiper}>
                                    {mainItemsContentsRefData.map((card) => {
                                        return (
                                            <SwiperSlide key={card.title}>
                                                <TwoColumnHeroCard content={card} />
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                            <div className="right-cards">
                                {subItemsContentsRefData.map((value) => (
                                    <div className="right-card" key={value.title}>
                                        <div className="smallNews">
                                            <div className="row">
                                                <div className="col-12 smallNewsBody ms-3 mb-3">
                                                    <h3
                                                        className="underline"
                                                        onClick={() => handleClick(value.nid)}
                                                    >
                                                        {value.title}
                                                    </h3>
                                                </div>
                                                <div className="col-lg-7 col-md-7 col-sm-6 col-6">
                                                    <div className="smallNewsImg">
                                                        <ImageWithObjectFit
                                                            className="cursor-pointer"
                                                            src={value.image.src}
                                                            onClick={() => handleClick(value.nid)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-md-5 col-sm-6 col-6">
                                                    <div className="smallNewsBody">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: value.summary,
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TwoColumnHeroPage;
