import React from "react";

const TwitterSvg = () => {
    return (
        <svg
            width="32"
            height="26"
            viewBox="0 0 32 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.3515 6.39628C29.5868 5.46976 30.6987 4.35793 31.5634 3.06081C30.4516 3.55495 29.1545 3.92556 27.8573 4.0491C29.2162 3.24611 30.2045 2.01075 30.6987 0.466555C29.4633 1.20777 28.0426 1.76368 26.622 2.07252C25.3866 0.775395 23.7189 0.0341797 21.8658 0.0341797C18.2833 0.0341797 15.3802 2.93727 15.3802 6.51981C15.3802 7.01396 15.442 7.5081 15.5655 8.00224C10.1917 7.6934 5.37381 5.09915 2.16188 1.20777C1.60597 2.13429 1.29713 3.24611 1.29713 4.48147C1.29713 6.70512 2.40895 8.68169 4.20022 9.85528C3.15016 9.79351 2.10011 9.54644 1.23536 9.0523V9.11407C1.23536 12.2642 3.459 14.8585 6.42387 15.4762C5.92972 15.5997 5.31204 15.7232 4.75613 15.7232C4.32376 15.7232 3.95315 15.6615 3.52077 15.5997C4.32376 18.194 6.73271 20.047 9.57403 20.1088C7.35038 21.8383 4.57083 22.8883 1.5442 22.8883C0.988287 22.8883 0.494143 22.8265 0 22.7648C2.84133 24.6178 6.23856 25.6679 9.94464 25.6679C21.8658 25.6679 28.3515 15.8468 28.3515 7.26103C28.3515 6.95219 28.3515 6.70512 28.3515 6.39628Z"
                fill="#4F4F4F"
            />
        </svg>
    );
};

export default TwitterSvg;
