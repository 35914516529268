import React, { useCallback, useEffect, useState } from "react";
import "./ParagraphMedia.css";
import { useSelector } from "react-redux";
import ImageWithObjectFit from "../ImageWrapperComponent";
import * as posterImage from "../../utils/usePosterImage";
import { parseWithRemoveBrTagsFromBody } from "../../utils/articleHelpers";
import { ARTICLE_DETAILS, BLOCK_DETAILS } from "../../constants/strings";

function ParagraphMedia({
    contents: contentToRender = [],
    body,
    heading,
    section_image,
    block_source,
    colors: colorObj,
}) {
    const [paragraphMediaDetails, setParagraphMediaDetailsDetails] = useState({
        heading: "",
        body: "",
        image: "",
    });

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    useEffect(() => {
        if (block_source === BLOCK_DETAILS) {
            setParagraphMediaDetailsDetails({
                ...paragraphMediaDetails,
                body: body,
                heading: heading,
                image: section_image?.src,
            });
        }

        if (block_source === ARTICLE_DETAILS) {
            setParagraphMediaDetailsDetails({
                ...paragraphMediaDetails,
                body: contentReferenceData[0]?.body,
                heading: contentReferenceData[0]?.title,
                image: contentReferenceData[0]?.image?.src || contentReferenceData[0]?.posterImage,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                let resp = respKeys.map((k) => contentFromStore[k]);
                resp = JSON.parse(JSON.stringify(resp));
                for await (const element of resp) {
                    if (element && element.link) {
                        element.posterImage = await posterImage.usePosterImage(
                            element?.link,
                            element?.image,
                        );
                    }
                }
                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    return (
        <>
            <div
                className="paragraphMedia py-50"
                style={{ "--primary_color": colorObj?.primary_color }}
            >
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <div
                                className={`${
                                    paragraphMediaDetails?.body === "" ? "only-img" : "wrapping-img"
                                }`}
                            >
                                <ImageWithObjectFit src={paragraphMediaDetails?.image} />
                                {paragraphMediaDetails?.image?.title !== "" && (
                                    <div
                                        className="imgtext"
                                        style={{ color: colorObj?.accent_color1 }}
                                    >
                                        {paragraphMediaDetails?.image?.title}
                                        <span
                                            className="acknowledgementText ms-1"
                                            style={{ color: colorObj?.accent_color3 }}
                                        >
                                            {paragraphMediaDetails?.image?.acknowledgement}
                                        </span>
                                    </div>
                                )}
                            </div>
                            {paragraphMediaDetails?.body !== "" && (
                                <div className="container">
                                    <h3
                                        className="heading ms-2"
                                        style={{ color: colorObj?.accent_color1 }}
                                    >
                                        {paragraphMediaDetails?.heading}
                                    </h3>
                                </div>
                            )}
                            {paragraphMediaDetails?.body !== "" && (
                                <div
                                    className="text ms-2 container"
                                    style={{ color: colorObj?.accent_color1 }}
                                >
                                    <p>
                                        {parseWithRemoveBrTagsFromBody(
                                            paragraphMediaDetails.body || "",
                                            colorObj?.primary_color,
                                        )}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ParagraphMedia;
