import React from "react";

function LabelOnlyMenu({ value, openLink, colors }) {
    return (
        <li className="nav-item">
            <span
                className="nav-link cursor-pointer"
                onClick={() => openLink(value.content_reference)}
                style={{ color: colors?.accent_color4 }}
            >
                {value.heading}
            </span>
        </li>
    );
}

export default LabelOnlyMenu;
