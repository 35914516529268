import React from "react";

function PosterImage({ src, ...rest }) {
    if (!src) return null;

    const checkYoutubeImage = (event) => {
        if (event.target.naturalWidth === 120) {
            event.target.src = event.target.currentSrc.replace("maxresdefault", "sddefault");
        }
    };

    return (
        <img className="object-cover" src={src} {...rest} onLoad={(p) => checkYoutubeImage(p)} />
    );
}

export default PosterImage;
