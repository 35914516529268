import React from "react";
import { useNavigate } from "react-router";
import parse from "html-react-parser";
import ImageWithObjectFit from "../ImageWrapperComponent";

function TwoColumnHeroCard({ content }) {
    const navigate = useNavigate();

    const { image, title, nid, summary } = content;

    const handleClick = () => {
        navigate(`/articles/${nid}`);
    };

    return (
        <>
            <div className="left-card">
                <div className="bigNews">
                    <div className="bigNewsImg">
                        <ImageWithObjectFit
                            className="cursor-pointer"
                            src={image.src}
                            onClick={handleClick}
                        />
                    </div>
                    <div className="bigNewsBody">
                        <h3 className="underline" onClick={handleClick}>
                            {title}
                        </h3>
                        <div>{parse(summary)}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TwoColumnHeroCard;
