import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../utils/networkRequests";
import axios from "axios";

const SLICE_NAME = "contact_us";

export const fetchContactUsFormFields = createAsyncThunk(
    `${SLICE_NAME}/fetchContactUsFormFields`,
    async (link) => {
        try {
            const response = await axiosInstance.get(link);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

export const saveContactUsForm = createAsyncThunk(
    `${SLICE_NAME}/saveContactUsForm`,
    async ({ formData }, { getState }) => {
        const state = getState();
        const { submitLink } = state[SLICE_NAME];

        const response = await axios.post(submitLink, formData);
        return response.data;
    },
);

const initialState = {
    formFields: [],
    metaData: {},
    submitLink: "",
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const contactUsSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        // Example reducer:
        // fetchContentAndLayout: (state) => {
        //   const contentKeysMappedToLowercase = {}
        //   Object.keys(contentFromII.content).forEach(
        //     (k) => (contentKeysMappedToLowercase[k.toLowerCase()] = contentFromII.content[k]),
        //   )
        //   state.content = contentKeysMappedToLowercase
        //   state.layout = layoutFromII.layout
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchContactUsFormFields.fulfilled, (state, action) => {
            const data = action.payload;
            const { fields, submit_link: submitLink, ...meta } = data;
            state.formFields = fields;
            state.submitLink = submitLink;
            state.metaData = meta;
        });
    },
});

// Action creators are generated for each case reducer function
// export const { fetchContentAndLayout } = layoutSlice.actions

export default contactUsSlice.reducer;
