import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const SLICE_NAME = "search";

export const fetchSearchResultData = createAsyncThunk(
    `${SLICE_NAME}/fetchSearchResultData`,
    async ({ search: searchString }, { getState }) => {
        try {
            const state = getState();

            const response = await axios.get(
                state.auth?.configs?.SEARCH_RESULT_API_LINK + searchString,
            );
            return response.data;
        } catch (err) {
            console.error(err);
        }
    },
);

const initialState = {
    articles: [],
    isLoading: false,
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const searchSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        // Example reducer:
        // fetchContentAndLayout: (state) => {
        //   const contentKeysMappedToLowercase = {}
        //   Object.keys(contentFromII.content).forEach(
        //     (k) => (contentKeysMappedToLowercase[k.toLowerCase()] = contentFromII.content[k]),
        //   )
        //   state.content = contentKeysMappedToLowercase
        //   state.layout = layoutFromII.layout
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchResultData.fulfilled, (state, action) => {
                const data = action.payload;
                const articles = data.articles;
                state.articles = articles;
                state.isLoading = false;
            })
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("pending"),
                (state) => {
                    state.isLoading = true;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("fulfilled"),
                (state) => {
                    state.isLoading = false;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("rejected"),
                (state) => {
                    state.isLoading = false;
                },
            );
    },
});

export default searchSlice.reducer;
